/* Libs */
import { Box, Typography } from '@mui/material'
import { Navigate } from 'react-router-dom'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { colorPalette, typography } from '../../config'
import { Input, Button, Message } from '../../components'
import { selectAuth, useAppDispatch, useAppSelector, login } from '../../redux'
import { yupResolver } from '@hookform/resolvers/yup'
import { loginFormSchema } from '../../schemas'
import { useTranslation } from 'react-i18next'

interface LoginFormValues {
   email: string
   password: string
}

const Login: React.FC = () => {
   const dispatch = useAppDispatch()
   const { isAuthenticated, error } = useAppSelector(selectAuth)
   const {
      control,
      handleSubmit,
      formState: { errors },
   } = useForm<LoginFormValues>({
      defaultValues: {
         email: '',
         password: '',
      },
      resolver: yupResolver(loginFormSchema),
   })
   const { t } = useTranslation(['common', 'inputs', 'buttons'])

   const onSubmit: SubmitHandler<LoginFormValues> = (data) => {
      dispatch(login(data))
   }

   return (
      <Box
         sx={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0 24px'
         }}
      >
         <Message />
         {isAuthenticated && <Navigate to='/' replace={true} />}
         <Typography
            className={typography.pc.h4}
            component='div'
            sx={{ marginBottom: '40px' }}
         >
            Shop Admin
         </Typography>

         <Box
            component='form'
            sx={{ width: '374px', padding: '24px' }}
            onSubmit={handleSubmit(onSubmit)}
         >
            <Controller
               name='email'
               control={control}
               rules={{ required: true }}
               render={({ field }) => (
                  <Input
                     label={t('common.email.label', { ns: 'inputs' }) as string}
                     placeholder={
                        t('common.email.placeholder', {
                           ns: 'inputs',
                        }) as string
                     }
                     type='email'
                     error={!!errors.email}
                     helperText={errors.email?.message}
                     {...field}
                     sx={{ margin: '10px 0 16px 0' }}
                  />
               )}
            />
            <Controller
               name='password'
               control={control}
               rules={{ required: true }}
               render={({ field }) => (
                  <Input
                     label={
                        t('common.password.label', { ns: 'inputs' }) as string
                     }
                     placeholder={
                        t('common.password.placeholder', {
                           ns: 'inputs',
                        }) as string
                     }
                     type='password'
                     error={!!errors.password}
                     helperText={errors.password?.message}
                     {...field}
                     sx={{ margin: '32px 0 16px 0' }}
                  />
               )}
            />
            <Typography
               className={typography.mb.b3}
               component='div'
               color='#999999'
               sx={{ marginBottom: '40px', textAlign: 'right' }}
            >
               {t('buttons.login.forgot', { ns: 'components' })} ?
            </Typography>
            <Button fullWidth type='submit' customsize='lg' variant='contained'>
               {t('buttons.login.signIn', { ns: 'components' })}
            </Button>
            {error && (
               <Typography
                  className={typography.pc.helpReg}
                  color={colorPalette.red.shade_500}
                  textAlign='center'
                  sx={{ marginTop: '8px' }}
               >
                  {error.message}
               </Typography>
            )}
         </Box>
      </Box>
   )
}

export default Login
