import { createAsyncThunk } from '@reduxjs/toolkit'
import { useFetchingHandler } from '../../hooks/use-fetch-handlers'
import { UserData, Status } from '../../types'
import { setResponse, setUploading } from '../fetch'

export type UpdateUserPasswordData = {
   password: string
}

export type UpdateUserInfoData = {
   email: string
   status?: boolean
}

interface UpdateData<Payload> {
   userId: string
   payload: Payload
}

export const getAllUsers = createAsyncThunk(
   'userState/getAllUsers',
   async (_, { dispatch }) => {
      const { getHandler } = useFetchingHandler({ dispatch })
      try {
         const res = await getHandler<UserData[]>({
            url: 'user',
            secured: true,
         })

         return {
            users: res?.data,
         }
      } catch (err: any) {
         throw err
      }
   }
)

export const getOneUser = createAsyncThunk(
   'userState/getOneUser',
   async (userId: string, { dispatch }) => {
      const { getHandler } = useFetchingHandler({ dispatch })
      try {
         const res = await getHandler<UserData>({
            url: `user/${userId}`,
            secured: true,
         })

         return {
            userDetail: res?.data,
         }
      } catch (err: any) {
         throw err
      }
   }
)

export const updateUserInfo = createAsyncThunk(
   'userState/updateUserInfo',
   async (
      { userId, payload }: UpdateData<UpdateUserInfoData>,
      { dispatch }
   ) => {
      const { putHandler } = useFetchingHandler({ dispatch })
      try {
         dispatch(setUploading(true))
         dispatch(
            setResponse({
               status: Status.PENDING,
               message: 'Processing ...',
            })
         )

         const res = await putHandler<UpdateUserInfoData, any>({
            url: `user/${userId}/userinfo`,
            secured: true,
            body: payload,
         })

         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.SUCCESS,
               message: res.message,
            })
         )
      } catch (err: any) {
         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.ERROR,
               message: err?.response?.message,
            })
         )
         throw err
      }
   }
)

export const updateUserPassword = createAsyncThunk(
   'userState/updateUserPassword',
   async (
      { userId, payload }: UpdateData<UpdateUserPasswordData>,
      { dispatch }
   ) => {
      const { putHandler } = useFetchingHandler({ dispatch })
      try {
         dispatch(setUploading(true))
         dispatch(
            setResponse({
               status: Status.PENDING,
               message: 'Processing ...',
            })
         )

         const res = await putHandler<UpdateUserPasswordData, any>({
            url: `user/${userId}/password`,
            secured: true,
            body: payload,
         })

         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.SUCCESS,
               message: res.message,
            })
         )
      } catch (err: any) {
         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.ERROR,
               message: err?.response?.message,
            })
         )
         throw new Error(err)
      }
   }
)
