import { FC, useCallback, useEffect } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import {
   getOnePromotionProgram,
   resetProgramDetail,
   selectFetch,
   selectPromotion,
   updatePromotionProgram,
   useAppDispatch,
   useAppSelector,
} from '../../../redux'
import {
   CircleLoading,
   FlashSaleListProduct,
   FlashSaleMutation,
   PromotionProgramForm,
} from '../../../components'
import { ProductData, PromotionProgramData } from '../../../types'

const PromotionProgramDetail: FC = () => {
   const { programId } = useParams()
   const dispatch = useAppDispatch()
   const { programDetail, promotionLoading } = useAppSelector(selectPromotion)
   const { uploading } = useAppSelector(selectFetch)

   const handleOnSubmit = (payload: FlashSaleMutation) => {
      if (programId) {
         dispatch(
            updatePromotionProgram({ promotionId: programId, ...payload })
         )
      }
   }

   useEffect(() => {
      if (programId && !uploading) {
         dispatch(getOnePromotionProgram(programId))
      }
   }, [programId, uploading])

   useEffect(() => {
      return () => {
         dispatch(resetProgramDetail())
      }
   }, [])

   const mergeProductsToProgram = useCallback(
      (products: ProductData[]): FlashSaleListProduct[] => {
         return products.map((product) => ({
            ...product,
            price_sale: product.flash_sale?.price_sale?.toString() || '',
            percentage: product.flash_sale?.percentage?.toString() || '',
         }))
      },
      []
   )

   if (promotionLoading || !programDetail) {
      return <CircleLoading />
   }

   const { flash_sale_name, created_at, end_at, list_products, start_at } =
      programDetail as PromotionProgramData

   const defaultValues = {
      flash_sale_name,
      start_at, //date
      end_at, //date
      list_products: mergeProductsToProgram(list_products),
   }

   return (
      <PromotionProgramForm
         createdAt={created_at}
         defaultValues={defaultValues}
         handleOnSubmit={handleOnSubmit}
      />
   )
}

export default PromotionProgramDetail
