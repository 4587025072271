import { FC } from 'react'
import { RouterProvider } from 'react-router-dom'
import { ThemeProvider } from '@mui/material'
import { router, theme } from './config'
import 'react-quill/dist/quill.snow.css'
import './app.scss'
import './i18n'
import 'highlight.js/styles/monokai.css'
import 'react-quill/dist/quill.snow.css'

const App: FC = () => {
   return (
      <ThemeProvider theme={theme}>
         <RouterProvider router={router} />
      </ThemeProvider>
   )
}

export default App
