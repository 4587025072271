import { ChangeEvent } from 'react'
import { getNumberWithComma } from './get-price'
import { FlashSaleData, Maybe } from '../types'

export const urlPattern = new RegExp(
   '^((https?|ftp)://)?' + // protocol (optional)
      '(([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,})' + // domain name and extension
      '(\\:[0-9]{1,5})?' + // port (optional)
      '(\\/.*)?$' // path (optional)
)

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

export const phoneNumberFormat =
   /^(\+84|0)\d{9,10}$|^(\+84|0)\d{3}\s\d{3}\s\d{3}$/

export const validatePhoneNumber = (phoneNum: string): boolean => {
   return phoneNumberFormat.test(phoneNum)
}

export const formatPhoneNumber = (phoneNum: string): string => {
   if (/^\+84\d{9,10}$/.test(phoneNum)) {
      // If it matches the Vietnamese phone format with +84, return the string
      return phoneNum
   } else if (/^0\d{9,10}$/.test(phoneNum)) {
      // If it starts with 0 but doesn't match Vietnamese phone format, replace with +84
      return '+84' + phoneNum.slice(1)
   } else {
      return ''
   }
}

export const transformNumberInput = (value: string): string => {
   const input = Number(value.replaceAll(',', ''))
   return isNaN(input) || input < 0 ? '' : getNumberWithComma(input)
}

export const transformNumberOutput = (e: ChangeEvent<HTMLInputElement>) => {
   const output = Number(e.target.value.replaceAll(',', ''))
   return isNaN(output) ? '0' : output.toString()
}

export const transferStringToNumber = (value: string): number => {
   if (typeof value === 'number') {
      return value as number
   } else if (typeof value !== 'string') {
      return 0
   }

   return parseInt(value.replaceAll(',', ''))
}

export const isFlashSaleValid = (flashSale?: Maybe<FlashSaleData>) => {
   if (!flashSale) {
      return false
   }

   const { start_at, end_at, status, price_sale } = flashSale
   const curDate = new Date().getTime()

   return Boolean(
      status && curDate >= start_at && curDate <= end_at && price_sale > 0
   )
}

export const isValidUrl = (url: string): boolean => {
   return urlPattern.test(url)
}

export const validateEmail = (email: string): boolean => {
   return emailRegex.test(email)
}
