import { FC, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { CircleLoading, Button, Icon, PopupNoti } from '../../../components'
import Table, {
   TableRowType,
   HeadCell,
   FilterType,
   TableCellDataType,
} from '../../../components/Table'
import {
   PageHeader,
   PageContent,
   PageTitle,
   PageWrapper,
} from '../../../layout'
import {
   selectManager,
   getAllManagers,
   useAppDispatch,
   useAppSelector,
   revokeManager,
   selectFetch,
   resetOrders,
   resetManagers,
   grantPermissionManager,
} from '../../../redux'
import { ReactComponent as PenIcon } from '../../../assets/icons/pen.svg'
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg'
import { ReactComponent as ReloadIcon } from '../../../assets/icons/reload.svg'
import { ReactComponent as CheckIcon } from '../../../assets/icons/tick.svg'
import { colorPalette } from '../../../config'
import { ActiveStatus, ManagerData, ManagerPermission } from '../../../types'
import { managerGrantPermMsg, warningMsg } from '../../../schemas'
import { getPermission } from '../../../utils'
import { useTranslation } from 'react-i18next'
import useFilteredQuery from '../../../hooks/use-swr/use-query-with-filter'

type ToRevokeManager = {
   id: string
   isActive: boolean
}

const ManagersTable: FC = () => {
   const dispatch = useAppDispatch()
   // const { managerLoading } = useAppSelector(selectManager)
   const { uploading } = useAppSelector(selectFetch)
   const { t } = useTranslation(['common', 'inputs', 'components'])

   const {
      controlMethods: { handleAddSearchCriteria, handleRemoveSearchCriteria },
      response: { isLoading, isValidating, data: managers, mutate },
   } = useFilteredQuery<ManagerData[]>({ url: 'manager' })

   const [managerToRevoke, setManagerToRevoke] =
      useState<ToRevokeManager | null>(null)
   const [openAlert, setOpenAlert] = useState<boolean>(false)

   const headCells: HeadCell[] = [
      {
         isId: true,
         id: '_id',
         numeric: false,
         disablePadding: false,
         filterType: FilterType.TEXT,
         label: 'ID',
      },
      {
         id: 'email',
         numeric: false,
         disablePadding: false,
         filterType: FilterType.TEXT,
         label: t('common.email', { ns: 'rows' }),
      },
      {
         id: 'permission',
         numeric: false,
         disablePadding: false,
         filterType: FilterType.OPTION,
         label: t('manager.permission', { ns: 'rows' }),
      },
      {
         id: 'is_active',
         numeric: false,
         disablePadding: false,
         filterType: FilterType.OPTION,
         label: t('common.status', { ns: 'rows' }),
      },
      {
         id: 'updated_at',
         numeric: false,
         disablePadding: false,
         filterType: FilterType.DATE,
         label: t('common.updatedAt', { ns: 'rows' }),
      },
   ]

   const handleOnRevoke = (manager_id: string, isActive: boolean) => {
      setOpenAlert(true)
      setManagerToRevoke({ id: manager_id, isActive })
   }

   const handleRevoke = () => {
      if (!managerToRevoke?.id) {
         return
      }

      if (managerToRevoke.isActive) {
         dispatch(revokeManager(managerToRevoke.id))
      } else {
         dispatch(grantPermissionManager(managerToRevoke.id))
      }
      mutate({
         data: managers?.reduce<ManagerData[]>((acc, cur) => {
            acc.push(
               cur._id === managerToRevoke.id
                  ? { ...cur, is_active: !cur.is_active }
                  : cur
            )
            return acc
         }, []),
         statusCode: 200,
      })
   }

   const handleOnSearch = (searchTerm: string, type: string) => {
      if (!searchTerm) {
         return handleRemoveSearchCriteria(type)
      }

      return handleAddSearchCriteria(type, searchTerm)
   }

   const rows: TableRowType[] = useMemo(
      () =>
         managers
            ? managers.map((manager) => {
                 return {
                    key: manager._id,
                    cells: [
                       {
                          identifier: '_id',
                          type: TableCellDataType.ID,
                          data: manager._id,
                       },
                       {
                          identifier: 'email',
                          type: TableCellDataType.TEXT,
                          data: manager.email,
                       },
                       {
                          identifier: 'permission',
                          type: TableCellDataType.TAG,
                          data: t(
                             `permission.${getPermission(manager.permission)}`
                          ) as string,
                          tagColorMap: [
                             {
                                value: t(
                                   `permission.${getPermission(
                                      ManagerPermission.ADMIN
                                   )}`
                                ),
                                color: 'success',
                             },
                             {
                                value: t(
                                   `permission.${getPermission(
                                      ManagerPermission.PACKAGE_MAN
                                   )}`
                                ),
                                color: 'warning',
                             },
                             {
                                value: t(
                                   `permission.${getPermission(
                                      ManagerPermission.SHOP_KEEPER
                                   )}`
                                ),
                                color: 'info',
                             },
                          ],
                       },
                       {
                          identifier: 'is_active',
                          type: TableCellDataType.TAG,
                          data: t(
                             `status.${
                                ActiveStatus[
                                   manager.is_active ? 'ACTIVE' : 'DEACTIVATED'
                                ]
                             }`
                          ) as string,
                          tagColorMap: [
                             {
                                value: t(`status.${ActiveStatus.ACTIVE}`),
                                color: 'success',
                             },
                             {
                                value: t(`status.${ActiveStatus.DEACTIVATED}`),
                                color: 'error',
                             },
                          ],
                       },
                       {
                          identifier: 'updated_at',
                          type: TableCellDataType.DATE,
                          data: manager.updated_at || manager.created_at,
                       },
                       {
                          identifier: 'action',
                          type: TableCellDataType.ACTION,
                          data: [
                             {
                                icon: {
                                   src: PenIcon,
                                   color: colorPalette.grey,
                                   tooltip: t('tooltip.common.edit', {
                                      ns: 'components',
                                   }) as string,
                                },
                                link: `/managers/${manager._id}`,
                             },
                             {
                                icon: {
                                   src: manager.is_active
                                      ? ReloadIcon
                                      : CheckIcon,
                                   color: manager.is_active
                                      ? colorPalette.red.shade_500
                                      : colorPalette.green.shade_500,
                                   tooltip: manager.is_active
                                      ? (t('tooltip.common.revoke', {
                                           ns: 'components',
                                        }) as string)
                                      : 'Kích hoạt',
                                },
                                handleOnClick: () =>
                                   handleOnRevoke(
                                      manager._id,
                                      manager.is_active
                                   ),
                             },
                          ],
                       },
                    ],
                 }
              })
            : [],
      [managers]
   )

   useEffect(() => {
      if (!uploading && managerToRevoke) {
         mutate({
            data: managers?.reduce<ManagerData[]>((acc, cur) => {
               acc.push(
                  cur._id === managerToRevoke.id
                     ? { ...cur, is_active: !cur.is_active }
                     : cur
               )
               return acc
            }, []),
            statusCode: 200,
         })
         setManagerToRevoke(null)
      }
   }, [uploading])

   return (
      <>
         <PopupNoti
            open={openAlert}
            setOpen={setOpenAlert}
            message={
               managerToRevoke?.isActive ? warningMsg : managerGrantPermMsg
            }
            action={() => handleRevoke()}
         />
         <PageWrapper>
            <PageHeader>
               <PageTitle type='header' title={t('pageTitle.manager.all')} />
               <Button
                  customsize='sm'
                  variant='contained'
                  endIcon={
                     <Icon
                        type='fill'
                        src={PlusIcon}
                        color={colorPalette.white}
                        width={15}
                        height={15}
                     />
                  }
                  style={{ marginLeft: '5px' }}
                  component={Link as any}
                  to='create'
               >
                  {t('buttons.manager.create', { ns: 'components' })}
               </Button>
            </PageHeader>
            <PageContent>
               <Table
                  loading={isLoading || isValidating}
                  initialSort='is_active'
                  initialSearchTermType='email'
                  title={t('tableTitle.manager')}
                  headCells={headCells}
                  rows={rows}
                  withAction
                  onSearchTermChange={handleOnSearch}
               />
            </PageContent>
         </PageWrapper>
      </>
   )
}

export default ManagersTable
