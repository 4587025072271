import { FC, ReactNode } from 'react'
import { styled } from '@mui/system'
import { Box, Paper, Typography, Button } from '@mui/material'
import { colorPalette, typography } from '../config'

interface PageContentTitleProps {
   title: string
   type: 'header' | 'content'
   otherContent?: React.ReactNode
   withIcons?: boolean
}

export const DrawerHeader = styled('div')(() => {
   return {
      display: 'flex',
      minHeight: '112px !important',
      cursor: 'pointer',
      alignItems: 'center',
      justifyContent: 'flex-start',
      borderRight: `1px solid ${colorPalette.stroke}`,
      // necessary for content to be below app bar
      // ...theme.mixins.toolbar,
   }
})

export const PageHeader = styled(Box)(() => {
   return {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '16px',
   }
})

export const PageContent = styled(Paper)(() => {
   return {
      widht: '100%',
      height: '100%',
      borderRadius: '16px',
      marginBottom: '16px',
      padding: '16px',
      backgroundColor: colorPalette.white,
      boxShadow: 'none',
   }
})

export const MetaDataBox = styled(Box)(() => ({
   border: `1px solid ${colorPalette.lightGrey}`,
   padding: '24px 16px',
   borderRadius: '16px',
   position: 'relative',
   height: '100%',
}))

export const RemoveMetaDataBtn = styled(Button)(() => ({
   position: 'absolute',
   top: '-12px',
   right: '-12px',
   zIndex: 3,
   padding: '8px',
   borderRadius: '50%',
   display: 'flex',
   justifyContent: 'center',
   alignItems: 'center',
   minWidth: 0,
}))

export const PageTitle: FC<PageContentTitleProps> = ({
   title,
   type,
   otherContent,
   withIcons = false,
}) => {
   return (
      <Typography
         className={typography.pc[type === 'header' ? 'h6' : 's3']}
         color={colorPalette.dark}
         component='div'
         sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            overflowWrap: 'break-word',
            display: '-webkit-box',
            WebkitLineClamp: 1,
            WebkitBoxOrient: 'vertical',
            ...(type === 'content' && {
               paddingBottom: '16px',
               borderBottom: `1px solid ${colorPalette.line}`,
               marginBottom: '16px',
               display: 'flex',
               justifyContent: 'space-between',
               alignItems: 'center',
            }),
            minHeight: withIcons ? '57px' : 0,
         }}
         {...(type === 'content' && { textTransform: 'uppercase' })}
      >
         {title}
         {otherContent}
      </Typography>
   )
}

export const PageWrapper: FC<{ children?: ReactNode }> = ({ children }) => {
   return (
      <Box sx={{ display: 'flex', flexDirection: 'column', padding: '20px' }}>
         {children}
      </Box>
   )
}
