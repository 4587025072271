import { KeyedMutator } from 'swr'
import { Maybe, DeviotVideo } from '../../types'
import { useQuery } from '../use-swr/use-query'
import { APIReturnData } from '../use-fetch-handlers'

type UseVideos = () => {
   isLoading: boolean
   videos?: Maybe<DeviotVideo[]>
   error: any
   mutate: KeyedMutator<APIReturnData<DeviotVideo[]>>
}

type UseVideoDetail = (id: string) => {
   isLoading: boolean
   videoDetail?: Maybe<DeviotVideo>
   error: any
   mutate: KeyedMutator<APIReturnData<DeviotVideo>>
}

export const useVideos: UseVideos = () => {
   const { data, isLoading, error, mutate } = useQuery<DeviotVideo[]>({
      url: 'video-intro',
      secured: true,
      fetchOptions: {
         refreshInterval: 60000,
         revalidateIfStale: true,
      },
   })

   return {
      isLoading,
      error,
      mutate,
      videos: data?.data,
   }
}

export const useVideoDetail: UseVideoDetail = (id: string) => {
   const { data, isLoading, error, mutate } = useQuery<DeviotVideo>({
      url: `video-intro/${id}`,
      secured: true,
      fetchOptions: {
         refreshInterval: 60000,
         revalidateIfStale: true,
      },
   })

   return {
      isLoading,
      error,
      mutate,
      videoDetail: data?.data,
   }
}
