import { FC, useEffect } from 'react'
import { Box, Typography, Grid } from '@mui/material'
import { useForm } from 'react-hook-form'
import { Link, useParams } from 'react-router-dom'
import { colorPalette, typography } from '../../../config'
import {
   NavIconButton,
   Button,
   LinearLoading,
   CircleLoading,
   ProducTypeForm,
} from '../../../components'
import {
   PageContent,
   PageHeader,
   PageTitle,
   PageWrapper,
} from '../../../layout'
import { Status } from '../../../types'
import { yupResolver } from '@hookform/resolvers/yup'
import {
   createProductType,
   getProductTypeDetail,
   selectFetch,
   selectProduct,
   updateProductType,
   useAppDispatch,
   useAppSelector,
} from '../../../redux'
import { productTypeCreateFormSchema } from '../../../schemas/productSchema'
import { useTranslation } from 'react-i18next'
import { defaultProductTypeFormValues } from '../../../components/ProductForm/ProductTypeForm'
import { useProductTypeDetail } from '../../../hooks/use-redux/use-products'

interface ProductTypeCreateFormValues {
   product_type_name: string
}

const ProductTypeDetail: FC = () => {
   const { productTypeId } = useParams()
   const { productTypeDetail, dispatch, response, isLoading, isUploading } =
      useProductTypeDetail(productTypeId)
   const { t } = useTranslation(['common', 'inputs', 'components'])

   const {
      control,
      handleSubmit,
      reset,
      setValue,
      formState: { errors, isDirty },
   } = useForm<ProductTypeCreateFormValues>({
      defaultValues: productTypeDetail,
      resolver: yupResolver(productTypeCreateFormSchema),
   })

   const onSubmit = (data: ProductTypeCreateFormValues) => {
      if (productTypeId) {
         dispatch(
            updateProductType({
               productTypeId,
               productTypeName: data.product_type_name,
            })
         )
      }
   }

   useEffect(() => {
      if (productTypeDetail) {
         setValue('product_type_name', productTypeDetail.product_type_name)
      }
   }, [productTypeDetail])

   useEffect(() => {
      if (response?.status === Status.SUCCESS) {
         reset()
      }
   }, [response?.status])

   if (isLoading || !productTypeDetail) {
      return <CircleLoading />
   }

   return (
      <>
         {isUploading && <LinearLoading />}
         <PageWrapper>
            <PageHeader>
               <Box sx={{ display: 'flex' }}>
                  <NavIconButton
                     dir='left'
                     variant='text'
                     size='sm'
                     component={Link as any}
                     to='/product-types'
                     style={{ marginRight: '16px' }}
                  />
                  <Typography
                     className={typography.pc.h6}
                     color={colorPalette.dark}
                     component='div'
                  >
                     Loại sản phẩm: {productTypeDetail.product_type_name}
                  </Typography>
               </Box>
               <Button
                  disabled={!isDirty}
                  customsize='sm'
                  variant='contained'
                  type='submit'
                  onClick={handleSubmit(onSubmit)}
               >
                  {t('buttons.common.save', { ns: 'components' })}
               </Button>
            </PageHeader>
            <Grid container spacing={2}>
               <Grid item xs={12} sm={6}>
                  <PageContent>
                     <PageTitle
                        type='content'
                        title={t('blockTitle.general')}
                     />
                     <Box sx={{ width: '100%', paddingTop: '8px' }}>
                        {isLoading ? (
                           <CircleLoading />
                        ) : (
                           <ProducTypeForm errors={errors} control={control} />
                        )}
                     </Box>
                  </PageContent>
               </Grid>
            </Grid>
         </PageWrapper>
      </>
   )
}

export default ProductTypeDetail
