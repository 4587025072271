import { Paper, PaperProps, Stack } from '@mui/material'
import { colorPalette } from '../../config'
import { baseURL } from '../../hooks/use-fetch-handlers'
import BannerButton from './BannerButton'
import BannerText from './BannerText'
import DefaultBanner from './DefaultBanner'
import { BannerTextArea } from './styles'

export type BannerCardProps = PaperProps & {
   banner: {
      button_text?: string
      button_link?: string
      image_id?: string
      product?: string
      large_text_1?: string
      small_text_1?: string
   }
   preview?: boolean
}

const styledPaper = (Component: typeof Paper) => {
   return ({
      banner: { button_text, image_id, product, large_text_1, small_text_1 },
      preview = false,
      sx,
      ...restProps
   }: BannerCardProps) => {
      return (
         <Component
            elevation={0}
            sx={{
               ...(preview && {
                  border: `1px solid ${colorPalette.blue.shade_100}`,
                  borderRadius: 0,
               }),

               background: image_id
                  ? `url(${baseURL}/images/${image_id})`
                  : 'none',
               backgroundRepeat: 'no-repeat',
               backgroundSize: 'cover',
               backgroundPosition: 'center',
               width: '100%',
               paddingBottom: '37.5%',
               display: 'flex',
               flexDirection: 'column',
               justifyContent: 'center',
               alignItems: 'flex-start',
               position: 'relative',
               overflow: 'hidden',
               ...sx,
            }}
            {...restProps}
         >
            <BannerTextArea maxWidth='xl'>
               {large_text_1 && (
                  <BannerText size='lg'>{large_text_1}</BannerText>
               )}
               {small_text_1 && (
                  <BannerText size='sm'>{small_text_1}</BannerText>
               )}
               {button_text && (
                  <Stack direction='row' alignItems='center'>
                     <BannerButton>{button_text}</BannerButton>
                  </Stack>
               )}
            </BannerTextArea>
            {!image_id && <DefaultBanner />}
         </Component>
      )
   }
}

const BannerCard = styledPaper(Paper)

export default BannerCard
