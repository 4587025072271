import { useState } from 'react'
import { AttributeQuery, Maybe } from '../types'

export type FilterControlMethods = {
   handleChangeStartDate: (startDate: Maybe<number>) => void
   handleChangeEndDate: (endDate: Maybe<number>) => void
   handleChangePageSize: (pageSize: number) => void
   handleChangePageNum: (pageNum: number) => void
   handleAddSearchCriteria: (key: string, searchTerm: string) => void
   handleRemoveSearchCriteria: (key: string) => void
}

export type UseFilterReturn = FilterControlMethods & {
   filter: Maybe<AttributeQuery>
}

export const useFilter = (): UseFilterReturn => {
   const [filter, setFilter] = useState<Maybe<AttributeQuery>>(null)

   const handleChangePageNum = (pageNum: number) =>
      setFilter((prevFilter) => ({
         ...prevFilter,
         page: pageNum,
      }))

   const handleChangeStartDate = (startDate: Maybe<number>) =>
      setFilter((prevFilter) => ({ ...prevFilter, start_date: startDate }))

   const handleChangeEndDate = (endDate: Maybe<number>) => {
      setFilter((prevFilter) => ({ ...prevFilter, end_date: endDate }))
   }

   const handleChangePageSize = (pageSize: number) => {
      setFilter((prevFilter) => ({ ...prevFilter, limit: pageSize }))
   }

   const handleAddSearchCriteria = (key: string, searchTerm: string) =>
      setFilter((prevFilter) => {
         const currentCriterias = prevFilter?.search_criteria
         const newCriteria = {
            key,
            value: searchTerm,
         }

         if (!currentCriterias)
            return {
               ...prevFilter,
               search_criteria: searchTerm ? [newCriteria] : null,
            }

         const newCriterias = currentCriterias.reduce(
            (curResult, curCriteria) =>
               curCriteria.key === key
                  ? curResult
                  : [...curResult, curCriteria],
            searchTerm ? [newCriteria] : []
         )

         return { ...prevFilter, search_criteria: newCriterias }
      })

   const handleRemoveSearchCriteria = (key: string) =>
      setFilter((prevFilter) => {
         const currentCriterias = prevFilter?.search_criteria

         if (!currentCriterias)
            return {
               ...prevFilter,
               search_criteria: null,
            }

         return {
            ...prevFilter,
            search_criteria: currentCriterias.filter(
               (criteria) => criteria.key !== key
            ),
         }
      })

   return {
      filter,
      handleChangeStartDate,
      handleChangeEndDate,
      handleChangePageSize,
      handleChangePageNum,
      handleAddSearchCriteria,
      handleRemoveSearchCriteria,
   }
}
