import { FC, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { Link } from 'react-router-dom'
import { colorPalette } from '../../../config'
import {
   Button,
   Icon,
   PopupNoti,
   ProductsTable,
   PopupForm,
   Field,
   InputType,
   CircleLoading,
} from '../../../components'
import {
   PageContent,
   PageHeader,
   PageTitle,
   PageWrapper,
} from '../../../layout'
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg'
import { useTranslation } from 'react-i18next'
import { addToProgramSchema, warningMsg } from '../../../schemas'
import {
   addToPromotionProgram,
   deleteProduct,
   selectFetch,
   useAppDispatch,
   useAppSelector,
} from '../../../redux'
import { ReactComponent as BinIcon } from '../../../assets/icons/bin.svg'
import { ReactComponent as PenIcon } from '../../../assets/icons/pen.svg'
import { useProducts } from '../../../hooks/use-redux/use-products'
import useFilteredQuery from '../../../hooks/use-swr/use-query-with-filter'
import { WrappedProductData } from '../../../types'

export interface AddToPromotionProgramFormValues {
   product_id: string
   program_id: string
}

export const formFields: Field[] = [
   {
      name: 'product_id',
      label: 'Product ID',
      type: 'text',
      isHidden: true,
      inputType: InputType.INPUT,
      cols: 12,
      required: true,
   },
   {
      name: 'program_id',
      label: 'Program ID',
      type: 'text',
      isHidden: true,
      inputType: InputType.INPUT,
      cols: 12,
      required: true,
   },
]

const AllProducts: FC = () => {
   const { t } = useTranslation(['common', 'components'])
   const dispatch = useAppDispatch()
   const { uploading } = useAppSelector(selectFetch)
   const { isLoading, productTypes } = useProducts()
   const {
      filter,
      controlMethods,
      response: {
         isLoading: productLoading,
         data: products,
         paginationInfo,
         mutate,
      },
   } = useFilteredQuery<WrappedProductData[]>({ url: 'product' })

   const [selectedProduct, setSelectedProduct] = useState<string | null>(null)
   const [openAlert, setOpenAlert] = useState<boolean>(false)
   const [openForm, setOpenForm] = useState<boolean>(false)

   const handleOnDelete = (product_id: string) => {
      setOpenAlert(true)
      setSelectedProduct(product_id)
   }

   const handleDeleteProduct = () => {
      if (selectedProduct) {
         dispatch(deleteProduct(selectedProduct))
      }
   }

   useEffect(() => {
      if (!uploading && selectedProduct) {
         mutate({
            data: products?.reduce<WrappedProductData[]>((acc, cur) => {
               if (cur.product._id !== selectedProduct) {
                  acc.push(cur)
               }
               return acc
            }, []),
            statusCode: 200,
         })
         setSelectedProduct(null)
      }
   }, [uploading])

   const actions = (product_id: string) => {
      return [
         {
            icon: {
               src: PenIcon,
               color: colorPalette.grey,
               tooltip: t('tooltip.common.edit', {
                  ns: 'components',
               }),
            },
            link: `/products/${product_id}`,
         },
         {
            icon: {
               src: BinIcon,
               color: colorPalette.red.shade_500,
               tooltip: t('tooltip.common.delete', {
                  ns: 'components',
               }),
            },
            handleOnClick: () => handleOnDelete(product_id),
         },
      ]
   }

   const handleOnSubmit = (data: AddToPromotionProgramFormValues) => {
      dispatch(
         addToPromotionProgram({
            program_id: data.program_id,
            payload: {
               product_ids: [data.product_id],
            },
         })
      )
   }

   return (
      <>
         <PopupNoti
            open={openAlert}
            setOpen={setOpenAlert}
            message={warningMsg}
            action={() => handleDeleteProduct()}
         />
         <PopupForm
            title='Select promotion program'
            open={openForm}
            setOpen={setOpenForm}
            schema={addToProgramSchema}
            formFields={formFields}
            onSubmit={handleOnSubmit}
         />
         <PageWrapper>
            <PageHeader>
               <PageTitle type='header' title={t('pageTitle.product.all')} />
               <Box sx={{ display: 'flex' }}>
                  <Button
                     customsize='sm'
                     variant='contained'
                     endIcon={
                        <Icon
                           type='fill'
                           src={PlusIcon}
                           color={colorPalette.white}
                           width={15}
                           height={15}
                        />
                     }
                     style={{ marginLeft: '5px' }}
                     component={Link as any}
                     to='create'
                  >
                     {t('buttons.product.create', { ns: 'components' })}
                  </Button>
               </Box>
            </PageHeader>
            <PageContent>
               <ProductsTable
                  paginationInfo={paginationInfo}
                  isLoading={isLoading || productLoading}
                  productTypes={productTypes}
                  productsToShow={products?.map((product) => product.product)}
                  showActions
                  actions={actions}
                  filter={filter}
                  filterMethods={controlMethods}
               />
            </PageContent>
         </PageWrapper>
      </>
   )
}

export default AllProducts
