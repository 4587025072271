import {
   Typography as MuiTypography,
   TypographyProps as MuiTypographyProps,
} from '@mui/material'
import { banner, colorPalette } from '../../config'

type BannerTextProps = MuiTypographyProps & {
   size: 'lg' | 'md' | 'sm'
}

const styledTypography = (Component: typeof MuiTypography) => {
   return (props: BannerTextProps) => {
      const { size, children, ...restProps } = props

      const sizedClassName = {
         sm: banner.smallText,
         md: banner.mediumText,
         lg: banner.largeText,
      }

      return (
         <Component
            className={sizedClassName[size]}
            color={colorPalette.dark}
            {...restProps}
         >
            {children}
         </Component>
      )
   }
}

const BannerText = styledTypography(MuiTypography)

export default BannerText
