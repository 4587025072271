import Icon from '../Icon'
import { InputBaseProps } from '@mui/material'
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg'
import { Search, SearchIconWrapper, StyledInputBase } from './styles'
import { colorPalette, typography } from '../../config'

interface SearchBoxProps extends InputBaseProps {
   width: number
}

const styledSearchBox = (Component: typeof StyledInputBase) => {

   return ({ width, ...restProps }: SearchBoxProps) => {
      return (
         <Search width={width}>
            <SearchIconWrapper>
               <Icon
                  type='fill'
                  src={SearchIcon}
                  color={colorPalette.lightGrey}
               />
            </SearchIconWrapper>
            <Component
               className={typography.pc.descReg}
               inputProps={{ 'aria-label': 'search' }}
               {...restProps}
            />
         </Search>
      )
   }
}

const SearchBox = styledSearchBox(StyledInputBase)

export default SearchBox
