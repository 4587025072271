import { Box, IconButton, Modal, Typography } from '@mui/material'
import { FC } from 'react'
import {
   DiscardPreview,
   ModalContent,
   PopupContent,
   PopupFooter,
   PopupTitle,
   PreviewImg,
} from '../ImageUploader/styles'
import { colorPalette, typography } from '../../config'
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg'
import Icon from '../Icon'
import Button from '../Button'
import { baseURL } from '../../hooks/use-fetch-handlers'
import { Maybe } from '../../types'
import { Image } from './styles'

type ZoomedViewProps = {
   image: Maybe<string>
   open: boolean
   setOpen: (open: boolean) => void
}

const ZoomedView: FC<ZoomedViewProps> = ({ image, open, setOpen }) => {
   const handleClose = () => {
      setOpen(false)
   }

   return (
      <Modal
         open={open}
         onClose={handleClose}
         aria-labelledby='modal-modal-title'
         aria-describedby='modal-modal-description'
      >
         <ModalContent autoComplete='off'>
            <PopupTitle>
               <Typography
                  className={typography.pc.s2}
                  color={colorPalette.dark}
               >
                  Zoom
               </Typography>
               <IconButton onClick={handleClose}>
                  <Icon type='fill' color={colorPalette.dark} src={CloseIcon} />
               </IconButton>
            </PopupTitle>
            <PopupContent>
               <Box
                  sx={{
                     height: '50vh',
                     width: 'auto',
                  }}
               >
                  <Image
                     alt='zoomed-image'
                     src={`${baseURL}/images/${image}`}
                  />
               </Box>
            </PopupContent>
            <PopupFooter>
               <Box>
                  <Button
                     customsize='sm'
                     variant='outlined'
                     color='primary'
                     sx={{ marginRight: '8px' }}
                     onClick={handleClose}
                  >
                     Đóng
                  </Button>
               </Box>
            </PopupFooter>
         </ModalContent>
      </Modal>
   )
}

export default ZoomedView
