import { Paper } from '@mui/material'
import React from 'react'
import { colorPalette } from '../../config'
import {
   BannerGradientCirRight,
   BannerGradientCirLeft,
   BannerGradientCirBot,
} from './styles'

const DefaultBanner: React.FC = () => {
   return (
      <Paper
         elevation={0}
         sx={{
            bgcolor: colorPalette.background,
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 998,
         }}
      >
         <BannerGradientCirLeft elevation={0} />
         <BannerGradientCirBot elevation={0} />
         <BannerGradientCirRight elevation={0} />
      </Paper>
   )
}

export default DefaultBanner
