import { FC, useMemo } from 'react'
import Table, {
   TableCellDataType,
   TableRowType,
   CustomTableProps,
} from '../../components/Table'
import { FilterType } from '../../components/Table'
import { useTranslation } from 'react-i18next'
import {
   AttributeQuery,
   Maybe,
   ProductData,
   ProductTypeData,
} from '../../types'
import { FilterControlMethods } from '../../hooks/use-filter'
import { PaginationInfo } from '../../hooks/use-fetch-handlers'

type ProductsTableProps = CustomTableProps & {
   productsToShow?: ProductData[]
   productTypes?: ProductTypeData[]
   filterMethods: FilterControlMethods
   paginationInfo?: PaginationInfo
   filter: Maybe<AttributeQuery>
   isLoading?: boolean
}

const ProductsTable: FC<ProductsTableProps> = ({
   productTypes,
   productsToShow,
   showActions = false,
   filter,
   paginationInfo,
   filterMethods,
   isLoading,
   onSelected,
   actions,
}) => {
   const { t } = useTranslation(['common', 'rows', 'components'])

   const headCells = useMemo(
      () => [
         {
            isId: true,
            id: '_id',
            numeric: false,
            disablePadding: false,
            filterType: FilterType.TEXT,
            label: 'ID',
         },
         {
            id: 'image',
            numeric: false,
            disablePadding: false,
            filterType: FilterType.NONE,
            label: t('product.image', { ns: 'rows' }),
         },
         {
            id: 'product_name',
            numeric: false,
            disablePadding: false,
            filterType: FilterType.TEXT,
            label: t('product.name', { ns: 'rows' }),
         },
         {
            id: 'product_type',
            numeric: false,
            disablePadding: false,
            filterType: FilterType.TEXT,
            label: t('product.type', { ns: 'rows' }),
         },
         {
            id: 'price',
            numeric: true,
            disablePadding: false,
            filterType: FilterType.RANGE,
            label: t('common.price', { ns: 'rows' }),
         },
         {
            id: 'updated_at',
            numeric: false,
            disablePadding: false,
            filterType: FilterType.DATE,
            label: t('common.updatedAt', { ns: 'rows' }),
         },
      ],
      [t]
   )

   const rows: TableRowType[] = useMemo(
      () =>
         productsToShow && productTypes
            ? productsToShow.map((product, idx) => {
                 const productType = productTypes.find(
                    (type) => type._id === product.product_type
                 )

                 return {
                    key: product._id || `${idx}`,
                    cells: [
                       {
                          identifier: '_id',
                          type: TableCellDataType.ID,
                          data: product?._id || `${idx}`,
                       },
                       {
                          identifier: 'image',
                          type: TableCellDataType.IMAGE,
                          data: product?.image?.[0] || '',
                       },
                       {
                          identifier: 'product_name',
                          type: TableCellDataType.TEXT,
                          data: product.product_name,
                       },
                       {
                          identifier: 'product_type',
                          type: TableCellDataType.TEXT,
                          data:
                             productType?.product_type_name || 'Invalid type',
                       },
                       {
                          identifier: 'price',
                          type: TableCellDataType.UNIT,
                          data: product.price,
                          unit: 'đ',
                       },
                       {
                          identifier: 'updated_at',
                          type: TableCellDataType.DATE,
                          data:
                             product.updated_at ||
                             product.created_at ||
                             new Date(),
                       },
                       ...(showActions && actions
                          ? [
                               {
                                  identifier: 'action',
                                  type: TableCellDataType.ACTION,
                                  data: actions(product?._id || `${idx}`),
                               },
                            ]
                          : []),
                    ],
                 }
              })
            : [],
      [productsToShow, productTypes, actions, showActions]
   )

   const handleOnSearchTearmChange = (key: string, search_term: string) => {
      if (!search_term) {
         filterMethods.handleRemoveSearchCriteria(key)
      }

      filterMethods.handleAddSearchCriteria(key, search_term)
   }

   const renderTable = useMemo(
      () => (
         <Table
            loading={isLoading}
            title={t('tableTitle.product')}
            headCells={headCells}
            rows={rows}
            totalPage={paginationInfo?.pageTotal}
            totalRecords={paginationInfo?.totalDocument}
            pageNum={filter?.page}
            pageSize={filter?.limit}
            withAction={showActions}
            initialSort='product_name'
            initialSearchTermType='product_name'
            onSearchTermChange={handleOnSearchTearmChange}
            onSelected={onSelected}
         />
      ),
      [
         productsToShow,
         productTypes,
         t,
         showActions,
         headCells,
         rows,
         onSelected,
      ]
   )

   return renderTable
}

export default ProductsTable
