import { createAsyncThunk } from '@reduxjs/toolkit'
import { useFetchingHandler } from '../../hooks/use-fetch-handlers'
import { ManagerData, ManagerPermission, Status } from '../../types'
import { setResponse, setUploading } from '../fetch'

export interface AddManagerData {
   email: string
   password: string
   permission: ManagerPermission
}

export interface UpdateManagerDataPayload {
   password: string
}

export interface UpdateManagerData {
   managerId: string
   payload: UpdateManagerDataPayload
}

export const getAllManagers = createAsyncThunk(
   'managerState/getAllManager',
   async (_, { dispatch }) => {
      const { getHandler } = useFetchingHandler({ dispatch })
      try {
         const res = await getHandler<ManagerData[]>({
            url: 'manager',
            secured: true,
         })

         return {
            managers: res?.data,
         }
      } catch (err: any) {
         throw err
      }
   }
)

export const getOneManager = createAsyncThunk(
   'managerState/getOneManager',
   async (managerId: string, { dispatch }) => {
      const { getHandler } = useFetchingHandler({ dispatch })
      try {
         const res = await getHandler<ManagerData>({
            url: `manager/${managerId}`,
            secured: true,
         })

         return {
            managerDetail: res?.data,
         }
      } catch (err: any) {
         throw err
      }
   }
)

export const addManager = createAsyncThunk(
   'managerState/addManager',
   async (formValues: AddManagerData, { dispatch }) => {
      const { postHandler } = useFetchingHandler({ dispatch })
      try {
         dispatch(setUploading(true))
         dispatch(
            setResponse({
               status: Status.PENDING,
               message: 'Processing ...',
            })
         )
         const res = await postHandler<AddManagerData, ManagerData>({
            url: `manager`,
            secured: true,
            body: formValues,
         })

         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.SUCCESS,
               message: res.message,
            })
         )
      } catch (err: any) {
         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.ERROR,
               message: err?.response?.message,
            })
         )
         throw err
      }
   }
)

export const updatePasswordManager = createAsyncThunk(
   'managerState/updatePasswordManager',
   async ({ managerId, payload }: UpdateManagerData, { dispatch }) => {
      const { putHandler } = useFetchingHandler({ dispatch })
      try {
         dispatch(setUploading(true))
         dispatch(
            setResponse({
               status: Status.PENDING,
               message: 'Processing ...',
            })
         )
         const res = await putHandler<UpdateManagerDataPayload, ManagerData>({
            url: `manager/${managerId}`,
            secured: true,
            body: payload,
         })

         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.SUCCESS,
               message: res.message,
            })
         )
      } catch (err: any) {
         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.ERROR,
               message: err?.response?.message,
            })
         )
         throw err
      }
   }
)

export const revokeManager = createAsyncThunk(
   'managerState/revokeManager',
   async (managerId: string, { dispatch }) => {
      const { deleteHandler } = useFetchingHandler({ dispatch })
      try {
         dispatch(setUploading(true))
         dispatch(
            setResponse({
               status: Status.PENDING,
               message: 'Processing ...',
            })
         )
         const res = await deleteHandler<ManagerData>({
            url: `manager/${managerId}`,
            secured: true,
         })

         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.SUCCESS,
               message: res.message,
            })
         )
      } catch (err: any) {
         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.ERROR,
               message: err?.response?.message,
            })
         )
         throw err
      }
   }
)

export const grantPermissionManager = createAsyncThunk(
   'managerState/grantPermissionManager',
   async (managerId: string, { dispatch }) => {
      const { putHandler } = useFetchingHandler({ dispatch })
      try {
         dispatch(setUploading(true))
         dispatch(
            setResponse({
               status: Status.PENDING,
               message: 'Processing ...',
            })
         )
         const res = await putHandler<{ status: boolean }, ManagerData>({
            url: `manager/update/${managerId}`,
            secured: true,
            body: {
               status: true,
            },
         })

         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.SUCCESS,
               message: res.message,
            })
         )
      } catch (err: any) {
         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.ERROR,
               message: err?.response?.message,
            })
         )
         throw err
      }
   }
)
