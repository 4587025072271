import { useEffect } from 'react'
import {
   getAllProductTypes,
   getAllProducts,
   getProductTypeDetail,
   resetProductTypeDetail,
   resetProductTypes,
   resetProducts,
   selectFetch,
   selectProduct,
   useAppDispatch,
   useAppSelector,
} from '../../redux'
import { ProductTypeData, WrappedProductData } from '../../types'

type UseProducts = () => {
   isLoading: boolean
   productTypes?: ProductTypeData[]
   // products?: WrappedProductData[]
   isUploading: boolean
}

export const useProducts: UseProducts = () => {
   const dispatch = useAppDispatch()
   const { productLoading, productTypes } = useAppSelector(selectProduct)
   const { uploading } = useAppSelector(selectFetch)
   useEffect(() => {
      if (!uploading) {
         // dispatch(getAllProducts())
         dispatch(getAllProductTypes())
      }

      return () => {
         dispatch(resetProductTypes())
         // dispatch(resetProducts())
      }
   }, [uploading])

   return {
      isLoading: productLoading,
      productTypes,
      // products,
      isUploading: uploading,
   }
}

export const useProductTypeDetail = (productTypeId?: string) => {
   const { uploading, response } = useAppSelector(selectFetch)
   const { productLoading, productTypeDetail } = useAppSelector(selectProduct)
   const dispatch = useAppDispatch()

   useEffect(() => {
      if (!uploading && productTypeId) {
         dispatch(getProductTypeDetail(productTypeId))
      }

      return () => {
         dispatch(resetProductTypeDetail())
      }
   }, [uploading])

   return {
      isLoading: productLoading,
      productTypeDetail,
      isUploading: uploading,
      response,
      dispatch,
   }
}
