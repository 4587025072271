import { styled } from '@mui/system'
import { Container, Paper } from '@mui/material'
import { colorPalette } from '../../config'

export const BannerTextArea = styled(Container)(() => ({
    position: 'absolute',
    top: '50%',
    left: '0',
    maxWidth: '50%',
    transform: 'translate(0, -50%)',
    zIndex: 999,
}))

export const BannerBackground = styled(Paper)(() => ({
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    paddingBottom: '56.25%',
    borderRadius: 0,
    border: `1px solid ${colorPalette.blue.shade_100}`
}))

export const BannerGradientCirLeft = styled(Paper)(() => ({
    position: 'absolute',
    top: '46px',
    left: '-107px',
    width: '195.75px',
    height: '148.75px',
    background: 'linear-gradient(185.78deg, #61DCDF 0.38%, #886CFF 100%)',
    opacity: '0.4',
    filter: 'blur(100px)',
    transform: 'matrix(-1, 0, -0.01, -1, 0, 0)',
}))

export const BannerGradientCirBot = styled(Paper)(() => ({
    position: 'absolute',
    width: '100px',
    height: '100px',
    left: '253.5px',
    top: '96.5px',
    background: '#4F77FF',
    opacity: '0.3',
    filter: 'blur(100px)',
}))

export const BannerGradientCirRight = styled(Paper)(() => ({
    position: 'absolute',
    width: '190.25px',
    height: '185.75px',
    right: '-117.75px',
    top: '-51.5px',
    background: '#886CFF',
    opacity: '0.3',
    filter: 'blur(100px)',
}))