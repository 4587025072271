import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BillData, RevenueData, SoldProductData } from '../../types'
import { getStatistic, StatisticIndex, statisticMapping } from './statisticThunk'

export interface StatisticState {
   statisticLoading: boolean
   soldProducts: SoldProductData[]
   revenue?: RevenueData
   bill?: BillData
}

interface Payload {
   data: any
   index: StatisticIndex
}

const initialState: StatisticState = {
   statisticLoading: false,
   soldProducts: []
}

export const statisticSlice = createSlice({
   name: 'statisticState',
   initialState,
   reducers: {
      resetStatistics: (state) => {
         state.soldProducts = []
         state.revenue = undefined
         state.bill = undefined
      },
   },
   extraReducers: (builder) => {
      builder
         .addCase(getStatistic.pending, (state) => {
            state.statisticLoading = true
         })
         .addCase(getStatistic.fulfilled, (state, action: PayloadAction<Payload, string>) => {
            state.statisticLoading = false
            const index = StatisticIndex[action.payload.index]
            //@ts-ignore
            state[statisticMapping[index].state] = action.payload.data
         })
         .addCase(getStatistic.rejected, (state, action: any) => {
            state.statisticLoading = false
         })
   },
})

export const { resetStatistics } = statisticSlice.actions

export default statisticSlice.reducer
