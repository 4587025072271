import { forwardRef, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import Stack from '@mui/material/Stack'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { StyledTextField } from './styles'
import { typography } from '../../config'
import { PopperPlacementType } from '@mui/material'

interface DateTimePickerProps {
   disablePast?: boolean
   disableFuture?: boolean
   minDate?: number | Date | string | Dayjs
   maxDate?: number | Date | string | Dayjs
   label: string
   error?: boolean
   position?: PopperPlacementType
   helperText?: string
   value: any
   onChange: (value: Date) => void
}

const DateTimePicker = forwardRef(
   (
      {
         label,
         error = false,
         helperText = '',
         value: inputValue,
         disableFuture,
         disablePast,
         position,
         onChange,
         maxDate,
         minDate,
         ...props
      }: DateTimePickerProps,
      ref: any
   ) => {
      const [value, setValue] = useState<Dayjs | null>(inputValue)

      const handleChange = (newValue: Dayjs | null) => {
         setValue(newValue)
         const date = dayjs(newValue).toDate()
         onChange(date)
      }

      return (
         <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={3}>
               <MuiDateTimePicker
                  {...props}
                  PopperProps={{
                     placement: position,
                  }}
                  inputFormat='DD/MM/YYYY - HH:mm:ss'
                  className={typography.mb.b2}
                  label={label}
                  value={value}
                  disableFuture={disableFuture}
                  disablePast={disablePast}
                  minDate={minDate ? dayjs(minDate) : undefined}
                  maxDate={maxDate ? dayjs(maxDate) : undefined}
                  onChange={handleChange}
                  renderInput={(param: any) => (
                     <StyledTextField
                        ref={ref}
                        {...param}
                        error={error}
                        helperText={helperText}
                     />
                  )}
               />
            </Stack>
         </LocalizationProvider>
      )
   }
)

export default DateTimePicker
