import { FC, useEffect, useMemo, useState } from 'react'
import { Box, Grid } from '@mui/material'
import { Link } from 'react-router-dom'
import { colorPalette } from '../../../config'
import {
   Button,
   Icon,
   CircleLoading,
   ProductTypeBlock,
   PopupNoti,
} from '../../../components'
import { PageHeader, PageTitle, PageWrapper } from '../../../layout'
import {
   selectProduct,
   useAppDispatch,
   useAppSelector,
   selectFetch,
   getAllProductTypes,
   deleteProductType,
} from '../../../redux'
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg'
import { useTranslation } from 'react-i18next'
import { warningMsg } from '../../../schemas'

const ProductTypesTable: FC = () => {
   const dispatch = useAppDispatch()
   const { productLoading, productTypes } = useAppSelector(selectProduct)
   const { uploading } = useAppSelector(selectFetch)
   const { t } = useTranslation(['common', 'components'])

   const [selectedProductType, setSelectedProductType] = useState<
      string | null
   >(null)
   const [openAlert, setOpenAlert] = useState<boolean>(false)

   const handleOnDelete = (id: string) => {
      setOpenAlert(true)
      setSelectedProductType(id)
   }

   const handleDeleteProductType = () => {
      if (selectedProductType) {
         dispatch(deleteProductType(selectedProductType))
         setSelectedProductType(null)
      }
   }

   useEffect(() => {
      if (!uploading) {
         dispatch(getAllProductTypes())
      }
   }, [uploading])

   const renderItem = useMemo(
      () =>
         productTypes ? (
            <Grid container spacing={2}>
               {productTypes.map((type) => (
                  <Grid item xs={12} md={4} key={type._id}>
                     <ProductTypeBlock onDelete={handleOnDelete} type={type} />
                  </Grid>
               ))}
            </Grid>
         ) : null,
      [productTypes]
   )

   return (
      <>
         <PopupNoti
            open={openAlert}
            setOpen={setOpenAlert}
            message={warningMsg}
            action={() => handleDeleteProductType()}
         />
         <PageWrapper>
            <PageHeader>
               <PageTitle
                  type='header'
                  title={t('pageTitle.productType.all')}
               />
               <Box sx={{ display: 'flex' }}>
                  <Button
                     customsize='sm'
                     variant='contained'
                     endIcon={
                        <Icon
                           type='fill'
                           src={PlusIcon}
                           color={colorPalette.white}
                           width={15}
                           height={15}
                        />
                     }
                     style={{ marginLeft: '5px' }}
                     component={Link as any}
                     to='create'
                  >
                     {t('buttons.productType.create', { ns: 'components' })}
                  </Button>
               </Box>
            </PageHeader>
            {productLoading ? <CircleLoading /> : renderItem}
         </PageWrapper>
      </>
   )
}

export default ProductTypesTable
