import { Grid } from '@mui/material'
import { FC } from 'react'
import { Controller } from 'react-hook-form'
import Input from '../Input'
import { ProductTypeData } from '../../types'

type ProductTypeFromProps = {
   control: any
   errors: any
}

export const defaultProductTypeFormValues = {
   product_type_name: '',
}

const ProductTypeForm: FC<ProductTypeFromProps> = ({ control, errors }) => {
   return (
      <Grid container spacing={2}>
         <Grid item xs={12} sm={12}>
            <Controller
               name='product_type_name'
               control={control}
               rules={{ required: true }}
               render={({ field }) => (
                  <Input
                     label='Tên loại sản phẩm'
                     fullWidth
                     placeholder='Nhập tên loại sản phẩm'
                     type='text'
                     error={!!errors.product_type_name}
                     helperText={errors.product_type_name?.message}
                     {...field}
                  />
               )}
            />
         </Grid>
      </Grid>
   )
}

export default ProductTypeForm
