import { FC, useState } from 'react'
import { Box, Typography, Grid } from '@mui/material'
import { colorPalette, typography } from '../../config'
import { Button, ImageGallery, Icon } from '../../components'
import { ImageUploader } from '../../components'
import { PageContent, PageHeader, PageTitle, PageWrapper } from '../../layout'
import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg'
import { useTranslation } from 'react-i18next'

const Media: FC = () => {
   const { t } = useTranslation(['common', 'components'])

   const [openUploader, setOpenUploader] = useState<boolean>(false)

   return (
      <>
         <ImageUploader open={openUploader} setOpen={setOpenUploader} />
         <PageWrapper>
            <PageHeader>
               <Typography
                  className={typography.pc.h6}
                  color={colorPalette.dark}
                  component='div'
               >
                  {t('pageTitle.media')}
               </Typography>
               <Button
                  type='submit'
                  customsize='sm'
                  variant='contained'
                  onClick={() => setOpenUploader(true)}
               >
                  <Icon
                     type='fill'
                     src={UploadIcon}
                     color={colorPalette.white}
                     sx={{
                        marginRight: '8px',
                     }}
                  />
                  {t('buttons.media.upload', { ns: 'components' })}
               </Button>
            </PageHeader>
            <Grid container spacing={2}>
               <Grid item xs={12} sm={12}>
                  <PageContent>
                     <PageTitle
                        type='content'
                        title={t('blockTitle.mediaGallery')}
                     />
                     <Box sx={{ width: '100%' }}>
                        <ImageGallery variantIdentifier='' cols={6} />
                     </Box>
                  </PageContent>
               </Grid>
            </Grid>
         </PageWrapper>
      </>
   )
}

export default Media
