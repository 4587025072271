import * as yup from 'yup'
import { requiredMsg } from './common'

export const bannerCreateFormSchema = yup.object({
   button_text: yup.string().nullable(),
   button_link: yup.string().nullable(),
   product: yup.string().nullable(),
   large_text_1: yup.string().nullable(),
   small_text_1: yup.string().nullable(),
   image_id: yup.string().required(requiredMsg),
})
