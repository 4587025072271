import { FC, useEffect } from 'react'
import { Box, Typography, Grid } from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { colorPalette, typography } from '../../config'
import {
   NavIconButton,
   Button,
   Input,
   CircleLoading,
   ImageEditor,
   Select,
   SelectOption,
} from '../../components'
import { PageContent, PageHeader, PageTitle, PageWrapper } from '../../layout'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAppDispatch, useAppSelector, selectFetch } from '../../redux'
import { updateBrandSchema } from '../../schemas'
import { useTranslation } from 'react-i18next'
import { Status, StatusSaleAgent } from '../../types'
import { BrandPayload, createBrand } from '../../redux/cms'
import { getAgentStatus } from '../../utils'

const BrandCreate: FC = () => {
   const { response } = useAppSelector(selectFetch)
   const dispatch = useAppDispatch()
   const { t } = useTranslation(['common', 'inputs', 'components'])

   const {
      control,
      reset,
      setValue,
      handleSubmit,
      watch,
      formState: { errors, isDirty },
   } = useForm<BrandPayload>({
      defaultValues: {
         name: '',
         email: '',
         phone: '',
         description: '',
         thumbnail: '',
         address: '',
         status: StatusSaleAgent.APPROVED,
      },
      mode: 'onSubmit',
      resolver: yupResolver(updateBrandSchema),
   })
   const [thumbnail] = watch(['thumbnail'])

   const onSubmit = async (data: BrandPayload) => {
      dispatch(createBrand(data))
   }

   const handleChangeMainImage = (imagesToUpload: string[]) => {
      setValue('thumbnail', imagesToUpload[0], { shouldDirty: true })
   }

   useEffect(() => {
      if (response?.status === Status.SUCCESS) {
         reset()
      }
   }, [response?.status])

   const renderForm = (
      <Grid container spacing={2}>
         <Grid item xs={12} sm={6}>
            <Controller
               name='name'
               control={control}
               render={({ field }) => (
                  <Input
                     label={'Tên đại lý'}
                     fullWidth
                     placeholder={'Tên đại lý'}
                     type='text'
                     error={!!errors.name}
                     helperText={errors?.name?.message}
                     {...field}
                  />
               )}
            />
         </Grid>
         <Grid item xs={12} sm={6}>
            <Controller
               name='phone'
               control={control}
               render={({ field }) => (
                  <Input
                     label={'Số điện thoại'}
                     fullWidth
                     placeholder={'Nhập số điện thoại'}
                     type='text'
                     error={!!errors.phone}
                     helperText={errors?.name?.message}
                     {...field}
                  />
               )}
            />
         </Grid>
         <Grid item xs={12} sm={12}>
            <Controller
               name='email'
               control={control}
               render={({ field }) => (
                  <Input
                     label={'Email'}
                     fullWidth
                     placeholder={'Email'}
                     type={'text'}
                     error={!!errors.email}
                     helperText={errors?.email?.message}
                     {...field}
                  />
               )}
            />
         </Grid>
         <Grid item xs={12} sm={12}>
            <Controller
               name='address'
               control={control}
               rules={{ required: true }}
               render={({ field }) => (
                  <Input
                     label={'Địa chỉ'}
                     fullWidth
                     placeholder={'Địa chỉ'}
                     type={'text'}
                     error={!!errors.address}
                     helperText={errors?.address?.message}
                     {...field}
                  />
               )}
            />
         </Grid>
         <Grid item xs={12} sm={12}>
            <Controller
               name='description'
               control={control}
               rules={{ required: true }}
               render={({ field }) => (
                  <Input
                     label={'Mô tả kinh doanh'}
                     fullWidth
                     placeholder={'Mô tả kinh doanh'}
                     type={'text'}
                     error={!!errors.description}
                     helperText={errors?.description?.message}
                     {...field}
                  />
               )}
            />
         </Grid>
         <Grid item xs={12} sm={12}>
            <Controller
               name='status'
               control={control}
               rules={{ required: true }}
               render={({ field }) => (
                  <Select
                     label={
                        t('common.permission.label', {
                           ns: 'inputs',
                        }) as string
                     }
                     {...field}
                  >
                     {Object.values(StatusSaleAgent).map((item) => (
                        <SelectOption key={item} value={item}>
                           {getAgentStatus(item)}
                        </SelectOption>
                     ))}
                  </Select>
               )}
            />
         </Grid>
         <Grid item xs={12}>
            <Typography
               className={typography.pc.s3}
               color={colorPalette.dark}
               sx={{ marginTop: '16px' }}
            >
               Chọn ảnh thumbnail
            </Typography>
            {errors?.thumbnail ? (
               <Typography
                  className={typography.pc.helpReg}
                  color={colorPalette.red.shade_500}
               >
                  Chưa chọn ảnh
               </Typography>
            ) : null}
            <ImageEditor
               productId={''}
               initialSelectedImgs={[thumbnail]}
               variantIdentifier={'main'}
               cols={8}
               onSelectedImgsChanges={handleChangeMainImage}
            />
         </Grid>
      </Grid>
   )

   return (
      <form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
         <PageWrapper>
            <PageHeader>
               <Box sx={{ display: 'flex' }}>
                  <NavIconButton
                     dir='left'
                     variant='text'
                     size='sm'
                     component={Link as any}
                     to='/brands'
                     style={{ marginRight: '16px' }}
                  />
                  <Typography
                     className={typography.pc.h6}
                     color={colorPalette.dark}
                     component='div'
                  >
                     Thêm đại lý
                  </Typography>
               </Box>
               <Button
                  disabled={!isDirty}
                  type='submit'
                  customsize='sm'
                  variant='contained'
               >
                  {t('buttons.common.save', { ns: 'components' })}
               </Button>
            </PageHeader>
            <Grid container spacing={2}>
               <Grid item xs={12} sm={12}>
                  <PageContent>
                     <PageTitle
                        type='content'
                        title={t('blockTitle.general')}
                     />
                     <Box sx={{ width: '100%', paddingTop: '8px' }}>
                        {renderForm}
                     </Box>
                  </PageContent>
               </Grid>
            </Grid>
         </PageWrapper>
      </form>
   )
}

export default BrandCreate
