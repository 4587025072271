import { createSlice } from '@reduxjs/toolkit'
import {
   getAllUsers,
   getOneUser,
} from './userThunk'
import { UserData } from '../../types'

interface ProductsState {
   loading: boolean
   users: UserData[]
   userDetail?: UserData
}

const initialState: ProductsState = {
   loading: false,
   users: [],
}

export const userSlice = createSlice({
   name: 'userState',
   initialState,
   reducers: {
      resetUsers: state => {
         state.users = []
      },
      resetUserDetail: state => {
         state.userDetail = undefined
      }
   },
   extraReducers: (builder) => {
      builder
         .addCase(getAllUsers.pending, (state) => {
            state.loading = true
         })
         .addCase(getAllUsers.fulfilled, (state, action: any) => {
            state.loading = false
            state.users = action.payload.users
         })
         .addCase(getAllUsers.rejected, (state, action: any) => {
            state.loading = false
         })
         .addCase(getOneUser.pending, (state) => {
            state.loading = true
         })
         .addCase(getOneUser.fulfilled, (state, action: any) => {
            state.loading = false
            state.userDetail = action.payload.userDetail
         })
         .addCase(getOneUser.rejected, (state, action: any) => {
            state.loading = false
         })
   },
})

export const { resetUserDetail, resetUsers } = userSlice.actions

export default userSlice.reducer
