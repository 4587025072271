import { FC } from 'react'
import { Link } from 'react-router-dom'
import { Box, Typography, Paper, Stack } from '@mui/material'
import Icon from '../Icon'
import IconButton from '../IconButton'
import { ProductTypeData } from '../../types'
import { colorPalette, typography } from '../../config'
import { ReactComponent as PenIcon } from '../../assets/icons/pen.svg'
import { ReactComponent as BinIcon } from '../../assets/icons/bin.svg'
import { getDate } from '../../utils/get-date'
import { useTranslation } from 'react-i18next'

interface ProductTypeBlockProps {
   type: ProductTypeData
   onDelete: (id: string) => void
}

const ProductTypeBlock: FC<ProductTypeBlockProps> = ({ type, onDelete }) => {
   const { t } = useTranslation(['components'])
   const { _id, product_type_name, created_at } = type
   const { day, time } = getDate(created_at || new Date().valueOf())

   return (
      <Paper
         sx={{
            borderRadius: '16px',
            borderColor: colorPalette.white,
            padding: '16px',
            width: '100%',
            height: '100%',
            textDecoration: 'none',
            display: 'flex',
            flexDirection: 'column',
            cursor: 'pointer',
         }}
      >
         <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='flex-start'
         >
            <Box>
               <Typography
                  className={typography.pc.h7}
                  color={colorPalette.dark}
               >
                  {product_type_name}
               </Typography>
               <Typography
                  className={typography.mb.descSemi}
                  color={colorPalette.lightGrey}
               >
                  <strong>{t('typeBlock.createdAt')}</strong> {time} {day}
               </Typography>
            </Box>
            <Stack direction='row'>
               <Link to={`/product-types/${_id}`}>
                  <IconButton
                     variant='outlined'
                     src={PenIcon}
                     iconColor={colorPalette.primary}
                     sx={{ marginRight: '4px' }}
                     customSize='sm'
                  >
                     <Icon
                        type='fill'
                        color={colorPalette.dark}
                        src={PenIcon}
                     />
                  </IconButton>
               </Link>
               <IconButton
                  variant='contained'
                  src={BinIcon}
                  color='error'
                  iconColor={colorPalette.red.shade_500}
                  sx={{ marginRight: '4px' }}
                  customSize='sm'
                  onClick={() => onDelete(_id)}
               >
                  <Icon
                     type='fill'
                     color={colorPalette.red.shade_500}
                     src={BinIcon}
                  />
               </IconButton>
            </Stack>
         </Stack>
         {/* <Stack
            direction='row-reverse'
            alignItems='center'
            sx={{ marginTop: '8px' }}
         >
            {product_type_prototype.map(({ slug, title }) => (
               <Tag
                  key={slug}
                  label={title}
                  color='primary'
                  variant='filled'
                  sx={{ marginRight: '4px' }}
               />
            ))}
         </Stack> */}
      </Paper>
   )
}

export default ProductTypeBlock
