import React from 'react'
import { IconButton, IconButtonProps } from '@mui/material'
import { Link } from 'react-router-dom'
import Icon from '../Icon'
import { ReactComponent as ArrowBackIcon } from '../../assets/icons/arrow-left.svg'
import { colorPalette } from '../../config'

type BackButtonProps = IconButtonProps & {
   to: string
}

const styledIconButton = (Component: typeof IconButton) => {
   return (props: BackButtonProps) => {
      const { sx, to } = props

      const styles = {
         backgroundColor: colorPalette.white,
         width: '40px',
         height: '40px',
         marginRight: '16px',
      }

      return (
         <Component component={Link} to={to} sx={{ ...sx, ...styles }}>
            <Icon
               src={ArrowBackIcon}
               type='fill'
               color={colorPalette.primary}
            />
         </Component>
      )
   }
}

const BackButton = styledIconButton(IconButton)

export default BackButton
