import { ChangeEvent, FC, useEffect, useMemo } from 'react'
import { Box, Typography, Grid } from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import { Link, Navigate } from 'react-router-dom'
import { colorPalette, typography } from '../../../config'
import {
   NavIconButton,
   Button,
   Input,
   DateTimePicker,
} from '../../../components'
import {
   PageContent,
   PageHeader,
   PageTitle,
   PageWrapper,
} from '../../../layout'
import { yupResolver } from '@hookform/resolvers/yup'
import {
   useAppDispatch,
   useAppSelector,
   selectFetch,
   CreateVoucherData,
   createVoucher,
} from '../../../redux'
import { voucherCreateSchema } from '../../../schemas'
import { useTranslation } from 'react-i18next'
import { Status } from '../../../types'
import {
   transformNumberInput,
   transformNumberOutput,
} from '../../../utils/validation'

const VoucherCreate: FC = () => {
   const { response } = useAppSelector(selectFetch)
   const dispatch = useAppDispatch()
   const { t } = useTranslation(['common', 'inputs', 'components'])

   const {
      control,
      reset,
      setValue,
      handleSubmit,
      formState: { errors, isDirty },
   } = useForm<CreateVoucherData>({
      defaultValues: {
         quantity: 1,
         value: 1000,
         expired_at: new Date().valueOf(),
      },
      mode: 'onChange',
      resolver: yupResolver(voucherCreateSchema),
   })

   const onSubmit = async (data: CreateVoucherData) => {
      dispatch(createVoucher(data))
   }

   useEffect(() => {
      if (response?.status === Status.SUCCESS) {
         reset()
      }
   }, [response?.status])

   const renderForm = (
      <Grid container spacing={2}>
         <Grid item xs={12} sm={12}>
            <Controller
               name='quantity'
               control={control}
               render={({ field: { value, onChange, ...restField } }) => (
                  <Input
                     label={'Số lượng'}
                     fullWidth
                     placeholder={'Số lượng'}
                     type='text'
                     error={!!errors.quantity}
                     helperText={errors?.quantity?.message}
                     value={value ? transformNumberInput(value.toString()) : ''}
                     onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        onChange(parseInt(transformNumberOutput(e)))
                     }
                     {...restField}
                  />
               )}
            />
         </Grid>
         <Grid item xs={12} sm={12}>
            <Controller
               name='value'
               control={control}
               render={({ field: { value, onChange, ...restField } }) => (
                  <Input
                     label={'Giá trị'}
                     fullWidth
                     placeholder={'Giá trị'}
                     type={'text'}
                     error={!!errors.value}
                     helperText={errors?.value?.message}
                     value={value ? transformNumberInput(value.toString()) : ''}
                     onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        onChange(parseInt(transformNumberOutput(e)))
                     }
                     endAdornment={
                        <Typography
                           className={typography.pc.descSemi}
                           color={colorPalette.dark}
                           component='span'
                        >
                           đ
                        </Typography>
                     }
                     {...restField}
                  />
               )}
            />
         </Grid>
         <Grid item xs={12} sm={12}>
            <Controller
               name='expired_at'
               control={control}
               rules={{ required: true }}
               render={({ field }) => {
                  const handleChange = (value: Date) => {
                     const date = new Date(value)
                     setValue('expired_at', date.valueOf(), {
                        shouldDirty: true,
                     })
                  }

                  return (
                     <DateTimePicker
                        {...field}
                        value={field.value}
                        error={!!errors.expired_at}
                        disablePast
                        helperText={errors?.expired_at?.message as string}
                        label='Ngày hết hạn'
                        onChange={handleChange}
                     />
                  )
               }}
            />
         </Grid>
      </Grid>
   )

   return (
      <form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
         <PageWrapper>
            <PageHeader>
               <Box sx={{ display: 'flex' }}>
                  <NavIconButton
                     dir='left'
                     variant='text'
                     size='sm'
                     component={Link as any}
                     to='/promotion/vouchers'
                     style={{ marginRight: '16px' }}
                  />
                  <Typography
                     className={typography.pc.h6}
                     color={colorPalette.dark}
                     component='div'
                  >
                     Thêm voucher
                  </Typography>
               </Box>
               <Button
                  disabled={!isDirty}
                  type='submit'
                  customsize='sm'
                  variant='contained'
               >
                  {t('buttons.common.save', { ns: 'components' })}
               </Button>
            </PageHeader>
            <Grid container spacing={2}>
               <Grid item xs={12} sm={6}>
                  <PageContent>
                     <PageTitle
                        type='content'
                        title={t('blockTitle.general')}
                     />
                     <Box sx={{ width: '100%', paddingTop: '8px' }}>
                        {renderForm}
                     </Box>
                  </PageContent>
               </Grid>
            </Grid>
         </PageWrapper>
      </form>
   )
}

export default VoucherCreate
