import { KeyedMutator } from 'swr'
import { Maybe, SetupPostData } from '../../types'
import { useQuery } from '../use-swr/use-query'
import { APIReturnData } from '../use-fetch-handlers'

type UseSetupPosts = () => {
   isLoading: boolean
   setupPosts?: Maybe<SetupPostData[]>
   error: any
   mutate: KeyedMutator<APIReturnData<SetupPostData[]>>
}

type UseSetupPostDetail = (id: string) => {
   isLoading: boolean
   setupPostDetail?: Maybe<SetupPostData>
   error: any
   mutate: KeyedMutator<APIReturnData<SetupPostData>>
}

export const useSetupPosts: UseSetupPosts = () => {
   const { data, isLoading, error, mutate } = useQuery<SetupPostData[]>({
      url: 'instruction-post',
      secured: true,
      fetchOptions: {
         refreshInterval: 60000,
         revalidateIfStale: true,
      },
   })

   return {
      isLoading,
      error,
      setupPosts: data?.data,
      mutate
   }
}

export const useSetupPostDetail: UseSetupPostDetail = (id: string) => {
   const { data, isLoading, error, mutate } = useQuery<SetupPostData>({
      url: `instruction-post/${id}`,
      secured: true,
      fetchOptions: {
         refreshInterval: 60000,
         revalidateIfStale: true,
      },
   })

   return {
      isLoading,
      error,
      mutate,
      setupPostDetail: data?.data,
   }
}
