import { FC, useState } from 'react'
import { Typography, Tooltip, Stack } from '@mui/material'
import { PageContent, PageTitle } from '../../layout'
import IconButton from '../IconButton'
import ImageGallery from '../ImageGallery'
import { colorPalette, typography } from '../../config'
import { useAppSelector, selectMedia } from '../../redux'
import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg'
import { ReactComponent as TickIcon } from '../../assets/icons/tick.svg'
import ImageUploader from '../ImageUploader'
import { useTranslation } from 'react-i18next'

interface ImageEditorProps {
   variantIdentifier: string
   productId?: string
   initialSelectedImgs?: string[]
   initialImgsAsStorage?: string[]
   multipleSelect?: boolean
   cols?: number
   onSelectedImgsChanges?: (imgs: string[]) => void
}

const ImageEditor: FC<ImageEditorProps> = ({
   productId,
   variantIdentifier,
   initialSelectedImgs,
   initialImgsAsStorage,
   cols = 4,
   multipleSelect = false,
   onSelectedImgsChanges,
}) => {
   const [openUploader, setOpenUploader] = useState<boolean>(false)
   const [selectedCount, setSelectedCount] = useState<number>(
      initialSelectedImgs?.length || 0
   )
   const { mediaLoading } = useAppSelector(selectMedia)
   const { t } = useTranslation(['components', 'common'])

   const handleOnSelectedChange = (num: number) => setSelectedCount(num)

   return (
      <>
         <ImageUploader open={openUploader} setOpen={setOpenUploader} />
         <PageContent>
            <PageTitle
               type='content'
               title={t('blockTitle.mediaGallery', { ns: 'common' })}
               otherContent={
                  <Stack direction='row' alignItems='center'>
                     <Typography
                        className={typography.pc.helpSemi}
                        color={colorPalette.red.shade_500}
                        textTransform='none'
                        sx={{ marginRight: '8px' }}
                     >
                        Chú ý: Hãy tải ảnh mới lên trước khi chỉnh sửa các thông tin khác
                     </Typography>
                     {!mediaLoading && (
                        <Typography
                           className={typography.pc.helpSemi}
                           color={colorPalette.lightGrey}
                           textTransform='none'
                           sx={{ marginRight: '8px' }}
                        >
                           {selectedCount} {t('imageGallery.itemSelected')}
                        </Typography>
                     )}
                     <Tooltip
                        title={t('tooltip.media.upload')}
                        placement='bottom'
                     >
                        <IconButton
                           src={UploadIcon}
                           variant='outlined'
                           customSize='md'
                           iconColor={colorPalette.primary}
                           onClick={() => setOpenUploader(true)}
                        />
                     </Tooltip>
                  </Stack>
               }
            />
            <ImageGallery
               initialImagesAsStorage={initialImgsAsStorage}
               initialSeletedImages={initialSelectedImgs}
               variantIdentifier={variantIdentifier}
               multipleSelect={multipleSelect}
               selector
               cols={cols}
               onSelectedImgsChanges={onSelectedImgsChanges}
               selectedCount={selectedCount}
               onSelectedCountChange={handleOnSelectedChange}
            />
         </PageContent>
      </>
   )
}

export default ImageEditor
