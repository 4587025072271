import { FC } from 'react'
import { Paper, Button, Typography } from '@mui/material'
import { useLocation, Link } from 'react-router-dom'
import { colorPalette, typography } from '../../config'
import { useTranslation } from 'react-i18next'

interface HeaderTabProps {
   links: {
      label: string
      href: string
   }[]
   linkPrefix: string
}

const replaceAll = (
   findAnyWith: string[],
   replaceWith: string,
   value: string
): string => {
   let result: string = value
   findAnyWith.forEach((str, idx) => {
      result = result.replaceAll(str, replaceWith)
   })

   return result
}

const HeaderTab: FC<HeaderTabProps> = ({ links, linkPrefix }) => {
   const { pathname } = useLocation()
   const { t } = useTranslation(['common'])

   return (
      <Paper
         elevation={0}
         sx={{
            width: '100%',
            padding: '16px',
            borderRadius: '16px',
            backgroundColor: colorPalette.white,
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
         }}
      >
         {links.map(({ href, label }) => {
            const isActive =
               replaceAll(['/', linkPrefix], '', pathname) === href

            return (
               <Button
                  component={Link}
                  to={href}
                  key={href}
                  variant={isActive ? 'contained' : 'text'}
                  sx={{
                     marginRight: '4px',
                     padding: '9px 16px',
                     borderRadius: '10px',
                  }}
               >
                  <Typography
                     className={typography.pc.s4}
                     color={colorPalette[isActive ? 'white' : 'dark']}
                  >
                     {t(`pageTitle.${label}`)}
                  </Typography>
               </Button>
            )
         })}
      </Paper>
   )
}

export default HeaderTab
