import { FC, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { CircleLoading, Button, Icon, PopupNoti } from '../../../components'
import Table, {
   TableRowType,
   HeadCell,
   FilterType,
   TableCellDataType,
} from '../../../components/Table'
import {
   PageHeader,
   PageContent,
   PageTitle,
   PageWrapper,
} from '../../../layout'
import { useAppDispatch } from '../../../redux'
import { ReactComponent as PenIcon } from '../../../assets/icons/pen.svg'
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg'
import { ReactComponent as BinIcon } from '../../../assets/icons/bin.svg'
import { colorPalette } from '../../../config'
import { warningMsg } from '../../../schemas'
import { useTranslation } from 'react-i18next'
import { useSetupPosts } from '../../../hooks/use-redux/use-setup-posts'
import { Maybe } from '../../../types'
import { deleteSetupPost } from '../../../redux/cms'

const SetupPostsTable: FC = () => {
   const dispatch = useAppDispatch()
   const { setupPosts, isLoading, mutate } = useSetupPosts()
   const { t } = useTranslation(['common', 'inputs', 'components'])

   const [setupPostToDelete, setSetupPostToDelete] =
      useState<Maybe<string>>(null)
   const [openAlert, setOpenAlert] = useState<boolean>(false)

   const headCells: HeadCell[] = [
      {
         isId: true,
         id: '_id',
         numeric: false,
         disablePadding: false,
         filterType: FilterType.TEXT,
         label: 'ID',
      },
      {
         id: 'thumbnail',
         numeric: false,
         disablePadding: false,
         filterType: FilterType.TEXT,
         label: 'Ảnh',
      },
      {
         id: 'title',
         numeric: false,
         disablePadding: false,
         filterType: FilterType.TEXT,
         label: 'Tiêu đề',
      },
      {
         id: 'created_at',
         numeric: false,
         disablePadding: false,
         filterType: FilterType.DATE,
         label: t('common.createdAt', { ns: 'rows' }),
      },
   ]

   const handleOnDelete = (setupPost_id: string) => {
      setOpenAlert(true)
      setSetupPostToDelete(setupPost_id)
   }

   const handleDelete = async () => {
      if (!setupPostToDelete) {
         return
      }
      await mutate({
         statusCode: 200,
         data: setupPosts?.filter((post) => post._id !== setupPostToDelete),
      })

      dispatch(deleteSetupPost(setupPostToDelete))
      setSetupPostToDelete(null)
   }

   const rows: TableRowType[] = useMemo(
      () =>
         setupPosts
            ? setupPosts.map((setupPost) => {
                 return {
                    key: setupPost._id,
                    cells: [
                       {
                          identifier: '_id',
                          type: TableCellDataType.ID,
                          data: setupPost._id,
                       },
                       {
                          identifier: 'thumbnail',
                          type: TableCellDataType.IMAGE,
                          data: setupPost.thumbnail,
                       },
                       {
                          identifier: 'title',
                          type: TableCellDataType.TEXT,
                          data: setupPost.title,
                       },
                       {
                          identifier: 'created_at',
                          type: TableCellDataType.DATE,
                          data: setupPost.created_at,
                       },
                       {
                          identifier: 'action',
                          type: TableCellDataType.ACTION,
                          data: [
                             {
                                icon: {
                                   src: PenIcon,
                                   color: colorPalette.grey,
                                   tooltip: t('tooltip.common.edit', {
                                      ns: 'components',
                                   }) as string,
                                },
                                link: `/setups/${setupPost._id}`,
                             },
                             {
                                icon: {
                                   src: BinIcon,
                                   color: colorPalette.red.shade_500,
                                   tooltip: 'Xoá',
                                },
                                handleOnClick: () =>
                                   handleOnDelete(setupPost._id),
                             },
                          ],
                       },
                    ],
                 }
              })
            : [],
      [setupPosts]
   )

   return (
      <>
         <PopupNoti
            open={openAlert}
            setOpen={setOpenAlert}
            message={warningMsg}
            action={() => handleDelete()}
         />
         <PageWrapper>
            <PageHeader>
               <PageTitle type='header' title={'Tất cả bài hướng dẫn'} />
               <Button
                  customsize='sm'
                  variant='contained'
                  endIcon={
                     <Icon
                        type='fill'
                        src={PlusIcon}
                        color={colorPalette.white}
                        width={15}
                        height={15}
                     />
                  }
                  style={{ marginLeft: '5px' }}
                  component={Link as any}
                  to='create'
               >
                  Tạo bài hướng dẫn
               </Button>
            </PageHeader>
            <PageContent>
               {isLoading ? (
                  <CircleLoading />
               ) : (
                  <Table
                     initialSort='title'
                     initialSearchTermType='_id'
                     title={'Danh sách  bài hướng dẫn'}
                     headCells={headCells}
                     rows={rows}
                     withAction
                  />
               )}
            </PageContent>
         </PageWrapper>
      </>
   )
}

export default SetupPostsTable
