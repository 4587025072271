/* Libs */
import React, { memo } from 'react'
import { Icon } from '../../components'
import { DrawerHeader } from '../common'
import { MainBox, ContentContainer, ToggleMenuButton } from './styles'
import { ReactComponent as NavLeftIcon } from '../../assets/icons/nav-left.svg'
import { ReactComponent as NavRightIcon } from '../../assets/icons/nav-right.svg'
import { colorPalette } from '../../config'
interface ContentProps {
   open: boolean
   onOpen: () => void
   onClose: () => void
   children: React.ReactNode
}

const Content: React.FC<ContentProps> = ({
   open,
   onOpen,
   onClose,
   children,
}) => {
   return (
      <MainBox component='main' sx={{ flexGrow: 1, p: 0, maxWidth: `calc(100% - ${open ? 257 : 95}px)` }}>
         <DrawerHeader />
         <ContentContainer>
            {children}
            <ToggleMenuButton
               open={open}
               color='inherit'
               aria-label='open drawer'
               onClick={open ? onClose : onOpen}
               edge='start'
            >
               <Icon
                  type='fill'
                  src={open ? NavLeftIcon : NavRightIcon}
                  color={colorPalette.white}
               />
            </ToggleMenuButton>
         </ContentContainer>
      </MainBox>
   )
}

export default memo(Content)
