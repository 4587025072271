import React from 'react'
import { Box, BoxProps } from '@mui/material'

type IconProps = {
   type: 'fill' | 'stroke'
   color: string
   src: React.FunctionComponent<any>
   width?: number
   height?: number
   active?: boolean
   activeColor?: string
}

type ExtraIconProps = IconProps & BoxProps

enum HoverEvent {
   MOUSE_ENTER = 'in',
   MOUSE_LEAVE = 'out',
}

const styledIcon = (Component: typeof Box) => {
   return (props: ExtraIconProps) => {
      const { type, src, color, width, height, active, activeColor, sx, ...restProps } = props
      const SvgIcon = src
      const iconShapes = ['path', 'circle', 'ellipse']

      const iconRef = React.useRef<SVGSVGElement>()

      const [isHovered, setIsHovered] = React.useState<boolean>(false)

      const handleOnHover = (event: HoverEvent) => {
         setIsHovered(!!(event === HoverEvent.MOUSE_ENTER))
      }

      React.useEffect(() => {
         iconShapes.forEach((shape) => {
            const elements = iconRef.current?.querySelectorAll(shape)
            elements?.forEach((element) => {
               element?.setAttribute(type, color)
            })
            if ((active || isHovered) && activeColor) {
               elements?.forEach((element) => {
                  element?.setAttribute(type, activeColor)
               })
            }
         })
      }, [type, color, src, active, activeColor, isHovered])

      React.useEffect(() => {
         const element = iconRef.current?.querySelector('svg')
         if (!!width && !!height) {
            element?.setAttribute('width', `${width}`)
            element?.setAttribute('height', `${height}`)
         }
      }, [width, height])

      return (
         <Component
            sx={{
               width,
               height,
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               ...sx,
            }}
            ref={iconRef}
            onMouseEnter={() => handleOnHover(HoverEvent.MOUSE_ENTER)}
            onMouseLeave={() => handleOnHover(HoverEvent.MOUSE_LEAVE)}
            {...restProps}
         >
            <SvgIcon />
         </Component>
      )
   }
}

const Icon = styledIcon(Box)

export default Icon
