/* Libs */
import React, { useState, useCallback, useEffect } from 'react'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'
import { Header, Sidebar, Content } from '../../layout'
/* Redux */
import { selectAuth, selectFetch, setImages, useAppDispatch, useAppSelector } from '../../redux'
import { LinearLoading, Message } from '../../components'
import useFilteredQuery from '../../hooks/use-swr/use-query-with-filter'

const Portal: React.FC = () => {
   const { isAuthenticated } = useAppSelector(selectAuth)
   const { uploading } = useAppSelector(selectFetch)
   const dispatch = useAppDispatch()
   const navigate = useNavigate()
   const { pathname } = useLocation()

   const result = useFilteredQuery<ImageData[]>({ url: isAuthenticated ? 'images' : null, options: {
      onSuccess: ({ data }: { data: ImageData[] }) => {
         dispatch(setImages(data))
      },
      refreshInterval: 1200000
   } })

   const [isOpen, setIsOpen] = useState(true)

   const handleDrawerOpen = useCallback(() => {
      setIsOpen(true)
   }, [])
   const handleDrawerClose = useCallback(() => {
      setIsOpen(false)
   }, [])

   // useEffect(() => {
   //    const currRoute = localStorage.getItem('currentRoute')
   //    navigate(currRoute ? currRoute : '/dashboard')
   // }, [])

   // useEffect(() => {
   //    localStorage.setItem('currentRoute', pathname)
   // }, [pathname])

   useEffect(() => { 
      if (pathname && (pathname === '/' || pathname === '/promotion') && isAuthenticated) { 
         navigate(pathname === '/' ? '/dashboard' : '/promotion/programs')
      }
   }, [pathname, isAuthenticated])

   useEffect(() => {
      if (!isAuthenticated) {
         navigate('/login')
      }
   }, [isAuthenticated])

   return (
      <Box sx={{ display: 'flex' }}>
         {!isAuthenticated && <Navigate to='/login' replace={true} />}
         <Header open={isOpen} />
         <Sidebar open={isOpen} />
         <Content
            open={isOpen}
            onOpen={handleDrawerOpen}
            onClose={handleDrawerClose}
         >
            {uploading && <LinearLoading />}
            <Outlet />
            <Message />
         </Content>
      </Box>
   )
}

export default Portal
