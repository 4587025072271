import { FC as ReactFC, ReactNode } from 'react'

export enum Status {
   IDDLE = 'iddle',
   SUCCESS = 'success',
   ERROR = 'error',
   PENDING = 'pending',
}

export enum OrderStatusNumber {
   ORDER_SUCCESS = 0,
   ORDER_ONHOLD = 1,
   ORDER_CANCELLED = 2,
   ORDER_SHIPPED = 3,
}

export enum OrderStatusString {
   ORDER_SUCCESS = 'Success',
   ORDER_ONHOLD = 'On hold',
   ORDER_CANCELLED = 'Cancelled',
   ORDER_SHIPPED = 'Shipped',
}

export enum ActiveStatus {
   ACTIVE = 'Active',
   DEACTIVATED = 'Deactivated',
}

export enum VoucherStatus {
   VALID = 'Valid',
   INVALID = 'Invalid',
}

export type MuiColor =
   | 'default'
   | 'primary'
   | 'secondary'
   | 'error'
   | 'info'
   | 'success'
   | 'warning'
   | undefined

export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = {
   [K in keyof T]: T[K]
}

export type FC = ReactFC & {
   children?: ReactNode
}

export type AttributeQuery = {
   page?: number
   limit?: number
   start_date?: Maybe<number>
   end_date?: Maybe<number>
   search_criteria?: Maybe<SearchCriteria[]>
}

export type SearchCriteria = {
   key: string
   value: string
}
