import { createSlice } from '@reduxjs/toolkit'
import {
   getAllPromotions,
   getAllPromotionPrograms,
   getAllVouchers,
   getOnePromotionProgram,
} from './promotionThunk'
import {
   Maybe,
   PromotionData,
   PromotionProgramData,
   VoucherData,
} from '../../types'

export interface PromotionState {
   promotionLoading: boolean
   promotions: PromotionData[]
   promotionPrograms: PromotionProgramData[]
   vouchers: VoucherData[]
   programDetail: Maybe<PromotionProgramData>
}

const initialState: PromotionState = {
   promotionLoading: false,
   promotions: [],
   promotionPrograms: [],
   vouchers: [],
   programDetail: null,
}

export const promotionSlice = createSlice({
   name: 'promotionState',
   initialState,
   reducers: {
      resetPromotions: (state) => {
         state.promotions = []
      },
      resetPromotionPrograms: (state) => {
         state.promotionPrograms = []
      },
      resetVouchers: (state) => {
         state.vouchers = []
      },
      resetProgramDetail: (state) => {
         state.programDetail = null
      },
   },
   extraReducers: (builder) => {
      builder
         .addCase(getAllPromotions.pending, (state) => {
            state.promotionLoading = true
         })
         .addCase(getAllPromotions.fulfilled, (state, action: any) => {
            state.promotionLoading = false
            state.promotions = action.payload.promotions
         })
         .addCase(getAllPromotions.rejected, (state, action: any) => {
            state.promotionLoading = false
         })
         .addCase(getAllPromotionPrograms.pending, (state) => {
            state.promotionLoading = true
         })
         .addCase(getAllPromotionPrograms.fulfilled, (state, action: any) => {
            state.promotionLoading = false
            state.promotionPrograms = action.payload.promotionPrograms
         })
         .addCase(getAllPromotionPrograms.rejected, (state, action: any) => {
            state.promotionLoading = false
         })
         .addCase(getAllVouchers.pending, (state) => {
            state.promotionLoading = true
         })
         .addCase(getAllVouchers.fulfilled, (state, action: any) => {
            state.promotionLoading = false
            state.vouchers = action.payload.vouchers
         })
         .addCase(getAllVouchers.rejected, (state, action: any) => {
            state.promotionLoading = false
         })
         .addCase(getOnePromotionProgram.pending, (state, action: any) => {
            state.promotionLoading = true
         })
         .addCase(getOnePromotionProgram.fulfilled, (state, action: any) => {
            state.promotionLoading = false
            state.programDetail = action.payload.programDetail
         })
         .addCase(getOnePromotionProgram.rejected, (state, action: any) => {
            state.promotionLoading = false
            state.programDetail = null
         })
   },
})

export const {
   resetPromotionPrograms,
   resetPromotions,
   resetVouchers,
   resetProgramDetail,
} = promotionSlice.actions

export default promotionSlice.reducer
