import { Grid, Stack, Typography } from '@mui/material'
import { ChangeEvent, FC } from 'react'
import {
   Controller,
   ControllerRenderProps,
   FieldValues,
   useFieldArray,
} from 'react-hook-form'
import Input from '../Input'
import { colorPalette, typography } from '../../config'
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg'
import { RemoveMetaDataBtn } from '../../layout'
import Icon from '../Icon'
import Button from '../Button'

type ProductMetaDataFieldValueProps = {
   nestIndex: number
   control: any
   watch: any
   errors: any
}

const ProductMetadataFieldValue: FC<ProductMetaDataFieldValueProps> = ({
   nestIndex,
   control,
   watch,
   errors,
}) => {
   const { fields, remove, append } = useFieldArray({
      control,
      name: `product_metadata.${nestIndex}.values`,
   })

   const handleAddOption = () => {
      append({
         slug: '',
         name: '',
      })
   }

   const handleOnFieldChange = (
      e: ChangeEvent<HTMLInputElement>,
      field: ControllerRenderProps<
         FieldValues,
         `product_metadata.${number}.values.${number}.name`
      >
   ) => {
      field.onChange(e)
   }

   const handleOnRemove = (idx: number) => {
      remove(idx)
   }

   return (
      <Stack direction='column' sx={{ width: '100%' }}>
         <Typography className={typography.pc.s3} color={colorPalette.dark}>
            Bộ giá trị
         </Typography>
         {fields.map((option, idx) => (
            <Grid
               key={option.id}
               container
               spacing={2}
               sx={{ marginTop: '8px' }}
            >
               <Grid item xs={8} sx={{ position: 'relative ' }}>
                  <Controller
                     key={option.id}
                     name={`product_metadata.${nestIndex}.values.${idx}.name`}
                     control={control}
                     rules={{ required: true }}
                     render={({ field }) => (
                        <Input
                           label='Giá trị'
                           value={field.value}
                           onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              handleOnFieldChange(e, field)
                           }
                           fullWidth
                           placeholder='Nhập giá trị'
                           type='text'
                           error={
                              errors.product_attributes &&
                              !!errors?.product_attributes[nestIndex].values[
                                 idx
                              ]?.name
                           }
                           helperText={
                              errors.product_attributes &&
                              errors?.product_attributes[nestIndex].values[idx]
                                 ?.name?.message
                           }
                        />
                     )}
                  />
                  {fields.length > 1 && (
                     <RemoveMetaDataBtn
                        variant='contained'
                        color='error'
                        onClick={() => handleOnRemove(idx)}
                        sx={{ top: 0 }}
                     >
                        <Icon
                           type='fill'
                           color={colorPalette.white}
                           src={CloseIcon}
                           width={12}
                           height={12}
                        />
                     </RemoveMetaDataBtn>
                  )}
               </Grid>
               <Grid item xs={4}>
                  <Button
                     customsize='sm'
                     variant='contained'
                     fullWidth
                     onClick={handleAddOption}
                     sx={{ height: '100%' }}
                  >
                     Thêm
                  </Button>
               </Grid>
            </Grid>
         ))}
      </Stack>
   )
}

export default ProductMetadataFieldValue
