import { createAsyncThunk } from '@reduxjs/toolkit'
import { SyntheticEvent } from 'react'
import { useFetchingHandler } from '../../hooks/use-fetch-handlers'
import { BannerData, Status } from '../../types'
import { setLoading, setResponse, setUploading } from '../fetch'

export type CreateBannerData = {
   button_text?: string
   button_link?: string
   image_id: string
   product?: string
   large_text_1?: string
   small_text_1?: string
}

export type UpdateBannerData<T> = {
   bannerId: string
   payload: T
}

export const getAllBanners = createAsyncThunk(
   'bannerState/getAllBanners',
   async (_: SyntheticEvent | void, { dispatch }) => {
      const { getHandler } = useFetchingHandler({ dispatch })
      try {
         dispatch(setLoading(true))
         dispatch(
            setResponse({
               status: Status.PENDING,
               message: 'Processing...',
            })
         )

         const res = await getHandler<BannerData[]>({
            url: 'banner',
            secured: true,
         })

         dispatch(setLoading(false))
         dispatch(
            setResponse({
               status: Status.SUCCESS,
               message: res?.message,
            })
         )

         return {
            banners: res?.data,
         }
      } catch (err: any) {
         dispatch(setLoading(false))
         dispatch(
            setResponse({
               status: Status.ERROR,
               message: err?.message,
            })
         )
         throw err
      }
   }
)

export const getBannerDetail = createAsyncThunk(
   'bannerState/getBannerDetail',
   async (id: string, { dispatch }) => {
      const { getHandler } = useFetchingHandler({ dispatch })
      try {
         // dispatch(setLoading(true))
         // dispatch(
         //    setResponse({
         //       status: Status.PENDING,
         //       message: 'Processing...',
         //    })
         // )

         const res = await getHandler<BannerData>({
            url: `banner/${id}`,
            secured: true,
         })

         // dispatch(setLoading(false))
         // dispatch(
         //    setResponse({
         //       status: Status.SUCCESS,
         //       message: res?.message,
         //    })
         // )
         return {
            bannerDetail: res?.data,
         }
      } catch (err: any) {
         // dispatch(setLoading(false))
         // dispatch(
         //    setResponse({
         //       status: Status.ERROR,
         //       message: err?.message,
         //    })
         // )
         throw err
      }
   }
)

export const createBanner = createAsyncThunk(
   'bannerState/createBanner',
   async (values: CreateBannerData, { dispatch }) => {
      const { postHandler } = useFetchingHandler({ dispatch })
      try {
         dispatch(setUploading(true))
         dispatch(
            setResponse({
               status: Status.PENDING,
               message: 'Processing ...',
            })
         )
         const res = await postHandler<CreateBannerData, any>({
            url: `banner`,
            secured: true,
            body: values,
         })

         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: res ? Status.SUCCESS : Status.ERROR,
               message: res?.data.message || 'Tạo thành công',
            })
         )
      } catch (err: any) {
         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.ERROR,
               message: err?.response?.data?.message,
            })
         )
         throw err
      }
   }
)

export const deleteBanner = createAsyncThunk(
   'bannerState/deleteBanner',
   async (bannerId: string, { dispatch }) => {
      const { deleteHandler } = useFetchingHandler({ dispatch })
      try {
         dispatch(setUploading(true))
         dispatch(
            setResponse({
               status: Status.PENDING,
               message: 'Processing ...',
            })
         )

         const res = await deleteHandler<any>({
            url: `banner/${bannerId}`,
            secured: true,
         })

         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.SUCCESS,
               message: res.message,
            })
         )
      } catch (err: any) {
         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.ERROR,
               message: err?.response?.message,
            })
         )

         throw err
      }
   }
)

export const updateBanner = createAsyncThunk(
   'bannerState/updateBanner',
   async (
      { bannerId, payload }: UpdateBannerData<CreateBannerData>,
      { dispatch }
   ) => {
      const { putHandler } = useFetchingHandler({ dispatch })
      try {
         dispatch(setUploading(true))
         dispatch(
            setResponse({
               status: Status.PENDING,
               message: 'Processing ...',
            })
         )

         const res = await putHandler<CreateBannerData, any>({
            url: `banner/${bannerId}`,
            secured: true,
            body: payload,
         })

         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.SUCCESS,
               message: res.message,
            })
         )
      } catch (err: any) {
         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.ERROR,
               message: err?.response?.message,
            })
         )
         throw new Error(err)
      }
   }
)
