import { createAsyncThunk } from '@reduxjs/toolkit'
import { useFetchingHandler } from '../../hooks/use-fetch-handlers'
import { BillData, RevenueData, SoldProductData, Status } from '../../types'
import { setLoading, setResponse } from '../fetch'

type StatisticDate = {
   start_at: number
   end_at: number
}

type GetData = SoldProductData[] | RevenueData | BillData

type GetStatisticData = {
   index: StatisticIndex
   date: StatisticDate
}

export enum StatisticIndex {
   SOLD_PRODUCT = 'SOLD_PRODUCT',
   REVENUE = 'REVENUE',
   BILL = 'BILL',
}

export const statisticMapping = {
   [StatisticIndex.SOLD_PRODUCT]: {
      url: 'number_of_sold_product_each_product',
      state: 'soldProducts'
   },
   [StatisticIndex.REVENUE]: {
      url: 'revenue',
      state: 'revenue'
   },
   [StatisticIndex.BILL]: {
      url: 'number_of_bill',
      state: 'bill'
   }
}

export const getStatistic = createAsyncThunk(
   'promotionState/getStatistic',
   async ({ index, date: { start_at, end_at }}: GetStatisticData, { dispatch }) => {
      const { getHandler } = useFetchingHandler({dispatch})
      try {
         dispatch(setLoading(true))

         const res = await getHandler<GetData>({
            url: `statistic/?index=${statisticMapping[index].url}&start_at=${start_at}&end_at=${end_at}`,
            secured: true,
         })

         dispatch(setLoading(false))

         return {
            data: res?.data,
            index
         }
      } catch (err: any) {
         dispatch(setLoading(false))
         dispatch(
            setResponse({
               status: Status.ERROR,
               message: err?.message,
            })
         )
         throw err
      }
   }
)


