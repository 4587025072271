import * as yup from 'yup'
import { requiredMsg } from './common'

export const flashSaleProductList = yup.object().shape({
   price_sale: yup.number().required(requiredMsg),
   percentage: yup.number().required(requiredMsg),
})

export const promotionProgramCreateSchema = yup
   .object({
      flash_sale_name: yup.string().required(requiredMsg),
      start_at: yup
         .number()
         .typeError('Phải điền đúng định dạng. VD: 26/02/2024 - 00:00:00')
         .required(requiredMsg),
      end_at: yup
         .number()
         .typeError('Phải điền đúng định dạng. VD: 26/02/2024 - 00:00:00')
         .required(requiredMsg),
      list_products: yup.array().of(flashSaleProductList),
   })
   .required()

export const voucherCreateSchema = yup
   .object({
      value: yup
         .number()
         .typeError('Phải là dạng số')
         .min(1000, 'Tối thiểu 1000 VND')
         .required(requiredMsg),
      quantity: yup
         .number()
         .min(1, 'Tối thiểu 1 voucher')
         .max(10, 'Tối đa 10 voucher')
         .required(requiredMsg),
      expired_at: yup
         .number()
         .typeError('Phải điền đúng định dạng. VD: 26/02/2024 - 00:00:00')
         .required(requiredMsg),
   })
   .required()

export const addToProgramSchema = yup
   .object({
      product_id: yup.string().required(requiredMsg),
      program_id: yup.string().required(requiredMsg),
   })
   .required()
