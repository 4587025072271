import { FC, useEffect, useMemo } from 'react'
import { CircleLoading } from '../Loading'
import Table, {
   TableRowType,
   HeadCell,
   FilterType,
   TableCellDataType,
   ActionData,
} from '../Table'
import { PageContent } from '../../layout'
import {
   getAllPromotionPrograms,
   selectFetch,
   selectPromotion,
   useAppDispatch,
   useAppSelector,
} from '../../redux'
import { useTranslation } from 'react-i18next'
import { VoucherStatus } from '../../types'

interface PromotionProgramsTableProps {
   showActions?: boolean
   disableMultipleSelect?: boolean
   withToolbar?: boolean
   actions?: (program_id: string) => ActionData[]
   onSelected?: (rows: TableRowType[], selectedRows: string[]) => void
}

const PromotionProgramsTable: FC<PromotionProgramsTableProps> = ({
   showActions = false,
   disableMultipleSelect = false,
   withToolbar = true,
   actions,
   onSelected,
}) => {
   const dispatch = useAppDispatch()
   const { promotionPrograms, promotionLoading } =
      useAppSelector(selectPromotion)
   const { uploading } = useAppSelector(selectFetch)
   const { t } = useTranslation(['common', 'rows', 'components'])

   const headCells: HeadCell[] = [
      {
         isId: true,
         id: '_id',
         numeric: false,
         disablePadding: false,
         filterType: FilterType.TEXT,
         label: 'ID',
      },
      {
         id: 'flash_sale_name',
         numeric: false,
         disablePadding: false,
         filterType: FilterType.TEXT,
         label: t('promotion.programName', { ns: 'rows' }),
      },
      {
         id: 'start_at',
         numeric: false,
         disablePadding: false,
         filterType: FilterType.DATE,
         label: t('common.startAt', { ns: 'rows' }),
      },
      {
         id: 'end_at',
         numeric: false,
         disablePadding: false,
         filterType: FilterType.DATE,
         label: t('common.expiredAt', { ns: 'rows' }),
      },
      {
         id: 'status',
         numeric: false,
         disablePadding: false,
         filterType: FilterType.OPTION,
         label: 'Trạng thái',
      },
      {
         id: 'create_at',
         numeric: false,
         disablePadding: false,
         filterType: FilterType.DATE,
         label: t('common.createdAt', { ns: 'rows' }),
      },
   ]

   const rows: TableRowType[] = useMemo(
      () =>
         promotionPrograms.map((program) => ({
            key: program._id,
            cells: [
               {
                  identifier: '_id',
                  type: TableCellDataType.ID,
                  data: program._id,
               },
               {
                  identifier: 'program_name',
                  type: TableCellDataType.TEXT,
                  data: program.flash_sale_name,
               },
               {
                  identifier: 'start_at',
                  type: TableCellDataType.DATE,
                  data: program.start_at,
               },
               {
                  identifier: 'end_at',
                  type: TableCellDataType.DATE,
                  data: program.end_at,
               },
               {
                  identifier: 'status',
                  type: TableCellDataType.TAG,
                  data: t(
                     `status.${
                        VoucherStatus[
                           program.end_at - program.start_at > 0 &&
                           program.end_at >= new Date().valueOf()
                              ? 'VALID'
                              : 'INVALID'
                        ]
                     }`
                  ) as string,
                  tagColorMap: [
                     {
                        value: t(`status.${VoucherStatus.VALID}`),
                        color: 'success',
                     },
                     {
                        value: t(`status.${VoucherStatus.INVALID}`),
                        color: 'error',
                     },
                  ],
               },
               {
                  identifier: 'created_at',
                  type: TableCellDataType.DATE,
                  data: program.created_at,
               },
               ...(showActions && actions
                  ? [
                       {
                          identifier: 'action',
                          type: TableCellDataType.ACTION,
                          data: actions(program._id),
                       },
                    ]
                  : []),
            ],
         })),
      [actions, promotionPrograms, showActions]
   )

   useEffect(() => {
      if (!uploading) {
         dispatch(getAllPromotionPrograms())
      }
   }, [uploading])

   return (
      <PageContent>
         {promotionLoading ? (
            <CircleLoading />
         ) : (
            <Table
               initialSort='created_at'
               initialSearchTermType='created_at'
               title={t('tableTitle.program')}
               headCells={headCells}
               rows={rows}
               onSelected={onSelected}
               withAction={showActions}
               withToolbar={withToolbar}
               disableMultiSelected={disableMultipleSelect}
            />
         )}
      </PageContent>
   )
}

export default PromotionProgramsTable
