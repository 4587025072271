import { StatusSaleAgent } from "../types"

export const getLabel = (label: string) => {
   return label
      .trim()
      .split('_')
      .map((x) => (x === 'id' ? 'ID' : x[0].toUpperCase() + x.substring(1)))
      .join(' ')
}

export const getAgentStatus = (status: StatusSaleAgent): string => {
   switch (status) {
      case StatusSaleAgent.APPROVED:
         return 'Đã duyệt'
      case StatusSaleAgent.PENDING:
         return 'Đang xử lý'
      case StatusSaleAgent.REJECT:
         return 'Không duyệt'
      default:
         return 'Trạng thái không hợp lệ'
   }
}