import { ChangeEvent, FC, useEffect } from 'react'
import {
   Box,
   Checkbox,
   FormControlLabel,
   Grid,
   Stack,
   Typography,
} from '@mui/material'
import { VariantData } from '../../types'
import { MetaDataBox } from '../../layout'
import Input from '../Input'
import ImageEditor from '../ImageEditor'
import Tag from '../Tag'
import { Controller, useForm } from 'react-hook-form'
import { createVariantSchema } from '../../schemas'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDebounce } from '../../hooks/use-debounce'
import {
   transformNumberInput,
   transformNumberOutput,
} from '../../utils/validation'
import { colorPalette, typography } from '../../config'

export type VariantController = {
   setVariantPrice: (variant_identifier: string, price: number) => void
   setVariantImages: (variant_identifier: string, images: string[]) => void
   setVariantName: (variant_identifier: string, name: string) => void
   toggleStatus: (
      variant_identifier: string,
      field: 'stock_status' | 'not_sellable',
      status: boolean
   ) => void
}

type VariantOptionProps = {
   variantIdentifier: string
   variant: VariantData
   variantController: VariantController
}

type VariantFormValues = {
   product_name: string
   stock_status: boolean
   not_sellable: boolean
   price: string
   image: string[]
}

const VariantOption: FC<VariantOptionProps> = ({
   variantIdentifier,
   variant,
   variantController: {
      setVariantImages,
      setVariantPrice,
      setVariantName,
      toggleStatus,
   },
}) => {
   const {
      control,
      setValue,
      watch,
      formState: { errors },
   } = useForm<VariantFormValues>({
      defaultValues: {
         product_name: variant.product_name ?? '',
         price: variant.price.toString() ?? '',
         image: variant.image ?? '',
         stock_status: Boolean(variant.stock_status),
         not_sellable: Boolean(variant.not_sellable),
      },
      resolver: yupResolver(createVariantSchema),
   })

   const [product_name, price, image, stockStatus, notSellable] = watch([
      'product_name',
      'price',
      'image',
      'stock_status',
      'not_sellable',
   ])

   const debouncedProductName = useDebounce(product_name, 500)
   const debouncedPrice = useDebounce(price, 500)
   const debouncedImage = useDebounce(image, 500)

   const handleChangeVariantImage = (imagesToUpload: string[]) => {
      setValue('image', imagesToUpload)
   }

   useEffect(() => {
      setVariantName(variantIdentifier, debouncedProductName)
      if (debouncedPrice) {
         setVariantPrice(
            variantIdentifier,
            Number(debouncedPrice.replaceAll(',', ''))
         )
      }
      setVariantImages(variantIdentifier, debouncedImage)
      toggleStatus(variantIdentifier, 'stock_status', stockStatus)
      toggleStatus(variantIdentifier, 'not_sellable', notSellable)
   }, [
      variantIdentifier,
      debouncedImage,
      debouncedPrice,
      debouncedProductName,
      stockStatus,
      notSellable,
   ])

   return (
      <MetaDataBox sx={{ marginBottom: '8px' }}>
         <Grid container spacing={2}>
            <Grid item xs={12}>
               <Stack direction='row' flexWrap='wrap' alignItems='center'>
                  {variant.product_metadata?.map(({ title, value, slug }) => (
                     <Tag
                        key={slug}
                        label={`${title}: ${value.name}`}
                        sx={{ marginRight: '8px', marginBottom: '8px' }}
                     />
                  ))}
               </Stack>
            </Grid>
            <Grid item xs={12}>
               <Controller
                  name='product_name'
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                     <Input
                        label='Tên mẫu'
                        fullWidth
                        placeholder='Nhập tên mẫu'
                        type='text'
                        error={!!errors.product_name}
                        helperText={
                           errors.product_name
                              ? errors.product_name.message
                              : ''
                        }
                        {...field}
                     />
                  )}
               />
            </Grid>
            <Grid item xs={12} md={12}>
               <Controller
                  name='price'
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                     <Input
                        value={
                           field.value
                              ? transformNumberInput(field.value.toString())
                              : ''
                        }
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                           field.onChange(transformNumberOutput(e))
                        }
                        endAdornment={
                           <Typography
                              className={typography.pc.descSemi}
                              color={colorPalette.dark}
                              component='span'
                           >
                              đ
                           </Typography>
                        }
                        label='Giá'
                        fullWidth
                        placeholder='Nhập giá'
                        type='text'
                        error={!!errors.price}
                        helperText={errors.price ? errors.price.message : ''}
                     />
                  )}
               />
            </Grid>
            <Grid item xs={12} md={6}>
               <Controller
                  name='stock_status'
                  control={control}
                  render={({ field: { value, ...restField } }) => (
                     <FormControlLabel
                        control={<Checkbox checked={value} {...restField} />}
                        label={'Còn hàng'}
                     />
                  )}
               />
            </Grid>
            <Grid item xs={12} md={6}>
               <Controller
                  name='not_sellable'
                  control={control}
                  render={({ field: { value, ...restField } }) => (
                     <FormControlLabel
                        control={<Checkbox checked={value} {...restField} />}
                        label={'Ngừng kinh doanh'}
                     />
                  )}
               />
            </Grid>
            <Grid item xs={12}>
               <Box sx={{ width: 'calc(100% + 32px)', margin: '-16px' }}>
                  {errors?.image ? (
                     <Typography
                        className={typography.pc.helpReg}
                        color={colorPalette.red.shade_500}
                     >
                        Chưa chọn ảnh
                     </Typography>
                  ) : null}
                  <ImageEditor
                     productId={variant?._id}
                     initialSelectedImgs={variant?.image}
                     variantIdentifier={variantIdentifier}
                     onSelectedImgsChanges={handleChangeVariantImage}
                     cols={3}
                  />
               </Box>
            </Grid>
         </Grid>
      </MetaDataBox>
   )
}

export default VariantOption
