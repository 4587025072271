import { createAsyncThunk } from '@reduxjs/toolkit'
import { ContentType, useFetchingHandler } from '../../hooks/use-fetch-handlers'
import { setResponse, setUploading } from '../fetch'
import { ImageData, Status } from '../../types'

export const getAllImages = createAsyncThunk(
   'mediaState/getAllImages',
   async (_, { dispatch }) => {
      const { getHandler } = useFetchingHandler({ dispatch })
      try {
         const res = await getHandler<ImageData[]>({
            url: 'images',
            secured: true,
         })

         return {
            images: res?.data,
         }
      } catch (err: any) {
         throw err
      }
   }
)

export const uploadImage = createAsyncThunk(
   'mediaState/uploadImage',
   async (file: File, { dispatch }) => {
      const { postHandler } = useFetchingHandler({ dispatch })
      try {
         // dispatch(setUploading(true))
         dispatch(
            setResponse({
               status: Status.PENDING,
               message: 'Processing',
            })
         )

         const formData = new FormData()
         formData.append('file', file, file.name)

         const res = await postHandler<FormData, any>({
            url: 'images',
            secured: true,
            contentType: ContentType.FILE,
            body: formData,
         })

         // dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.SUCCESS,
               message: res?.message,
            })
         )
      } catch (err: any) {
         // dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.ERROR,
               message: err?.message,
            })
         )
         throw err
      }
   }
)

export const deleteImage = createAsyncThunk(
   'mediaState/deleteImage',
   async (imageId: string, { dispatch }) => {
      const { deleteHandler } = useFetchingHandler({ dispatch })
      try {
         dispatch(setUploading(true))
         dispatch(
            setResponse({
               status: Status.PENDING,
               message: 'Processing',
            })
         )
         const res = await deleteHandler<ImageData[]>({
            url: `images/${imageId}`,
            secured: true,
         })

         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.SUCCESS,
               message: res?.message,
            })
         )
      } catch (err: any) {
         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.ERROR,
               message: err?.message,
            })
         )
         throw err
      }
   }
)
