import { FC, useEffect, useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Grid, Box } from '@mui/material'
import {
   Button,
   NavIconButton,
   CircleLoading,
   CommonInfo,
   PopupForm,
   InputType,
} from '../../../components'
import {
   PageHeader,
   PageContent,
   PageTitle,
   PageWrapper,
} from '../../../layout'
import {
   useAppDispatch,
   useAppSelector,
   selectUser,
   getOneUser,
   resetUserDetail,
   selectFetch,
   updateUserPassword,
   updateUserInfo,
} from '../../../redux'
import { ReactComponent as PasswordIcon } from '../../../assets/icons/fingerprint.svg'
import { ReactComponent as EmailIcon } from '../../../assets/icons/email.svg'
import {
   userUpdateInfoSchema,
   userUpdatePasswordSchema,
} from '../../../schemas'
import { useTranslation } from 'react-i18next'

enum FormName {
   EMAIL = 'email',
   PASSWORD = 'password',
   DEFAULT = 'email',
}

type EmailForm = {
   new_email: string
}

type PasswordForm = {
   new_password: string
   new_password_repeat: string
}

const UserDetail: FC = () => {
   const { userId } = useParams()
   const dispatch = useAppDispatch()
   const userState = useAppSelector(selectUser)
   const fetchState = useAppSelector(selectFetch)
   const { t } = useTranslation(['common', 'components', 'inputs'])

   const [formName, setFormName] = useState<FormName>(FormName.DEFAULT)
   const [openForm, setOpenForm] = useState<boolean>(false)

   const forms = useMemo(
      () => ({
         [FormName.EMAIL]: {
            title: t('formTitle.updateEmail'),
            schema: userUpdateInfoSchema,
            defaultValue: {
               new_email: '',
            },
            fields: [
               {
                  name: 'new_email',
                  label: t('common.email.label', { ns: 'inputs' }),
                  placeholder: t('common.email.placeholder', { ns: 'inputs' }),
                  type: 'email',
                  required: true,
                  inputType: InputType.INPUT,
                  cols: 12,
               },
            ],
            handleValidate: (data: EmailForm) => {
               return false
            },
            handleSubmit: (data: EmailForm) => {
               dispatch(
                  updateUserInfo({
                     userId: userId as string,
                     payload: { email: data.new_email },
                  })
               )
            },
         },
         [FormName.PASSWORD]: {
            title: t('formTitle.updatePassword'),
            schema: userUpdatePasswordSchema,
            defaultValue: {
               new_password: '',
               new_password_repate: '',
            },
            fields: [
               {
                  name: 'new_password',
                  label: t('common.newPass.label', { ns: 'inputs' }),
                  placeholder: t('common.newPass.placeholder', {
                     ns: 'inputs',
                  }),
                  type: 'password',
                  required: true,
                  inputType: InputType.INPUT,
                  cols: 12,
               },
               {
                  name: 'new_password_repeat',
                  label: t('common.repeatPass.label', { ns: 'inputs' }),
                  type: 'password',
                  placeholder: t('common.repeatPass.placeholder', {
                     ns: 'inputs',
                  }),
                  required: true,
                  inputType: InputType.INPUT,
                  cols: 12,
               },
            ],
            handleValidate: (data: PasswordForm) => {
               let error: any = {}
               if (data.new_password !== data.new_password_repeat) {
                  error.new_password = t('errors.passNotMatch', {
                     ns: 'inputs',
                  })
                  error.new_password_repeat = t('errors.passNotMatch', {
                     ns: 'inputs',
                  })
               } else {
                  error = false
               }

               return error
            },
            handleSubmit: (data: PasswordForm) => {
               dispatch(
                  updateUserPassword({
                     userId: userId as string,
                     payload: { password: data.new_password },
                  })
               )
            },
         },
      }),
      [userState?.userDetail, userId]
   )

   const handleOnClick = (name: FormName) => {
      setFormName(name)
      setOpenForm(true)
   }

   useEffect(() => {
      if (!fetchState.uploading) {
         dispatch(getOneUser(userId as string))
      }

      return () => {
         dispatch(resetUserDetail())
      }
   }, [userId, fetchState.uploading])

   const renderInfo = useMemo(
      () =>
         userState.userDetail ? (
            <Box sx={{ width: '100%', padding: '8px 0' }}>
               <Grid container spacing={3}>
                  <Grid
                     item
                     xs={12}
                     sm={12}
                     sx={{ overflow: 'hidden' }}
                     display='flex'
                     direction='row'
                  >
                     <CommonInfo
                        icon={EmailIcon}
                        label={t('common.email.label', { ns: 'inputs' })}
                        value={userState.userDetail?.email as string}
                     />
                     <Button
                        customsize='sm'
                        variant='contained'
                        color='primary'
                        onClick={() => handleOnClick(FormName.EMAIL)}
                        sx={{ marginLeft: '8px' }}
                     >
                        {t('buttons.common.edit', { ns: 'components' })}
                     </Button>
                  </Grid>
                  <Grid
                     item
                     xs={12}
                     sm={12}
                     sx={{ overflow: 'hidden' }}
                     display='flex'
                     direction='row'
                  >
                     <CommonInfo
                        icon={PasswordIcon}
                        label={t('common.password.label', { ns: 'inputs' })}
                        value={userState.userDetail?.password as string}
                        hiddenValue
                     />
                     <Button
                        customsize='sm'
                        variant='contained'
                        color='primary'
                        onClick={() => handleOnClick(FormName.PASSWORD)}
                        sx={{ marginLeft: '8px' }}
                     >
                        {t('buttons.common.edit', { ns: 'components' })}
                     </Button>
                  </Grid>
               </Grid>
            </Box>
         ) : null,
      [userState.userDetail, userState.loading, t]
   )

   return (
      <PageWrapper>
         {userState.userDetail ? (
            <PopupForm
               open={openForm}
               setOpen={setOpenForm}
               title={forms[formName].title}
               defaultValues={forms[formName].defaultValue}
               onSubmit={forms[formName].handleSubmit}
               onValidate={forms[formName].handleValidate}
               schema={forms[formName].schema}
               formFields={forms[formName].fields}
            />
         ) : null}
         <PageHeader>
            <Box sx={{ display: 'flex' }}>
               <NavIconButton
                  dir='left'
                  variant='text'
                  size='sm'
                  component={Link as any}
                  to='/users'
                  style={{ marginRight: '16px' }}
               />
               <PageTitle type='header' title={`${t('pageTitle.user.detail')} ${userId}`} />
            </Box>
         </PageHeader>
         {userState.loading ? (
            <CircleLoading />
         ) : (
            userState.userDetail && (
               <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                     <PageContent>
                        <PageTitle type='content' title={t('blockTitle.general')} />
                        {renderInfo}
                     </PageContent>
                  </Grid>
               </Grid>
            )
         )}
      </PageWrapper>
   )
}

export default UserDetail
