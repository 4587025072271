import { createAsyncThunk } from '@reduxjs/toolkit'
import { useFetchingHandler } from '../../hooks/use-fetch-handlers'
import { Status } from '../../types'
import { resetError, setError, setResponse, setUploading } from '../fetch'
import { States } from '../store'

export interface LoginPayload {
   email: string
   password: string
}

export interface LoginAPIData {
   refreshToken: string
   accessToken: string
   permission: string
}

export interface LogoutPayload {
   refreshToken: string
}

export const login = createAsyncThunk(
   'authState/login',
   async (formValues: LoginPayload, { dispatch }) => {
      const { postHandler } = useFetchingHandler({ dispatch })
      try {
         dispatch(setUploading(true))
         dispatch(
            setResponse({
               status: Status.PENDING,
               message: 'Processing ...',
            })
         )

         const res = await postHandler<LoginPayload, LoginAPIData>({
            url: 'authentication/login',
            body: formValues,
         })
         dispatch(setUploading(false))

         if (res?.data) {
            const userTokens = {
               accessToken: res?.data?.accessToken,
               refreshToken: res?.data?.refreshToken,
            }
            const permission = res?.data?.permission

            localStorage.setItem('userTokens', JSON.stringify(userTokens))
            localStorage.setItem('permission', JSON.stringify(permission))

            dispatch(
               setResponse({
                  status: Status.SUCCESS,
                  message: res?.message,
               })
            )
            dispatch(resetError())

            return {
               userTokens,
               permission,
            }
         }

         dispatch(
            setResponse({
               status: Status.ERROR,
               message:
                  res?.statusCode === 404
                     ? 'Sai tên đăng nhập hoặc mật khẩu'
                     : 'Không tìm thấy tài khoản',
            })
         )
         dispatch(
            setError({
               state: States.AUTH,
               log: res?.message,
            })
         )

         return {
            userTokens: null,
            permission: null,
         }
      } catch (err: any) {
         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.ERROR,
               message: err?.message,
            })
         )
         dispatch(
            setError({
               state: States.AUTH,
               log: err,
            })
         )

         throw err
      }
   }
)

export const logout = createAsyncThunk(
   'authState/logout',
   async (param, { dispatch }) => {
      const { postHandler } = useFetchingHandler({ dispatch })
      try {
         const { refreshToken } = JSON.parse(
            localStorage.getItem('userTokens') || 'null'
         )

         dispatch(setUploading(true))
         dispatch(
            setResponse({
               status: Status.PENDING,
               message: 'Processing ...',
            })
         )

         const res = await postHandler<LogoutPayload, any>({
            url: 'authentication/logout',
            body: { refreshToken },
            secured: true,
         })

         localStorage.removeItem('userTokens')
         localStorage.removeItem('permission')

         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.SUCCESS,
               message: res?.message,
            })
         )
         dispatch(resetError())
      } catch (err: any) {
         dispatch(
            setResponse({
               status: Status.ERROR,
               message: err?.message,
            })
         )
         dispatch(
            setError({
               state: States.AUTH,
               log: err,
            })
         )

         throw err
      }
   }
)
