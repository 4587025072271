import { FC, useState, useEffect, useMemo, memo, useCallback } from 'react'
import { Typography } from '@mui/material'
import Icon from '../Icon'
import { Image, ActionIcon, ImageList, ImageListItem, ZoomIcon } from './styles'
import { ReactComponent as DeleteIcon } from '../../assets/icons/bin.svg'
import { ReactComponent as ZoomInIcon } from '../../assets/icons/zoom-in.svg'
import { colorPalette, typography } from '../../config'
import PopupNoti from '../PopupNoti'
import {
   useAppDispatch,
   deleteImage,
   resetPendingImgs,
   useAppSelector,
   selectMedia,
} from '../../redux'
import { CircleLoading } from '../Loading'
import { baseURL } from '../../hooks/use-fetch-handlers'
import { warningMsg } from '../../schemas'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { ImageData, Maybe } from '../../types'
import ZoomedView from './ZoomedView'
import useFilteredQuery from '../../hooks/use-swr/use-query-with-filter'

interface ImageToShow {
   id: string
   selected: boolean
}

interface ImageGalleryProps {
   selectedCount?: number
   onSelectedCountChange?: (num: number) => void
   variantIdentifier: string
   selector?: boolean
   cols?: number
   gap?: number
   rowHeight?: number
   initialSeletedImages?: string[]
   initialImagesAsStorage?: string[]
   multipleSelect?: boolean
   onSelectedImgsChanges?: (imgs: string[]) => void
   onImgsToShowDeleted?: (imgId: string) => void
}

const updateSelectedStatus = (arrayA: ImageToShow[], arrayB: string[]) => {
   // Convert arrayB to a Set for efficient lookup
   const setB = new Set(arrayB)

   // Iterate over arrayA
   arrayA.forEach((item) => {
      // Check if the id of the item is included in arrayB
      if (setB.has(item.id)) {
         // Update the selected property to true
         item.selected = true
      }
   })

   // Return the updated arrayA
   return arrayA
}

const ImageGallery: FC<ImageGalleryProps> = memo(
   ({
      selector = false,
      variantIdentifier,
      cols,
      gap,
      onSelectedCountChange,
      rowHeight,
      initialImagesAsStorage,
      initialSeletedImages,
      multipleSelect = false,
      onSelectedImgsChanges,
   }) => {
      const dispatch = useAppDispatch()
      // const {
      //    response: { isLoading, data: images, },
      // } = useFilteredQuery<ImageData[]>({ url: 'images' })
      const { images } = useAppSelector(selectMedia)
      // const { uploading } = useAppSelector(selectFetch)
      const { t } = useTranslation('components')
      const [zoomedImg, setZoomedImg] = useState<Maybe<string>>(null)
      const [openZoom, setOpenZoom] = useState<boolean>(false)

      const [selectedImg, setSelectedImg] = useState<string | null>(null)
      const [imagesToShow, setImagesToShow] = useState<ImageToShow[]>([])
      const [openAlert, setOpenAlert] = useState<boolean>(false)

      const handleOpenZoom = (open: boolean) => setOpenZoom(open)

      const handleOnSelected = (imgId: string) => {
         setImagesToShow((prevImgs) => {
            const newImgs = prevImgs.map((img) =>
               img.id === imgId
                  ? { ...img, selected: !img.selected }
                  : {
                       ...img,
                       selected: multipleSelect
                          ? img.selected
                          : img.selected
                          ? !img.selected
                          : img.selected,
                    }
            )

            if (onSelectedImgsChanges) {
               onSelectedImgsChanges(
                  newImgs.filter((img) => img.selected).map((img) => img.id)
               )
            }

            return newImgs
         })
      }

      const handleOnAction = (image: string | null) => {
         setSelectedImg(image)
         setOpenAlert(true)
      }

      const handleDeleteImage = useCallback(() => {
         if (selectedImg) {
            dispatch(deleteImage(selectedImg as string))
         }
      }, [selectedImg])

      useEffect(() => {
         if (onSelectedCountChange) {
            onSelectedCountChange(
               imagesToShow.filter((img) => img.selected).length
            )
         }
      }, [imagesToShow])

      useEffect(() => {
         setImagesToShow((prevImages) => {
            if (images) {
               const imagesAsStorage = (
                  initialImagesAsStorage || images.map((img) => img._id)
               ).map((img) => ({ id: img, selected: false }))
               return updateSelectedStatus(
                  imagesAsStorage,
                  initialSeletedImages || []
               )
            }

            return prevImages
         })
      }, [images, initialImagesAsStorage, initialSeletedImages])

      useEffect(() => {
         return () => {
            dispatch(resetPendingImgs())
            setImagesToShow([])
         }
      }, [])

      const renderItem = useMemo(
         () =>
            imagesToShow && imagesToShow.length > 0 ? (
               <ImageList
                  cols={cols || 2}
                  rowHeight={rowHeight || 164}
                  gap={gap || 8}
               >
                  {imagesToShow.map((image, idx) => (
                     <ImageListItem
                        key={image.id}
                        className={`gallery-img-${image.id}-img-item gallery-img-item`}
                        imgid={image.id}
                        selector={selector}
                        selected={image.selected}
                     >
                        <ZoomIcon
                           onClick={() => {
                              setZoomedImg(image.id)
                              handleOpenZoom(true)
                           }}
                        >
                           <Icon
                              src={ZoomInIcon}
                              type='fill'
                              color={colorPalette.white}
                              width={14}
                              height={14}
                           />
                        </ZoomIcon>
                        {!selector && (
                           <ActionIcon
                              onClick={() => handleOnAction(image.id)}
                              className={`gallery-img-${image.id}-action-icon`}
                           >
                              <Icon
                                 type='fill'
                                 src={DeleteIcon}
                                 color={colorPalette.white}
                                 width={14}
                                 height={14}
                              />
                           </ActionIcon>
                        )}
                        <Image
                           alt={idx.toString()}
                           src={`${baseURL}/images/${image.id}`}
                           onClick={() => handleOnSelected(image.id)}
                        />
                     </ImageListItem>
                  ))}
               </ImageList>
            ) : (
               <Typography
                  className={typography.mb.b2}
                  color={colorPalette.lightGrey}
                  component='p'
                  textAlign='center'
                  sx={{ maxWidth: '80%' }}
               >
                  {t('imageGallery.noImage')}
               </Typography>
            ),
         [cols, gap, imagesToShow, rowHeight, selector]
      )

      return (
         <>
            <PopupNoti
               open={openAlert}
               setOpen={setOpenAlert}
               message={warningMsg}
               action={handleDeleteImage}
            />
            {renderItem}
            <ZoomedView
               open={openZoom}
               setOpen={handleOpenZoom}
               image={zoomedImg}
            />
         </>
      )
   }
)

export default ImageGallery
