import * as yup from 'yup'
import { requiredMsg } from './common'

export const userUpdateInfoSchema = yup
   .object({
      new_email: yup.string().required(requiredMsg),
   })
   .required()

export const userUpdatePasswordSchema = yup
   .object({
      new_password: yup.string().required(requiredMsg),
      new_password_repeat: yup.string().required(requiredMsg),
   })
   .required()
