import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { Grid, Stack, Typography } from '@mui/material'
import { Link, useParams } from 'react-router-dom'
import {
   NavIconButton,
   Button,
   ProductForm,
   PopupModal,
   PromotionProgramsTable,
   TableRowType,
} from '../../../components'
import {
   PageContent,
   PageHeader,
   PageTitle,
   PageWrapper,
} from '../../../layout'
import { ProductData, Status, VariantData } from '../../../types'
import {
   getOneProduct,
   updateProduct,
   getAllProductTypes,
   selectFetch,
   selectProduct,
   useAppDispatch,
   useAppSelector,
   resetProductDetail,
   addToPromotionProgram,
   selectMedia,
} from '../../../redux'
import { useTranslation } from 'react-i18next'
import {
   ProductFormValues,
   ProductsFormHOC,
   defaultProductFormValues,
   useProductsFormContext,
} from '../../../hooks/use-products-form'
import { getProductAttributes } from '../../../utils/generate-variant-from-attributes'
import { colorPalette, typography } from '../../../config'
import VariantOption from '../../../components/VariantOption'

const ProductDetailPage: FC = () => {
   const { productId } = useParams()
   const { response } = useAppSelector(selectFetch)
   const { mediaLoading } = useAppSelector(selectMedia)
   const { productLoading, productDetail } = useAppSelector(selectProduct)
   const { t } = useTranslation(['common', 'comoponents'])
   const dispatch = useAppDispatch()

   const [openForm, setOpenForm] = useState<boolean>(false)
   const [selectedProgramId, setSelectedProgramId] = useState<string>('')
   const [updatedDetail, setUpdatedDetail] = useState<boolean>(false)

   const { formControl, handleOnSubmit, variants, variantController } =
      useProductsFormContext()!

   const handleOnClick = () => {
      dispatch(
         addToPromotionProgram({
            program_id: selectedProgramId,
            payload: {
               product_ids: [productDetail?.product?._id || ''],
            },
         })
      )
   }

   const productAttributes = useMemo(
      () =>
         productDetail && productDetail?.variants
            ? getProductAttributes([
                 productDetail.product,
                 ...productDetail?.variants,
              ])
            : null,
      [productDetail]
   )

   const handleOnSelected = (rows: TableRowType[], selectedRows: string[]) => {
      const selectedRow = rows.find((row) => row.key === selectedRows[0])
      const selectedProgram = selectedRow?.cells.find(
         (cell) => cell.identifier === '_id'
      )

      if (selectedRow) {
         setSelectedProgramId((selectedProgram?.data as string) || '')
      }
   }

   useEffect(() => {
      dispatch(getAllProductTypes())
      if (productId) {
         setUpdatedDetail(false)
         dispatch(getOneProduct(productId))
      }

      return () => {
         dispatch(resetProductDetail())
      }
   }, [productId])

   useEffect(() => {
      if (response?.status === Status.SUCCESS && productId) {
         formControl.reset()
         dispatch(getOneProduct(productId as string))
         setOpenForm(false)
         setUpdatedDetail(false)
      }
   }, [response?.status, productId])

   useEffect(() => {
      if (
         productDetail &&
         !productLoading &&
         productAttributes &&
         !updatedDetail
      ) {
         const [mainImg, ...sharedImgs] = productDetail.product.image
         Object.keys(defaultProductFormValues).forEach((key) => {
            if (key === 'product_metadata') {
               formControl.setValue('product_metadata', productAttributes, { shouldDirty: true })
               return
            } else if (key === 'image') {
               formControl.setValue('image', sharedImgs, { shouldTouch: true, shouldDirty: true })
            } else {
               formControl.setValue(
                  key as keyof ProductFormValues,
                  productDetail.product[key as keyof ProductData] as unknown as
                     | string
                     | number
               )
            }
         })

         variantController.setInitVariants(
            [productDetail.product, ...productDetail?.variants].map(
               (variant) => {
                  const identifier = variant.product_metadata
                     .map(({ slug, value }) => `${slug}-${value.slug}`)
                     .join('_')
                  return {
                     ...variant,
                     identifier,
                     image:
                        variant._id === productId
                           ? [mainImg]
                           : [variant.image[0]],
                  }
               }
            )
         )
         setUpdatedDetail(true)
      }
   }, [
      productDetail,
      productLoading,
      productAttributes,
      variantController,
      formControl,
      productId,
      updatedDetail,
   ])

   return (
      <>
         <PopupModal
            title={t('formTitle.addToProgram')}
            maxWidth={'80vw'}
            minWidth={'80vw'}
            open={openForm}
            onClose={() => setOpenForm(false)}
            footer={
               <Button
                  customsize='sm'
                  disabled={!selectedProgramId}
                  variant='contained'
                  onClick={() => handleOnClick()}
               >
                  {t('buttons.common.add', { ns: 'components' })}
               </Button>
            }
         >
            <PromotionProgramsTable
               withToolbar={false}
               disableMultipleSelect
               onSelected={handleOnSelected}
            />
         </PopupModal>
         <PageWrapper>
            <PageHeader>
               <Stack
                  direction='row'
                  alignItems='center'
                  sx={{ maxWidth: '50%', overflow: 'hidden' }}
               >
                  <NavIconButton
                     dir='left'
                     variant='text'
                     size='sm'
                     component={Link as any}
                     to='/products'
                     style={{ marginRight: '16px' }}
                  />
                  <PageTitle
                     type='header'
                     title={
                        productDetail
                           ? productDetail?.product.product_name
                           : 'Loading...'
                     }
                  />
               </Stack>
               <Stack direction='row' alignItems='center'>
                  <Button
                     customsize='sm'
                     variant='outlined'
                     type='submit'
                     onClick={() => setOpenForm(true)}
                     sx={{ mr: '4px' }}
                  >
                     {t('buttons.promotion.addToProgram', { ns: 'components' })}
                  </Button>
                  <Button
                     customsize='sm'
                     variant='contained'
                     type='submit'
                     onClick={handleOnSubmit}
                  >
                     {t('buttons.common.save', { ns: 'components' })}
                  </Button>
               </Stack>
            </PageHeader>
            <Grid container spacing={2}>
               <Grid item xs={12} sm={12}>
                  <ProductForm formControl={formControl} />
               </Grid>
               <Grid item xs={12} sm={12}>
                  <PageContent>
                     <PageTitle type='content' title='Các mẫu sản phẩm' />
                     {variants.length > 0 ? (
                        <Grid container spacing={2}>
                           {variants.map((variant, idx) => (
                              <Grid item xs={12} sm={6} key={idx}>
                                 <VariantOption
                                    variantController={variantController}
                                    variant={variant}
                                    variantIdentifier={variant.identifier}
                                 />
                              </Grid>
                           ))}
                        </Grid>
                     ) : (
                        <Typography
                           className={typography.pc.descSemi}
                           color={colorPalette.dark}
                           component='p'
                        >
                           Thêm thuộc tính cho sản phẩm để bắt đầu tạo các mẫu
                           sản phẩm
                        </Typography>
                     )}
                  </PageContent>
               </Grid>
            </Grid>
         </PageWrapper>
      </>
   )
}

export const ProductDetail = () => {
   const dispatch = useAppDispatch()
   const { productId } = useParams()
   const onSubmit = (data: VariantData[]) => {
      if (productId) {
         dispatch(
            updateProduct({
               productId,
               payload: data,
            })
         )
      }
   }

   return ProductsFormHOC(ProductDetailPage, onSubmit)
}

export default ProductDetail
