import {
   Button as MuiButton,
   ButtonProps as MuiButtonProps,
} from '@mui/material'
import { banner, colorPalette } from '../../config'

const styledButton = (Component: typeof MuiButton) => {
   return ({ sx, children, ...restProps }: MuiButtonProps) => {
      return (
         <Component
            className={banner.smallText}
            sx={{
               bgcolor: colorPalette.primary,
               color: colorPalette.white,
               fontSize: '0.2916rem',
               borderRadius: '2.5px',
               textTransform: 'uppercase',
               padding: '0 8px !important',
               minWidth: '0 !important',
               minHeight: '0 !important',
               '&:hover': {
                  bgcolor: colorPalette.blue.shade_500
               },
               ...sx,
            }}
            {...restProps}
         >
            {children}
         </Component>
      )
   }
}

const BannerButton = styledButton(MuiButton)

export default BannerButton
