import { createAsyncThunk } from '@reduxjs/toolkit'
import { useFetchingHandler } from '../../hooks/use-fetch-handlers'
import { DeviotVideo, SetupPostData, SoftwareData, Status, StatusSaleAgent } from '../../types'
import { resetError, setError, setResponse, setUploading } from '../fetch'
import { States } from '../store'

export type CreateSetupPostBody = {
   title: string
   description: string
   url: string
   thumbnail: string
   content: string
}

export type CreateVideoBody = {
   title: string
   description: string
   urlVideoIntro: string
}

export type CreateSoftwareBody = {
   title: string
   description: string
   thumbnail: string
   downloadLink: string
}

export type BrandPayload = {
   name: string
   email: string
   phone: string
   description: string
   address: string
   thumbnail: string
   status: StatusSaleAgent
}

export type UpdateParams<T> = {
   id: string
   values: T
}

export const deleteSetupPost = createAsyncThunk(
   'cmsState/deleteSetupPost',
   async (id: string, { dispatch }) => {
      const { deleteHandler } = useFetchingHandler({ dispatch })
      try {
         dispatch(setUploading(true))
         dispatch(
            setResponse({
               status: Status.PENDING,
               message: 'Processing ...',
            })
         )

         const res = await deleteHandler<any>({
            url: `instruction-post/${id}`,
            secured: true,
         })

         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.SUCCESS,
               message: res?.message,
            })
         )
         dispatch(resetError())
      } catch (err: any) {
         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.ERROR,
               message: err?.message,
            })
         )
         dispatch(
            setError({
               state: States.CMS,
               log: err,
            })
         )

         throw err
      }
   }
)

export const createSetupPost = createAsyncThunk(
   'cmsState/createSetupPost',
   async (values: CreateSetupPostBody, { dispatch }) => {
      const { postHandler } = useFetchingHandler({ dispatch })
      try {
         dispatch(setUploading(true))
         dispatch(
            setResponse({
               status: Status.PENDING,
               message: 'Processing ...',
            })
         )

         const res = await postHandler<CreateSetupPostBody, SetupPostData>({
            url: `instruction-post`,
            body: values,
            secured: true,
         })

         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.SUCCESS,
               message: res?.message,
            })
         )
         dispatch(resetError())

         return res?.data
      } catch (err: any) {
         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.ERROR,
               message: err?.message,
            })
         )
         dispatch(
            setError({
               state: States.CMS,
               log: err,
            })
         )

         throw err
      }
   }
)

export const updateSetupPost = createAsyncThunk(
   'cmsState/updateSetupPost',
   async ({ id, values }: UpdateParams<CreateSetupPostBody>, { dispatch }) => {
      const { putHandler } = useFetchingHandler({ dispatch })
      try {
         dispatch(setUploading(true))
         dispatch(
            setResponse({
               status: Status.PENDING,
               message: 'Processing ...',
            })
         )

         const res = await putHandler<CreateSetupPostBody, SetupPostData>({
            url: `instruction-post/${id}`,
            body: values,
            secured: true,
         })

         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.SUCCESS,
               message: res?.message,
            })
         )
         dispatch(resetError())
      } catch (err: any) {
         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.ERROR,
               message: err?.message,
            })
         )
         dispatch(
            setError({
               state: States.CMS,
               log: err,
            })
         )

         throw err
      }
   }
)

export const deleteVideo = createAsyncThunk(
   'cmsState/deleteVideo',
   async (id: string, { dispatch }) => {
      const { deleteHandler } = useFetchingHandler({ dispatch })
      try {
         dispatch(setUploading(true))
         dispatch(
            setResponse({
               status: Status.PENDING,
               message: 'Processing ...',
            })
         )

         const res = await deleteHandler<any>({
            url: `video-intro/${id}`,
            secured: true,
         })

         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.SUCCESS,
               message: res?.message,
            })
         )
         dispatch(resetError())
      } catch (err: any) {
         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.ERROR,
               message: err?.message,
            })
         )
         dispatch(
            setError({
               state: States.CMS,
               log: err,
            })
         )

         throw err
      }
   }
)

export const createVideo = createAsyncThunk(
   'cmsState/createVideo',
   async (values: CreateVideoBody, { dispatch }) => {
      const { postHandler } = useFetchingHandler({ dispatch })
      try {
         dispatch(setUploading(true))
         dispatch(
            setResponse({
               status: Status.PENDING,
               message: 'Processing ...',
            })
         )

         const res = await postHandler<CreateVideoBody, DeviotVideo>({
            url: `video-intro`,
            body: values,
            secured: true,
         })

         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.SUCCESS,
               message: res?.message,
            })
         )
         dispatch(resetError())

         return res?.data
      } catch (err: any) {
         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.ERROR,
               message: err?.message,
            })
         )
         dispatch(
            setError({
               state: States.CMS,
               log: err,
            })
         )

         throw err
      }
   }
)

export const updateVideo = createAsyncThunk(
   'cmsState/updateVideo',
   async ({ id, values }: UpdateParams<CreateVideoBody>, { dispatch }) => {
      const { putHandler } = useFetchingHandler({ dispatch })
      try {
         dispatch(setUploading(true))
         dispatch(
            setResponse({
               status: Status.PENDING,
               message: 'Processing ...',
            })
         )

         const res = await putHandler<CreateVideoBody, DeviotVideo>({
            url: `video-intro/${id}`,
            body: values,
            secured: true,
         })

         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.SUCCESS,
               message: res?.message,
            })
         )
         dispatch(resetError())
      } catch (err: any) {
         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.ERROR,
               message: err?.message,
            })
         )
         dispatch(
            setError({
               state: States.CMS,
               log: err,
            })
         )

         throw err
      }
   }
)

export const deleteSoftware = createAsyncThunk(
   'cmsState/deleteSoftware',
   async (id: string, { dispatch }) => {
      const { deleteHandler } = useFetchingHandler({ dispatch })
      try {
         dispatch(setUploading(true))
         dispatch(
            setResponse({
               status: Status.PENDING,
               message: 'Processing ...',
            })
         )

         const res = await deleteHandler<any>({
            url: `software-data/${id}`,
            secured: true,
         })

         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.SUCCESS,
               message: res?.message,
            })
         )
         dispatch(resetError())
      } catch (err: any) {
         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.ERROR,
               message: err?.message,
            })
         )
         dispatch(
            setError({
               state: States.CMS,
               log: err,
            })
         )

         throw err
      }
   }
)

export const createSoftware = createAsyncThunk(
   'cmsState/createSoftware',
   async (values: CreateSoftwareBody, { dispatch }) => {
      const { postHandler } = useFetchingHandler({ dispatch })
      try {
         dispatch(setUploading(true))
         dispatch(
            setResponse({
               status: Status.PENDING,
               message: 'Processing ...',
            })
         )

         const res = await postHandler<CreateSoftwareBody, SoftwareData>({
            url: `software-data`,
            body: values,
            secured: true,
         })

         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.SUCCESS,
               message: res?.message,
            })
         )
         dispatch(resetError())

         return res?.data
      } catch (err: any) {
         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.ERROR,
               message: err?.message,
            })
         )
         dispatch(
            setError({
               state: States.CMS,
               log: err,
            })
         )

         throw err
      }
   }
)

export const updateSoftware = createAsyncThunk(
   'cmsState/updateSoftware',
   async ({ id, values }: UpdateParams<CreateSoftwareBody>, { dispatch }) => {
      const { putHandler } = useFetchingHandler({ dispatch })
      try {
         dispatch(setUploading(true))
         dispatch(
            setResponse({
               status: Status.PENDING,
               message: 'Processing ...',
            })
         )

         const res = await putHandler<CreateSoftwareBody, SoftwareData>({
            url: `software-data/${id}`,
            body: values,
            secured: true,
         })

         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.SUCCESS,
               message: res?.message,
            })
         )
         dispatch(resetError())
      } catch (err: any) {
         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.ERROR,
               message: err?.message,
            })
         )
         dispatch(
            setError({
               state: States.CMS,
               log: err,
            })
         )

         throw err
      }
   }
)

export const createBrand = createAsyncThunk(
   'cmsState/createBrand',
   async (values: BrandPayload, { dispatch }) => {
      const { postHandler } = useFetchingHandler({ dispatch })
      try {
         dispatch(setUploading(true))
         dispatch(
            setResponse({
               status: Status.PENDING,
               message: 'Processing ...',
            })
         )

         const res = await postHandler<BrandPayload, any>({
            url: 'sales-agent',
            body: values,
            secured: true,
         })

         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.SUCCESS,
               message: res?.message,
            })
         )
         dispatch(resetError())
      } catch (err: any) {
         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.ERROR,
               message: err?.message,
            })
         )
         dispatch(
            setError({
               state: States.CMS,
               log: err,
            })
         )

         throw err
      }
   }
)

export const updateBrand = createAsyncThunk(
   'cmsState/updateBrand',
   async (
      { id, values }: { id: string; values: BrandPayload },
      { dispatch }
   ) => {
      const { putHandler } = useFetchingHandler({ dispatch })
      try {
         dispatch(setUploading(true))
         dispatch(
            setResponse({
               status: Status.PENDING,
               message: 'Processing ...',
            })
         )

         const res = await putHandler<BrandPayload, any>({
            url: `sales-agent/${id}`,
            body: values,
            secured: true,
         })

         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.SUCCESS,
               message: res?.message,
            })
         )
         dispatch(resetError())
      } catch (err: any) {
         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.ERROR,
               message: err?.message,
            })
         )
         dispatch(
            setError({
               state: States.CMS,
               log: err,
            })
         )

         throw err
      }
   }
)

export const deleteBrand = createAsyncThunk(
   'cmsState/deleteBrand',
   async (id: string, { dispatch }) => {
      const { deleteHandler } = useFetchingHandler({ dispatch })
      try {
         dispatch(setUploading(true))
         dispatch(
            setResponse({
               status: Status.PENDING,
               message: 'Processing ...',
            })
         )

         const res = await deleteHandler({
            url: `sales-agent/${id}`,
            secured: true,
         })

         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.SUCCESS,
               message: res?.message,
            })
         )
         dispatch(resetError())
      } catch (err: any) {
         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.ERROR,
               message: err?.message,
            })
         )
         dispatch(
            setError({
               state: States.CMS,
               log: err,
            })
         )

         throw err
      }
   }
)
