import { KeyedMutator } from 'swr'
import { Maybe, SoftwareData } from '../../types'
import { useQuery } from '../use-swr/use-query'
import { APIReturnData } from '../use-fetch-handlers'

type UseSoftwares = () => {
   isLoading: boolean
   softwares?: Maybe<SoftwareData[]>
   error: any
   mutate: KeyedMutator<APIReturnData<SoftwareData[]>>
}

type UseSoftwareDetail = (id: string) => {
   isLoading: boolean
   softwareDetail?: Maybe<SoftwareData>
   error: any
   mutate: KeyedMutator<APIReturnData<SoftwareData>>
}

export const useSoftwares: UseSoftwares = () => {
   const { data, isLoading, error, mutate } = useQuery<SoftwareData[]>({
      url: 'software-data',
      secured: true,
      fetchOptions: {
         refreshInterval: 60000,
         revalidateIfStale: true,
      },
   })

   return {
      isLoading,
      error,
      mutate,
      softwares: data?.data,
   }
}

export const useSoftwareDetail: UseSoftwareDetail = (id: string) => {
   const { data, isLoading, error, mutate } = useQuery<SoftwareData>({
      url: `software-data/${id}`,
      secured: true,
      fetchOptions: {
         refreshInterval: 60000,
         revalidateIfStale: true,
      },
   })

   return {
      isLoading,
      error,
      mutate,
      softwareDetail: data?.data,
   }
}
