export const requiredMsg = 'Không được bỏ trống giá trị'
export const warningMsg = {
   __html: `
    Hành động này sẽ xoá đối tượng được chọn! <br />
    Chắc chắn muốn thực hiện?
 `,
}
export const managerGrantPermMsg = {
   __html: `
    Hành động này sẽ kích hoạt quyền cho quản lý này! <br />
    Chắc chắn muốn thực hiện?
 `,
}
