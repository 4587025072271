import { FC, useEffect, useMemo } from 'react'
import { Box, Typography, Grid } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { colorPalette, typography } from '../../../config'
import {
   NavIconButton,
   Button,
   ImageEditor,
   Input,
   BannerCard,
} from '../../../components'
import {
   PageContent,
   PageHeader,
   PageTitle,
   PageWrapper,
} from '../../../layout'
import { Status } from '../../../types'
import { yupResolver } from '@hookform/resolvers/yup'
import {
   createBanner,
   selectFetch,
   useAppDispatch,
   useAppSelector,
} from '../../../redux'
import { bannerCreateFormSchema } from '../../../schemas/bannerSchema'
import { useTranslation } from 'react-i18next'

interface BannerCreateFormValues {
   button_text?: string
   button_link?: string
   product?: string
   large_text_1?: string
   small_text_1?: string
   image_id: string
}

const BannerCreate: FC = () => {
   const { response } = useAppSelector(selectFetch)
   const dispatch = useAppDispatch()
   const { t } = useTranslation(['common', 'components'])

   const {
      control,
      handleSubmit,
      setValue,
      reset,
      watch,
      formState: { errors, isDirty },
   } = useForm<BannerCreateFormValues>({
      defaultValues: {
         button_text: '',
         button_link: '',
         product: '',
         large_text_1: '',
         small_text_1: '',
         image_id: '',
      },
      resolver: yupResolver(bannerCreateFormSchema),
   })

   const onSubmit = (data: BannerCreateFormValues) => {
      dispatch(createBanner(data))
   }

   const handleOnSelectedImgsChange = (imgs: string[]) => {
      setValue('image_id', imgs.length > 0 ? imgs[0] : '', {
         shouldDirty: true,
      })
   }

   const renderForm = useMemo(
      () => (
         <PageContent>
            <PageTitle
               type='content'
               title={t('blockTitle.general')}
               withIcons
            />
            <Grid container spacing={2} sx={{ marginTop: '4px' }}>
               <Grid item xs={12} sm={12}>
                  <Controller
                     name='button_text'
                     control={control}
                     rules={{ required: true }}
                     render={({ field }) => (
                        <Input
                           label={
                              t('banners.buttonText.label', {
                                 ns: 'inputs',
                              }) as string
                           }
                           fullWidth
                           placeholder={
                              t('banners.buttonText.placeholder', {
                                 ns: 'inputs',
                              }) as string
                           }
                           type='text'
                           error={!!errors.button_text}
                           helperText={errors.button_text?.message}
                           {...field}
                        />
                     )}
                  />
               </Grid>
               <Grid item xs={12} sm={12}>
                  <Controller
                     name='button_link'
                     control={control}
                     rules={{ required: true }}
                     render={({ field }) => (
                        <Input
                           label={
                              t('banners.buttonLink.label', {
                                 ns: 'inputs',
                              }) as string
                           }
                           fullWidth
                           placeholder={
                              t('banners.buttonLink.placeholder', {
                                 ns: 'inputs',
                              }) as string
                           }
                           type='text'
                           error={!!errors.button_link}
                           helperText={errors.button_link?.message}
                           {...field}
                        />
                     )}
                  />
               </Grid>
               <Grid item xs={12} sm={12}>
                  <Controller
                     name='product'
                     control={control}
                     rules={{ required: true }}
                     render={({ field }) => (
                        <Input
                           label={
                              t('banners.product.label', {
                                 ns: 'inputs',
                              }) as string
                           }
                           fullWidth
                           placeholder={
                              t('banners.product.placeholder', {
                                 ns: 'inputs',
                              }) as string
                           }
                           type='text'
                           error={!!errors.product}
                           helperText={errors.product?.message}
                           {...field}
                        />
                     )}
                  />
               </Grid>
               <Grid item xs={12} sm={12}>
                  <Controller
                     name='large_text_1'
                     control={control}
                     rules={{ required: true }}
                     render={({ field }) => (
                        <Input
                           required
                           label={
                              t('banners.largeText1.label', {
                                 ns: 'inputs',
                              }) as string
                           }
                           fullWidth
                           placeholder={
                              t('banners.largeText1.placeholder', {
                                 ns: 'inputs',
                              }) as string
                           }
                           type='text'
                           error={!!errors.large_text_1}
                           helperText={errors.large_text_1?.message}
                           {...field}
                        />
                     )}
                  />
               </Grid>
               <Grid item xs={12} sm={12}>
                  <Controller
                     name='small_text_1'
                     control={control}
                     rules={{ required: true }}
                     render={({ field }) => (
                        <Input
                           label={
                              t('banners.smallText1.label', {
                                 ns: 'inputs',
                              }) as string
                           }
                           fullWidth
                           placeholder={
                              t('banners.smallText1.placeholder', {
                                 ns: 'inputs',
                              }) as string
                           }
                           type='text'
                           error={!!errors.small_text_1}
                           helperText={errors.small_text_1?.message}
                           {...field}
                        />
                     )}
                  />
               </Grid>
            </Grid>
         </PageContent>
      ),
      [control, errors, t]
   )

   useEffect(() => {
      if (response?.status === Status.SUCCESS) {
         reset()
      }
   }, [response?.status])

   return (
      <PageWrapper>
         <PageHeader>
            <Box sx={{ display: 'flex' }}>
               <NavIconButton
                  dir='left'
                  variant='text'
                  size='sm'
                  component={Link as any}
                  to='/banners'
                  style={{ marginRight: '16px' }}
               />
               <Typography
                  className={typography.pc.h6}
                  color={colorPalette.dark}
                  component='div'
               >
                  {t('pageTitle.banner.create')}
               </Typography>
            </Box>
            <Button
               disabled={!isDirty}
               customsize='sm'
               variant='contained'
               type='submit'
               onClick={handleSubmit(onSubmit)}
            >
               {t('buttons.common.save', { ns: 'components' })}
            </Button>
         </PageHeader>
         <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
               {renderForm}
            </Grid>
            <Grid item xs={12} sm={6}>
               <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                     <PageContent>
                        <PageTitle
                           type='content'
                           title={t('blockTitle.preview')}
                        />
                        <BannerCard
                           preview
                           banner={{
                              image_id: watch('image_id'),
                              button_text: watch('button_text'),
                              button_link: watch('button_link'),
                              large_text_1: watch('large_text_1'),
                              small_text_1: watch('small_text_1'),
                           }}
                        />
                     </PageContent>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                     {errors?.image_id ? (
                        <Typography
                           className={typography.pc.helpReg}
                           color={colorPalette.red.shade_500}
                        >
                           Chưa chọn ảnh
                        </Typography>
                     ) : null}
                     <ImageEditor
                        variantIdentifier=''
                        onSelectedImgsChanges={handleOnSelectedImgsChange}
                     />
                  </Grid>
               </Grid>
            </Grid>
         </Grid>
      </PageWrapper>
   )
}

export default BannerCreate
