import { ManagerPermission } from '../types'

export const getPermission = (permission: ManagerPermission) => {
   switch (permission) {
      case ManagerPermission.ADMIN:
         return 'Admin'
      case ManagerPermission.SHOP_KEEPER:
         return 'Shop Keeper'
      case ManagerPermission.PACKAGE_MAN:
         return 'Package Man'
      default:
         return 'Invalid permission'
   }
}
