/* Libs */
import React from 'react'
import { createBrowserRouter } from 'react-router-dom'
/* Components */
import Login from '../pages/Login'
import Portal from '../pages/Portal'
import NotFound from '../pages/NotFound'
import Dashboard from '../pages/Dashboard'
import { ProductCreate, AllProducts, ProductDetail } from '../pages/Products'
import { ProductTypeCreate, ProductTypesTable } from '../pages/ProductTypes'
import { OrdersTable, OrderDetail } from '../pages/Orders'
import { ManagersTable, ManagerCreate, ManagerDetail } from '../pages/Managers'
import { UsersTable, UserDetail } from '../pages/Users'
import {
   Promotion,
   PromotionProgramsTable,
   VouchersTable,
} from '../pages/Promotions'
import Media from '../pages/Media'
import { AllBanners, BannerCreate } from '../pages/Banners'
import { ReactComponent as DashboardIcon } from '../assets/icons/dashboard.svg'
import { ReactComponent as ProductIcon } from '../assets/icons/cart.svg'
import { ReactComponent as OrderIcon } from '../assets/icons/note.svg'
import { ReactComponent as ManagersIcon } from '../assets/icons/headphone.svg'
import { ReactComponent as UsersIcon } from '../assets/icons/users.svg'
import { ReactComponent as MediaIcon } from '../assets/icons/media.svg'
import { ReactComponent as ProductTypeIcon } from '../assets/icons/pie-chart.svg'
import { ReactComponent as VoucherIcon } from '../assets/icons/voucher.svg'
import { ReactComponent as BannerIcon } from '../assets/icons/banner.svg'
import { ReactComponent as DocumentIcon } from '../assets/icons/document.svg'
import { ReactComponent as CameraIcon } from '../assets/icons/camera.svg'
import { ReactComponent as DownloadIcon } from '../assets/icons/download.svg'
import { ReactComponent as BrandIcon } from '../assets/icons/brands.svg'
import ProductTypeDetail from '../pages/ProductTypes/ProductTypeDetail'
import PromotionProgramCreate from '../pages/Promotions/PromotionProgramCreate'
import PromotionProgramDetail from '../pages/Promotions/PromotionProgramDetail'
import BannerDetail from '../pages/Banners/BannerDetail'
import { VideoCreate, VideoDetail, VideosTable } from '../pages/Videos'
import {
   SoftWareDetail,
   SoftwareCreate,
   SoftwareTables,
} from '../pages/Softwares'
import {
   SetupPostCreate,
   SetupPostDetail,
   SetupPostsTable,
} from '../pages/SetupPost'
import Brands from '../pages/Brands'
import VoucherCreate from '../pages/Promotions/VoucherCreate'
import BrandCreate from '../pages/Brands/BrandCreate'
import BrandDetail from '../pages/Brands/BrandDetail'

interface BasicRouteType {
   name: string
   title: string
   path: string
   icon?: React.FunctionComponent<any>
   hideInMenu: boolean
}

export interface RouteType extends BasicRouteType {
   subRoutes?: RouteType[]
}

export const portalRoutes: RouteType[] = [
   {
      name: 'dashboard',
      title: 'Dashboard',
      path: '/dashboard',
      icon: DashboardIcon,
      hideInMenu: false,
   },
   {
      name: 'products',
      title: 'Products',
      path: '/products',
      icon: ProductIcon,
      hideInMenu: false,
   },
   {
      name: 'productTypes',
      title: 'Product Types',
      path: '/product-types',
      icon: ProductTypeIcon,
      hideInMenu: false,
   },
   {
      name: 'orders',
      title: 'Orders',
      path: '/orders',
      icon: OrderIcon,
      hideInMenu: false,
   },
   {
      name: 'managers',
      title: 'Managers',
      path: '/managers',
      icon: ManagersIcon,
      hideInMenu: false,
   },
   {
      name: 'users',
      title: 'Users',
      path: '/users',
      icon: UsersIcon,
      hideInMenu: false,
   },
   {
      name: 'promotion',
      title: 'Promotions & Vouchers',
      path: '/promotion',
      icon: VoucherIcon,
      hideInMenu: false,
   },
   {
      name: 'media',
      title: 'Media',
      path: '/media',
      icon: MediaIcon,
      hideInMenu: false,
   },
   {
      name: 'banners',
      title: 'Banners',
      path: '/banners',
      icon: BannerIcon,
      hideInMenu: false,
   },
   {
      name: 'videos',
      title: 'Videos',
      path: '/videos',
      icon: CameraIcon,
      hideInMenu: false,
   },
   {
      name: 'softwares',
      title: 'Softwares',
      path: '/softwares',
      icon: DocumentIcon,
      hideInMenu: false,
   },
   {
      name: 'setups',
      title: 'Setups',
      path: '/setups',
      icon: DownloadIcon,
      hideInMenu: false,
   },
   {
      name: 'brands',
      title: 'Brands',
      path: '/brands',
      icon: BrandIcon,
      hideInMenu: false,
   },
]

export const router = createBrowserRouter([
   {
      path: '/404',
      element: <NotFound />,
   },
   {
      path: '/login',
      element: <Login />,
   },
   {
      path: '/',
      element: <Portal />,
      errorElement: <NotFound />,
      children: [
         {
            path: '/dashboard',
            element: <Dashboard />,
         },
         {
            path: '/products',
            element: <AllProducts />,
         },
         {
            path: '/product-types',
            element: <ProductTypesTable />,
         },
         {
            path: '/product-types/create',
            element: <ProductTypeCreate />,
         },
         {
            path: '/product-types/:productTypeId',
            element: <ProductTypeDetail />,
         },
         {
            path: '/products/create',
            element: <ProductCreate />,
         },
         {
            path: '/products/:productId',
            element: <ProductDetail />,
         },
         {
            path: '/orders',
            element: <OrdersTable />,
         },
         {
            path: '/orders/:orderId',
            element: <OrderDetail />,
         },
         {
            path: '/managers',
            element: <ManagersTable />,
         },
         {
            path: '/managers/create',
            element: <ManagerCreate />,
         },
         {
            path: '/managers/:managerId',
            element: <ManagerDetail />,
         },
         {
            path: '/users',
            element: <UsersTable />,
         },
         {
            path: '/users/:userId',
            element: <UserDetail />,
         },
         {
            path: '/promotion',
            element: <Promotion />,
            children: [
               {
                  path: 'programs',
                  element: <PromotionProgramsTable />,
                  index: true,
               },
               {
                  path: 'vouchers',
                  element: <VouchersTable />,
               },
            ],
         },
         {
            path: '/promotion-program/:programId',
            element: <PromotionProgramDetail />,
         },
         {
            element: <PromotionProgramCreate />,
            path: '/promotion-program-create',
         },
         {
            element: <VoucherCreate />,
            path: '/voucher/voucher-create',
         },
         {
            path: '/media',
            element: <Media />,
         },
         {
            path: '/banners',
            element: <AllBanners />,
         },
         {
            path: '/banners/create',
            element: <BannerCreate />,
         },
         {
            path: '/banners/:bannerId',
            element: <BannerDetail />,
         },
         {
            path: '/videos',
            element: <VideosTable />,
         },
         {
            path: '/videos/create',
            element: <VideoCreate />,
         },
         {
            path: '/videos/:videoId',
            element: <VideoDetail />,
         },
         {
            path: '/softwares',
            element: <SoftwareTables />,
         },
         {
            path: '/softwares/create',
            element: <SoftwareCreate />,
         },
         {
            path: '/softwares/:softwareId',
            element: <SoftWareDetail />,
         },
         {
            path: '/setups',
            element: <SetupPostsTable />,
         },
         {
            path: '/setups/create',
            element: <SetupPostCreate />,
         },
         {
            path: '/setups/:setupPostId',
            element: <SetupPostDetail />,
         },
         {
            path: '/brands',
            element: <Brands />,
         },
         {
            path: '/brands/create',
            element: <BrandCreate />,
         },
         {
            path: '/brands/:brandId',
            element: <BrandDetail />,
         },
      ],
   },
])
