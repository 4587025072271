import _ from 'lodash'
import { ProductData } from '../types'

export type ProductAttribute = {
   slug: string
   title: string
   values: ProductMetaDataOption[]
}

export interface ProductMetaDataOption {
   name: string
   slug: string
}

export interface ProductMetaDataCombinedOption extends ProductMetaDataOption {
   attribute_slug: string
   attribute_title: string
}

export interface ProductCombinedAttribute {
   slug: string
   title: string
   values: ProductMetaDataCombinedOption[]
}

/**
 * This function resolves the Combination of k attributes, each attribute has n options
 * @param attributes
 */
export const generateVariantsFromAttributes = (
   attributes: ProductCombinedAttribute[]
): ProductMetaDataCombinedOption[][] => {
   const getCombinations = (
      currentIndex: number,
      currentCombination: ProductMetaDataCombinedOption[]
   ) => {
      if (currentIndex === attributes.length) {
         combinations.push([...currentCombination])
         return
      }

      for (let i = 0; i < attributes[currentIndex].values.length; i++) {
         currentCombination[currentIndex] = attributes[currentIndex].values[i]
         getCombinations(currentIndex + 1, currentCombination)
      }
   }

   const combinations: ProductMetaDataCombinedOption[][] = []
   getCombinations(0, [])

   return combinations
}

const generateSlug = (str: string): string => {
   // remove accents
   var from =
         'àáãảạăằắẳẵặâầấẩẫậèéẻẽẹêềếểễệđùúủũụưừứửữựòóỏõọôồốổỗộơờớởỡợìíỉĩịäëïîöüûñçýỳỹỵỷ',
      to =
         'aaaaaaaaaaaaaaaaaeeeeeeeeeeeduuuuuuuuuuuoooooooooooooooooiiiiiaeiiouuncyyyyy'
   for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(RegExp(from[i], 'gi'), to[i])
   }

   str = str
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9\-]/g, '-')
      .replace(/-+/g, '-')

   return str
}

export const generateSlugsForAttributes = (
   attributes: ProductAttribute[]
): ProductCombinedAttribute[] => {
   return attributes.map((attr) => {
      const attribute_slug = generateSlug(attr.title)

      return {
         slug: attribute_slug,
         title: attr.title,
         values: attr.values.map((val) => ({
            name: val.name,
            slug: generateSlug(val.name),
            attribute_slug,
            attribute_title: attr.title,
         })),
      }
   })
}

export const getProductAttributes = (
   variants: ProductData[]
): ProductAttribute[] => {
   const variantsMetadata = variants.map((variant) => variant.product_metadata)
   const flattenVariantsMetadata = _.flatten(variantsMetadata)

   const grouppedAttributes = _.groupBy(flattenVariantsMetadata, 'slug')
   const attributes: ProductAttribute[] = Object.keys(grouppedAttributes).map(
      (key) => {
         const groupedValues = _.groupBy(
            grouppedAttributes[key]?.map((attr) => attr?.value),
            'slug'
         )

         return {
            slug: key || '',
            title: grouppedAttributes[key][0]?.title || '',
            values: Object.keys(groupedValues).map((key) => ({
               slug: key,
               name: groupedValues[key][0]?.name || '',
            })),
         }
      }
   )

   return attributes
}
