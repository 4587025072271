import type { AddressDetail } from '../types'

export const getAddress = (address: AddressDetail) => {
   if (address) {
      const { address_line_1, city, state, country } = address

      return [address_line_1, city, state, country].filter(info => !!info).join(',')
   }

   return 'Invalid address'
}
