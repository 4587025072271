import { useEffect, useState } from 'react'
import { Maybe } from '../../types'
import { tokenStorage } from './token-storage'

const defaultToken = 'null'

export type UserToken = {
   accessToken: string
   refreshToken: string
}

type UseToken = () => {
   userToken: Maybe<UserToken>
   loading: boolean
}

export const useToken: UseToken = () => {
   const [userToken, setUserToken] = useState<Maybe<UserToken>>(null)
   const [loading, setLoading] = useState<boolean>(true)

   useEffect(() => {
      if (typeof window !== 'undefined') {
         const userTokenFromStorage = JSON.parse(
            localStorage.getItem(tokenStorage) || defaultToken
         )

         setUserToken(userTokenFromStorage)
         setLoading(false)
      }
   }, [])

   return { userToken, loading }
}

export const saveAccessTokenToStorage = (token: string) => {
   if (typeof window !== 'undefined') {
      const userTokenFromStorage = JSON.parse(
         localStorage.getItem(tokenStorage) || defaultToken
      )

      const newToken = {
         ...userTokenFromStorage,
         accessToken: token,
      }

      localStorage.setItem(tokenStorage, JSON.stringify(newToken))
   }
}

export const handleTokenExpired = (token: string) => {
   if (typeof window !== 'undefined') {
      localStorage.removeItem(tokenStorage)

      window.location.reload()
   }
}
