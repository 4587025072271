import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getAllBanners, getBannerDetail } from './bannerThunk'
import { BannerData, Maybe } from '../../types'

interface ProductsState {
   bannerLoading: boolean
   banners: BannerData[]
   bannerDetail: Maybe<BannerData>
}

interface GetAllBannersPayload {
   banners: BannerData[]
}

interface GetBannerDetailPayload {
   bannerDetail: Maybe<BannerData>
}

const initialState: ProductsState = {
   bannerLoading: false,
   banners: [],
   bannerDetail: null,
}

export const bannerSlice = createSlice({
   name: 'bannerState',
   initialState,
   reducers: {
      resetBanners: (state) => {
         state.banners = []
      },
      resetBannerDetail: (state) => {
         state.bannerDetail = null
      },
   },
   extraReducers: (builder) => {
      builder
         .addCase(getAllBanners.pending, (state) => {
            state.bannerLoading = true
         })
         .addCase(
            getAllBanners.fulfilled,
            (state, action: PayloadAction<GetAllBannersPayload, string>) => {
               state.bannerLoading = false
               state.banners = action.payload.banners
            }
         )
         .addCase(getAllBanners.rejected, (state) => {
            state.bannerLoading = false
         })
         .addCase(getBannerDetail.pending, (state) => {
            state.bannerLoading = true
         })
         .addCase(
            getBannerDetail.fulfilled,
            (state, action: PayloadAction<GetBannerDetailPayload, string>) => {
               state.bannerLoading = false
               state.bannerDetail = action.payload.bannerDetail
            }
         )
         .addCase(getBannerDetail.rejected, (state) => {
            state.bannerLoading = false
            state.bannerDetail = null
         })
   },
})

export const { resetBanners, resetBannerDetail } = bannerSlice.actions

export default bannerSlice.reducer
