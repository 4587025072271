import { ChangeEvent, FC, useMemo } from 'react'
import { selectProduct, useAppSelector } from '../../redux'
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg'
import { useTranslation } from 'react-i18next'
import {
   MetaDataBox,
   PageContent,
   PageTitle,
   RemoveMetaDataBtn,
} from '../../layout'
import { useParams } from 'react-router-dom'
import {
   Box,
   Checkbox,
   FormControlLabel,
   Grid,
   Typography,
} from '@mui/material'
import { CircleLoading } from '../Loading'
import { Controller, useFieldArray } from 'react-hook-form'
import { ProductAttribute } from '../../utils/generate-variant-from-attributes'
import Input from '../Input'
import { ProductMetadataValueField } from '../ProductForm'
import Icon from '../Icon'
import { colorPalette, typography } from '../../config'
import { Select, SelectOption } from '../Select'
import Button from '../Button'
import { ProductFormControl } from '../../hooks/use-products-form'
import Editor from '../Editor'
import ImageEditor from '../ImageEditor'
import {
   transformNumberInput,
   transformNumberOutput,
} from '../../utils/validation'

export interface ProductCreateFormValues {
   product_name: string
   product_type: string
   price: number
   image: string[]
   product_metadata: ProductAttribute[]
   product_instruction: string
   product_description: string
   product_short_description?: string
   uid?: string
   rating?: number
   _id?: string
   slug?: string
}

export const defaultProductCreateFormValues: ProductCreateFormValues = {
   product_name: '',
   product_type: '',
   price: 0,
   product_metadata: [
      {
         slug: '',
         title: '',
         values: [
            {
               name: '',
               slug: '',
            },
         ],
      },
   ],
   slug: '',
   uid: '',
   _id: '',
   image: [],
   rating: 1,
   product_description: '',
   product_short_description: '',
   product_instruction: '',
}

type ProductFormProps = {
   formControl: ProductFormControl
}

const ProductForm: FC<ProductFormProps> = ({
   formControl: { control, errors, watch, setValue },
}) => {
   const { productId } = useParams()
   const { productLoading, productTypes } = useAppSelector(selectProduct)

   const { t } = useTranslation(['common', 'components'])

   const { fields, append, remove } = useFieldArray({
      control,
      name: 'product_metadata',
   })

   const [
      descriptionContent,
      instructionContent,
      shortDescriptionContent,
      image,
   ] = watch([
      'product_description',
      'product_instruction',
      'product_short_description',
      'image',
   ])

   const handleAddAttribute = () => {
      append({
         slug: '',
         title: '',
         values: [{ name: '', slug: '' }],
      })
   }

   const handleClearAttribute = () => {
      remove()
      append({
         slug: '',
         title: '',
         values: [{ name: '', slug: '' }],
      })
   }

   const onEditorStateChange = (
      value: string,
      type:
         | 'product_description'
         | 'product_instruction'
         | 'product_short_description'
   ) => {
      setValue(type, value, { shouldDirty: true })
   }

   const handleChangeMainImages = (imagesToUpload: string[]) => {
      setValue('image', imagesToUpload, { shouldDirty: true })
   }

   const renderAttributes = fields.map((attr, idx) => {
      return (
         <Grid item xs={4} key={attr.id}>
            <MetaDataBox>
               <Grid container spacing={2}>
                  <Grid item xs={12}>
                     <Controller
                        key={attr.id}
                        name={`product_metadata.${idx}.title`}
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                           <Input
                              label='Tên thuộc tính'
                              fullWidth
                              placeholder='Nhập tên thuộc tính'
                              type='text'
                              error={
                                 errors.product_metadata &&
                                 !!errors?.product_metadata[idx]?.title
                              }
                              helperText={
                                 errors.product_metadata &&
                                 errors?.product_metadata[idx]?.title?.message
                              }
                              {...field}
                           />
                        )}
                     />
                  </Grid>
                  <Grid item xs={12}>
                     <ProductMetadataValueField
                        nestIndex={idx}
                        control={control}
                        watch={watch}
                        errors={errors}
                     />
                  </Grid>
               </Grid>
               {fields.length > 1 && (
                  <RemoveMetaDataBtn
                     variant='contained'
                     color='error'
                     onClick={() => remove(idx)}
                  >
                     <Icon
                        type='fill'
                        color={colorPalette.white}
                        src={CloseIcon}
                        width={12}
                        height={12}
                     />
                  </RemoveMetaDataBtn>
               )}
            </MetaDataBox>
         </Grid>
      )
   })

   const renderForm = useMemo(
      () =>
         productTypes ? (
            <Grid container spacing={2}>
               <Grid item xs={12} sm={7}>
                  <Controller
                     name='product_name'
                     control={control}
                     rules={{ required: true }}
                     render={({ field }) => (
                        <Input
                           label={
                              t('product.name.label', {
                                 ns: 'inputs',
                              }) as string
                           }
                           fullWidth
                           placeholder={
                              t('product.name.placeholder', {
                                 ns: 'inputs',
                              }) as string
                           }
                           type='text'
                           error={!!errors.product_name}
                           helperText={errors.product_name?.message}
                           {...field}
                        />
                     )}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Controller
                     name='product_type'
                     control={control}
                     rules={{ required: true }}
                     render={({ field }) => (
                        <Select
                           label={
                              t('product.type.label', {
                                 ns: 'inputs',
                              }) as string
                           }
                           placeholder={
                              t('product.name.placeholder', {
                                 ns: 'inputs',
                              }) as string
                           }
                           type='text'
                           error={!!errors.product_type}
                           helperText={errors.product_type?.message}
                           {...field}
                        >
                           {productTypes.map(({ _id, product_type_name }) => (
                              <SelectOption key={_id} value={_id}>
                                 {product_type_name}
                              </SelectOption>
                           ))}
                        </Select>
                     )}
                  />
               </Grid>
               <Grid item xs={12} sm={2}>
                  <Controller
                     name='rating'
                     control={control}
                     rules={{ required: true }}
                     render={({ field  }) => (
                        <Select
                           label='Đánh giá'
                           placeholder='Số sao đánh giá'
                           type='text'
                           error={!!errors.rating}
                           helperText={errors.rating?.message}
                           {...field}
                        >
                           {[1, 2, 3, 4, 5].map((value) => (
                              <SelectOption key={value} value={value}>
                                 {value}
                              </SelectOption>
                           ))}
                        </Select>
                     )}
                  />
               </Grid>
               <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{ display: 'flex', flexDirection: 'column' }}
               >
                  <Typography
                     className={typography.mb.descSemi}
                     color={colorPalette.dark}
                     sx={{ marginLeft: '16px' }}
                  >
                     Mô tả
                  </Typography>
                  <Editor
                     style={{ flex: 1, marginBottom: '48px' }}
                     id='product_short_description'
                     theme='snow'
                     value={shortDescriptionContent}
                     onChange={(val) =>
                        onEditorStateChange(val, 'product_short_description')
                     }
                     helperText={errors.product_instruction?.message}
                  />
               </Grid>
               <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{ display: 'flex', flexDirection: 'column' }}
               >
                  <Typography
                     className={typography.mb.descSemi}
                     color={colorPalette.dark}
                     sx={{ marginLeft: '16px' }}
                  >
                     Giới thiệu sản phẩm
                  </Typography>
                  <Editor
                     style={{ flex: 1, marginBottom: '48px' }}
                     id='product_description'
                     theme='snow'
                     value={descriptionContent}
                     onChange={(val) =>
                        onEditorStateChange(val, 'product_description')
                     }
                     helperText={errors.product_instruction?.message}
                  />
               </Grid>
               <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{ display: 'flex', flexDirection: 'column' }}
               >
                  <Typography
                     className={typography.mb.descSemi}
                     color={colorPalette.dark}
                     sx={{ marginLeft: '16px' }}
                  >
                     Hướng dẫn sử dụng
                  </Typography>
                  <Editor
                     id='product_instruction'
                     theme='snow'
                     style={{ flex: 1, marginBottom: '48px' }}
                     value={instructionContent}
                     onChange={(val) =>
                        onEditorStateChange(val, 'product_instruction')
                     }
                     helperText={errors.product_description?.message}
                  />
               </Grid>
               <Grid item xs={12}>
                  <Typography
                     className={typography.pc.s3}
                     color={colorPalette.dark}
                     sx={{ marginTop: '16px' }}
                  >
                     Chọn ảnh chung
                  </Typography>
                  {errors?.image ? (
                     <Typography
                        className={typography.pc.helpReg}
                        color={colorPalette.red.shade_500}
                     >
                        Chưa chọn ảnh
                     </Typography>
                  ) : null}
                  <ImageEditor
                     productId={productId}
                     initialSelectedImgs={image}
                     variantIdentifier={productId || 'main'}
                     multipleSelect
                     cols={8}
                     onSelectedImgsChanges={handleChangeMainImages}
                  />
               </Grid>
               <Grid item xs={12}>
                  <Typography
                     className={typography.pc.s3}
                     color={colorPalette.dark}
                     sx={{ marginTop: '16px' }}
                  >
                     {t('common.title', { ns: 'inputs' })}
                  </Typography>
                  <Grid container spacing={2}>
                     {renderAttributes}
                  </Grid>
               </Grid>
               <Grid item xs={12}>
                  <Grid container spacing={2}>
                     <Grid item xs={12} md={6}>
                        <Button
                           customsize='sm'
                           variant='contained'
                           fullWidth
                           onClick={() => handleAddAttribute()}
                        >
                           {t('buttons.common.addMetadata', {
                              ns: 'components',
                           })}
                        </Button>
                     </Grid>
                     <Grid item xs={12} md={6}>
                        <Button
                           disabled={fields.length < 2}
                           customsize='sm'
                           variant='outlined'
                           color='error'
                           fullWidth
                           onClick={() => handleClearAttribute()}
                        >
                           {t('buttons.common.removeMetadata', {
                              ns: 'components',
                           })}
                        </Button>
                     </Grid>
                  </Grid>
               </Grid>
            </Grid>
         ) : null,
      [
         control,
         descriptionContent,
         errors.price,
         errors.product_name,
         errors.product_type,
         fields.length,
         instructionContent,
         productTypes,
         renderAttributes,
         productId,
         image,
      ]
   )

   return (
      <PageContent>
         <PageTitle
            withIcons={!productId}
            type='content'
            title={t('blockTitle.general')}
         />
         <Box sx={{ width: '100%', paddingTop: '8px' }}>
            {productLoading ? <CircleLoading /> : renderForm}
         </Box>
      </PageContent>
   )
}

export default ProductForm
