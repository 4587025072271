import { useAppDispatch } from '../../redux'
import { APIReturnData, useFetchingHandler } from '../use-fetch-handlers'
import useSWR, { SWRConfiguration } from 'swr'
import { useToken } from './use-token'
import { Maybe } from '../../types'

type UseQueryParams = {
   url: Maybe<string>
   fetchOptions?: SWRConfiguration
   secured?: boolean
}

export const useQuery = <T>({ url, fetchOptions, secured }: UseQueryParams) => {
   const { userToken, loading } = useToken()
   const dispatch = useAppDispatch()
   const { getHandler } = useFetchingHandler({ dispatch })

   const { data, isLoading, isValidating, mutate, error } = useSWR<
      APIReturnData<T>
   >(
      secured ? (userToken ? url : null) : url,
      (url: string) => getHandler<T>({ url, secured }),
      {
         ...fetchOptions,
         revalidateIfStale: true,
         revalidateOnFocus: true,
      }
   )

   return {
      isLoading: isLoading || loading,
      data,
      isValidating,
      error,
      mutate,
   }
}
