import { FC } from 'react'
import { IconButton, ButtonBaseProps } from '@mui/material'
import Icon from '../Icon'
import { ReactComponent as ArrowLeftIcon } from '../../assets/icons/arrow-left.svg'
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrow-right.svg'
import { colorPalette } from '../../config'

type NavIconButtonProps = ButtonBaseProps & {
   dir: 'left' | 'right'
   variant: 'outlined' | 'text' | 'contained'
   size: 'lg' | 'sm'
   style?: React.CSSProperties
   component?: React.ReactNode
   to?: string
   children?: React.ReactNode
}

const styledButton = (Component: any) => {
   return (props: NavIconButtonProps) => {
      const { dir = 'left', variant, size, style } = props
      const sizeStyles = {
         lg: {
            width: '56px',
            height: '56px',
         },
         sm: {
            width: '40px',
            height: '40px',
         },
      }

      const variantStyles = {
         outlined: {
            backgroundColor: colorPalette.white,
            border: `1px solid ${colorPalette.stroke}`,
         },
         text: {
            backgroundColor: colorPalette.white,
            border: 'none',
         },
         contained: {
            backgroundColor: colorPalette.primary,
            border: 'none',
         },
      }

      const btnProps = {
         sx: {
            ...variantStyles[variant],
            ...sizeStyles[size],
            ...style,
         },
         ...props,
      }

      return (
         <Component {...btnProps}>
            <Icon
               type='fill'
               src={dir === 'right' ? ArrowRightIcon : ArrowLeftIcon}
               color={
                  variant === 'contained'
                     ? colorPalette.white
                     : colorPalette.primary
               }
            />
         </Component>
      )
   }
}

const NavIconButton = styledButton(IconButton)

export default NavIconButton
