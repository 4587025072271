import { FC, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Icon, PopupNoti } from '../../components'
import Table, {
   TableRowType,
   HeadCell,
   FilterType,
   TableCellDataType,
} from '../../components/Table'
import { PageHeader, PageContent, PageTitle, PageWrapper } from '../../layout'
import { useAppSelector, selectFetch, useAppDispatch } from '../../redux'
import { ReactComponent as PenIcon } from '../../assets/icons/pen.svg'
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg'
import { colorPalette } from '../../config'
import { BrandData } from '../../types'
import { getAgentStatus } from '../../utils'
import { useTranslation } from 'react-i18next'
import useFilteredQuery from '../../hooks/use-swr/use-query-with-filter'
import { ReactComponent as BinIcon } from '../../assets/icons/bin.svg'
import { deleteBrand } from '../../redux/cms'
import { warningMsg } from '../../schemas'

const BrandsTable: FC = () => {
   // const { brandLoading } = useAppSelector(selectBrand)
   const { uploading } = useAppSelector(selectFetch)
   const dispatch = useAppDispatch()
   const { t } = useTranslation(['common', 'inputs', 'components'])

   const {
      controlMethods: { handleAddSearchCriteria, handleRemoveSearchCriteria },
      response: { isLoading, isValidating, data: brands, mutate },
   } = useFilteredQuery<BrandData[]>({ url: 'sales-agent' })
   const [selectedBrand, setSelectedBrand] = useState<string>('')
   const [openAlert, setOpenAlert] = useState<boolean>(false)

   const headCells: HeadCell[] = [
      {
         isId: true,
         id: '_id',
         numeric: false,
         disablePadding: false,
         filterType: FilterType.TEXT,
         label: '',
      },
      {
         id: 'thumbnail',
         numeric: false,
         disablePadding: false,
         filterType: FilterType.NONE,
         label: 'Ảnh',
      },
      {
         id: 'name',
         numeric: false,
         disablePadding: false,
         filterType: FilterType.TEXT,
         label: 'Tên',
      },
      {
         id: 'address',
         numeric: false,
         disablePadding: false,
         filterType: FilterType.NONE,
         label: 'Địa chỉ',
      },
      {
         id: 'status',
         numeric: false,
         disablePadding: false,
         filterType: FilterType.OPTION,
         label: t('common.status', { ns: 'rows' }),
      },
   ]

   const handleOnClick = (userId: string) => {
      setSelectedBrand(userId)
      setOpenAlert(true)
   }

   const handleRevoke = () => {
      if (selectedBrand) {
         dispatch(deleteBrand(selectedBrand))
      }
   }

   const handleOnSearch = (searchTerm: string, type: string) => {
      if (!searchTerm) {
         return handleRemoveSearchCriteria(type)
      }

      return handleAddSearchCriteria(type, searchTerm)
   }

   const rows: TableRowType[] = useMemo(
      () =>
         brands
            ? brands.map((brand) => {
                 return {
                    key: brand._id,
                    cells: [
                       {
                          identifier: '_id',
                          type: TableCellDataType.ID,
                          data: brand._id,
                       },
                       {
                          identifier: 'thumbnail',
                          type: TableCellDataType.IMAGE,
                          data: brand.thumbnail,
                       },
                       {
                          identifier: 'name',
                          type: TableCellDataType.TEXT,
                          data: brand.name,
                       },
                       {
                          identifier: 'adress',
                          type: TableCellDataType.TEXT,
                          data: brand.address,
                       },
                       {
                          identifier: 'status',
                          type: TableCellDataType.TAG,
                          data: getAgentStatus(brand.status),
                          tagColorMap: [
                             {
                                value: 'Đã duyệt',
                                color: 'success',
                             },
                             {
                                value: 'Đang xử lý',
                                color: 'warning',
                             },
                             {
                                value: 'Không duyệt',
                                color: 'error',
                             },
                          ],
                       },
                       //   {
                       //      identifier: 'updated_at',
                       //      type: TableCellDataType.DATE,
                       //      data: brand.updated_at || brand.created_at,
                       //   },
                       {
                          identifier: 'action',
                          type: TableCellDataType.ACTION,
                          data: [
                             {
                                icon: {
                                   src: PenIcon,
                                   color: colorPalette.grey,
                                   tooltip: t('tooltip.common.edit', {
                                      ns: 'components',
                                   }) as string,
                                },
                                link: `/brands/${brand._id}`,
                             },
                             {
                                icon: {
                                   src: BinIcon,
                                   color: colorPalette.red.shade_500,
                                   tooltip: 'Xoá',
                                },
                                handleOnClick: () => handleOnClick(brand._id),
                             },
                          ],
                       },
                    ],
                 }
              })
            : [],
      [brands]
   )

   useEffect(() => {
      if (!uploading && selectedBrand) {
         mutate({
            data: brands?.filter((brand) => brand._id !== selectedBrand),
            statusCode: 200,
         })
         setSelectedBrand('')
      }
   }, [uploading])

   return (
      <>
         <PopupNoti
            open={openAlert}
            setOpen={setOpenAlert}
            message={warningMsg}
            action={handleRevoke}
         />
         <PageWrapper>
            <PageHeader>
               <PageTitle type='header' title={'Đại lý'} />
               <Button
                  customsize='sm'
                  variant='contained'
                  endIcon={
                     <Icon
                        type='fill'
                        src={PlusIcon}
                        color={colorPalette.white}
                        width={15}
                        height={15}
                     />
                  }
                  style={{ marginLeft: '5px' }}
                  component={Link as any}
                  to='create'
               >
                  Thêm đại lý
               </Button>
            </PageHeader>
            <PageContent>
               <Table
                  loading={isLoading || isValidating}
                  initialSort='status'
                  initialSearchTermType='name'
                  title={'Danh sách đại lý'}
                  headCells={headCells}
                  rows={rows}
                  withAction
                  onSearchTermChange={handleOnSearch}
               />
            </PageContent>
         </PageWrapper>
      </>
   )
}

export default BrandsTable
