import { FC } from 'react'
import { FlashSaleMutation, PromotionProgramForm } from '../../../components'
import { createPromotionProgram, useAppDispatch } from '../../../redux'

const defaultValues = {
   flash_sale_name: '',
   start_at: new Date().valueOf(), //date
   end_at: new Date().valueOf(), //date
   list_products: [],
}

const PromotionProgramCreate: FC = () => {
   const dispatch = useAppDispatch()

   const handleOnSubmit = (payload: FlashSaleMutation) => {
      dispatch(createPromotionProgram(payload))
   }

   return (
      <PromotionProgramForm
         defaultValues={defaultValues}
         handleOnSubmit={handleOnSubmit}
      />
   )
}

export default PromotionProgramCreate
