import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getAllImages, uploadImage } from './mediaThunk'
import { ImageData } from '../../types'

export type ToUploadImages = {
   variantIdentifier: string
   image_ids: string[]
}

interface Mediatate {
   mediaLoading: boolean
   images: ImageData[]
   toUploadImages: ToUploadImages[]
}

const initialState: Mediatate = {
   mediaLoading: false,
   toUploadImages: [],
   images: [],
}

export const mediaSlice = createSlice({
   name: 'mediaState',
   initialState,
   reducers: {
      addImgToPending: (
         state,
         action: PayloadAction<ToUploadImages, string>
      ) => {
         const currentVariant = state.toUploadImages.find(
            ({ variantIdentifier }) =>
               variantIdentifier === action.payload.variantIdentifier
         )

         state.toUploadImages = currentVariant
            ? [
                 ...state.toUploadImages.filter(
                    ({ variantIdentifier }) =>
                       variantIdentifier !== action.payload.variantIdentifier
                 ),
                 action.payload,
              ]
            : [...state.toUploadImages, action.payload]
      },
      resetPendingImgs: (state) => {
         state.toUploadImages = []
      },
      setImages: (state, action) => {
         state.images = action.payload
      }
   },
   extraReducers: (builder) => {
      builder
         .addCase(uploadImage.pending, (state) => {
            state.mediaLoading = true
         })
         .addCase(uploadImage.fulfilled, (state, action: any) => {
            state.mediaLoading = false
         })
         .addCase(uploadImage.rejected, (state, action: any) => {
            state.mediaLoading = false
         })
   },
})

export const { addImgToPending, resetPendingImgs, setImages } = mediaSlice.actions

export default mediaSlice.reducer
