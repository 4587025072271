import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import commonEnTrans from './locales/en/common.json'
import commonViTrans from './locales/vi/common.json'
import inputsEnTrans from './locales/en/inputs.json'
import inputsViTrans from './locales/vi/inputs.json'
import componentsEnTrans from './locales/en/components.json'
import componentsViTrans from './locales/vi/components.json'
import rowsEnTrans from './locales/en/rows.json'
import rowsViTrans from './locales/vi/rows.json'

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'vi',
        debug: true,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                common: commonEnTrans,
                inputs: inputsEnTrans,
                components: componentsEnTrans,
                rows: rowsEnTrans,
            },
            vi: {
                common: commonViTrans,
                inputs: inputsViTrans,
                components: componentsViTrans,
                rows: rowsViTrans
            }
        }
    });


export default i18n;