import * as yup from 'yup'
import { requiredMsg } from './common'

export const metaDataValueSchema = yup.object().shape({
   name: yup.string().required(requiredMsg),
})

export const metaDataSchema = yup.object().shape({
   slug: yup.string(),
   title: yup.string().required(requiredMsg),
   values: yup.array().of(metaDataValueSchema),
})

export const productCreateFormSchema = yup
   .object()
   .shape({
      _id: yup.string(),
      product_name: yup.string().required(requiredMsg),
      product_type: yup.string().required(requiredMsg),
      price: yup.string().required(requiredMsg),
      image: yup.array().of(yup.string()).required(requiredMsg),
      product_metadata: yup.array().of(metaDataSchema),
      uid: yup.string(),
      slug: yup.string(),
      product_instruction: yup.string().required(requiredMsg),
      product_description: yup.string().required(requiredMsg),
      product_short_description: yup.string(),
   })
   .required()

export const productTypePrototypeSchema = yup.object().shape({
   slug: yup.string().required(requiredMsg),
   title: yup.string().required(requiredMsg),
})

export const productTypeCreateFormSchema = yup.object().shape({
   product_type_name: yup.string().required(requiredMsg),
})

export const updateProductImagesSchema = yup.object().shape({
   images: yup.array().of(yup.string().required(requiredMsg)),
})

export const createVariantSchema = yup.object().shape({
   product_name: yup.string().required(requiredMsg),
   price: yup.string().required(requiredMsg),
   image: yup.array().of(yup.string()),
})
