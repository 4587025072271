import { FC, useEffect, useState } from 'react'
import {
   PopupNoti,
   PromotionProgramsTable as ProgramsTable,
} from '../../../components'
import { useTitle, OutletTitle, FormName } from '../Promotion'
import { useTranslation } from 'react-i18next'
import { ReactComponent as VoucherIcon } from '../../../assets/icons/voucher.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/icons/bin.svg'
import { colorPalette } from '../../../config'
import { useNavigate } from 'react-router-dom'
import { deletePromotionProgram, selectFetch, useAppDispatch, useAppSelector } from '../../../redux'
import { warningMsg } from '../../../schemas'

const PromotionProgramsTable: FC = () => {
   const { setTitle, setFormName } = useTitle()
   const { uploading } = useAppSelector(selectFetch)
   const dispatch = useAppDispatch()
   const { t } = useTranslation(['common', 'rows', 'components'])
   const navigate = useNavigate()
   const [openAlert, setOpenAlert] = useState<boolean>(false)
   const [selectedId, setSelectedId] = useState<string>('')

   const handleOnDeleteFlashSale = (id: string) => {
      setSelectedId(id)
      setOpenAlert(true)
   }

   const handleOnAction = () => {
      dispatch(deletePromotionProgram(selectedId))
   }

   const actions = (program_id: string) => {
      return [
         {
            icon: {
               src: VoucherIcon,
               color: colorPalette.dark,
               tooltip: 'Xem chi tiết',
            },
            handleOnClick: () => navigate(`/promotion-program/${program_id}`),
         },
         {
            icon: {
               src: DeleteIcon,
               color: colorPalette.red.shade_500,
               tooltip: 'Xoá',
            },
            handleOnClick: () => handleOnDeleteFlashSale(program_id),
         },
      ]
   }

   useEffect(() => {
      setTitle(t(`pageTitle.${OutletTitle.PROGRAMS}`) || '')
      setFormName(FormName.PROGRAM)
   }, [uploading])

   return (
      <>
         <PopupNoti
            open={openAlert}
            setOpen={setOpenAlert}
            message={warningMsg}
            action={handleOnAction}
         />
         <ProgramsTable showActions actions={actions} />
      </>
   )
}

export default PromotionProgramsTable
