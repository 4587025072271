import * as yup from 'yup'
import { requiredMsg } from './common'
import { isValidUrl, validateEmail, validatePhoneNumber } from '../utils/validation'

export const videoCreateSchema = yup
   .object({
      title: yup.string().required(requiredMsg),
      description: yup.string().required(requiredMsg),
      urlVideoIntro: yup
         .string()
         .test(
            'contains-text', // Name of the test
            'Không đúng định dạng video, phải là link embed', // Error message
            (value) =>
               Boolean(value && value.includes('https://www.youtube.com/embed')) // Validation logic
         )
         .required(requiredMsg),
   })
   .required()

export const softwareCreateSchema = yup
   .object({
      title: yup.string().required(requiredMsg),
      thumbnail: yup.string().required(requiredMsg),
      description: yup.string().required(requiredMsg),
      downloadLink: yup
         .string()
         .test(
            'contains-text', // Name of the test
            'Không đúng định dạng url, VD: https://www.example.com', // Error message
            (value) => Boolean(value && isValidUrl(value)) // Validation logic
         )
         .required(requiredMsg),
   })
   .required()

export const setupPostCreateSchema = yup
   .object({
      title: yup.string().required(requiredMsg),
      thumbnail: yup.string().required(requiredMsg),
      content: yup.string().required(requiredMsg),
      description: yup.string().required(requiredMsg),
      url: yup
         .string()
         .test(
            'contains-text', // Name of the test
            'Không đúng định dạng url, VD: https://www.example.com', // Error message
            (value) => Boolean(value && isValidUrl(value)) // Validation logic
         )
         .required(requiredMsg),
   })
   .required()

export const updateBrandSchema = yup
   .object({
      name: yup.string().required(requiredMsg),
      thumbnail: yup.string().required(requiredMsg),
      description: yup.string().required(requiredMsg),
      phone: yup
         .string()
         .test(
            'is_valid', // Name of the test
            'Số điện thoại không họpe lệ', // Error message
            (value) => Boolean(value && validatePhoneNumber(value)) // Validation logic
         )
         .required(requiredMsg),
      email: yup
         .string()
         .test(
            'is_valid', // Name of the test
            'Email không họpe lệ', // Error message
            (value) => Boolean(value && validateEmail(value)) // Validation logic
         )
         .required(requiredMsg),
      address: yup.string().required(requiredMsg),
   })
   .required()
