import { Box, Grid, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { ChangeEvent, FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ProductData, WrappedProductData } from '../../types'
import { useProducts } from '../../hooks/use-redux/use-products'
import { promotionProgramCreateSchema } from '../../schemas'
import Table, {
   ActionData,
   FilterType,
   TableCellDataType,
   TableRowType,
} from '../Table'
import { ReactComponent as BinIcon } from '../../assets/icons/bin.svg'
import {
   isFlashSaleValid,
   transferStringToNumber,
   transformNumberInput,
   transformNumberOutput,
} from '../../utils/validation'
import Input from '../Input'
import { colorPalette, typography } from '../../config'
import Button from '../Button'
import NavIconButton from '../NavIconButton'
import { PageContent, PageHeader, PageWrapper } from '../../layout'
import DateTimePicker from '../DateTimePicker'
import { CircleLoading } from '../Loading'
import PopupModal from '../PopupModal'
import ProductsTable from '../ProductsTable'
import { getDate } from '../../utils'
import useFilteredQuery from '../../hooks/use-swr/use-query-with-filter'

export type FlashSaleListProduct = ProductData & {
   price_sale: string
   percentage: string
}

export type FlashSaleProduct = {
   _id: string
   price_sale: number
   percentage: number
}

export type FlashSaleMutation = {
   flash_sale_name: string
   start_at: number
   end_at: number
   list_products: FlashSaleProduct[]
}

type FlashSaleFormValues = {
   flash_sale_name: string
   start_at: number
   end_at: number
   list_products: FlashSaleListProduct[]
}

type PromotionProgramFormProps = {
   defaultValues: FlashSaleFormValues
   handleOnSubmit: (payload: FlashSaleMutation) => void
   createdAt?: number
}

const PromotionProgramForm: FC<PromotionProgramFormProps> = ({
   handleOnSubmit,
   defaultValues,
   createdAt,
}) => {
   const { t } = useTranslation(['common', 'components', 'inputs'])
   const { productTypes, isLoading } = useProducts()
   const {
      filter,
      controlMethods,
      response: { isLoading: productLoading, data: products, paginationInfo },
   } = useFilteredQuery<WrappedProductData[]>({ url: 'product' })

   const [openModal, setOpenModal] = useState<boolean>(false)
   const [selectedIds, setSelectedIds] = useState<string[]>([])
   const [inProgramIds, setInProgramIds] = useState<string[]>(
      defaultValues.list_products.map((product) => product._id || '')
   )

   const createdDate = createdAt ? getDate(Number(createdAt)) : null

   const restProductsToShow = useMemo(
      () =>
         products
            ?.filter(
               ({ product }) =>
                  !inProgramIds.includes(product._id || '') &&
                  product.price > 0 &&
                  !isFlashSaleValid(product.flash_sale)
            )
            .map((product) => product.product),
      [inProgramIds, products]
   )
   // const inProgramProducts = useMemo(
   //    () =>
   //       products?.filter(({ product }) =>
   //          inProgramIds.includes(product._id || '')
   //       ),
   //    [inProgramIds, products]
   // )

   const {
      formState: { isDirty, errors },
      handleSubmit,
      setValue,
      setError,
      clearErrors,
      watch,
      control,
   } = useForm<FlashSaleFormValues>({
      defaultValues,
      shouldFocusError: true,
      resolver: yupResolver(promotionProgramCreateSchema),
   })

   const { fields, remove } = useFieldArray({
      control,
      name: 'list_products',
   })

   const [start_at] = watch(['start_at'])

   const handleOnSelected = (rows: TableRowType[], selectedRows: string[]) => {
      const productIds = selectedRows.map((key) => {
         const selectedRow = rows.find((row) => row.key === key)
         const selectedCell = selectedRow?.cells.find(
            (cell) => cell.identifier === '_id'
         )

         return String(selectedCell?.data)
      })
      setSelectedIds(productIds)
   }

   const handleAddProductToProgram = (selectedIds: string[]) => {
      setInProgramIds(selectedIds)
      if (products) {
         setValue('list_products', [
            ...fields,
            ...products
               .map(({ product }) => product)
               ?.filter((product) => selectedIds.includes(product._id || ''))
               .map((product) => ({
                  ...product,
                  price_sale: '0',
                  percentage: '0',
               })),
         ])
      }
   }

   const handleOnPopupActionClick = () => {
      handleAddProductToProgram(selectedIds)
      setOpenModal(false)
      setSelectedIds([])
   }

   const handleOnDelete = () => {
      setInProgramIds((prevIds) =>
         prevIds.filter((prevId) => !selectedIds.includes(prevId))
      )
   }

   const handleOnPriceSaleChange = (
      e: ChangeEvent<HTMLInputElement>,
      onChange: (...event: any[]) => void,
      product: ProductData,
      idx: number
   ) => {
      const curPrice = transformNumberOutput(e)
      const intCurPrice = transferStringToNumber(curPrice)

      if (intCurPrice > product.price) {
         setError(`list_products.${idx}.price_sale`, {
            type: 'custom',
            message: 'Giá sale không được lớn hơn giá sản phẩm',
         })
         return
      }

      clearErrors(`list_products.${idx}.price_sale`)
      onChange(curPrice)
      const percentage = ((product.price - intCurPrice) / product.price) * 100
      setValue(
         `list_products.${idx}.percentage`,
         percentage.toFixed(2).toString()
      )
   }

   const handleOnPercentageChange = (
      e: ChangeEvent<HTMLInputElement>,
      onChange: (...event: any[]) => void,
      product: ProductData,
      idx: number
   ) => {
      const curPercentage = transformNumberOutput(e)
      const intCurPercentage = transferStringToNumber(curPercentage)

      if (intCurPercentage > 100) {
         setError(`list_products.${idx}.percentage`, {
            type: 'custom',
            message: 'Không được vượt quá 100%',
         })
         return
      }

      onChange(curPercentage)
      clearErrors(`list_products.${idx}.percentage`)
      const priceSale = product.price - (product.price * intCurPercentage) / 100
      setValue(`list_products.${idx}.price_sale`, priceSale.toString())
   }

   const validate = (values: FlashSaleFormValues) => {
      let hasError = false
      const { list_products } = values
      list_products.forEach((product, idx) => {
         if (transferStringToNumber(product.price_sale) > product.price) {
            setError(`list_products.${idx}.price_sale`, {
               type: 'custom',
               message: 'Giá sale không được lớn hơn giá sản phẩm',
            })
            hasError = true
         }
      })

      return hasError
   }

   const onSubmit = (values: FlashSaleFormValues) => {
      const hasError = validate(values)

      if (!hasError) {
         const { flash_sale_name, start_at, end_at, list_products } = values
         const payload = {
            flash_sale_name,
            start_at,
            end_at,
            list_products: list_products.map((product) => {
               return {
                  _id: product._id || '',
                  uid: product.uid,
                  price_sale: transferStringToNumber(product.price_sale),
                  percentage: transferStringToNumber(product.percentage),
               }
            }),
         }
         handleOnSubmit(payload)
      }
   }

   const headCells = useMemo(
      () => [
         {
            isId: true,
            id: '_id',
            numeric: false,
            disablePadding: false,
            filterType: FilterType.TEXT,
            label: 'ID',
         },
         {
            id: 'image',
            numeric: false,
            disablePadding: false,
            filterType: FilterType.NONE,
            label: t('product.image', { ns: 'rows' }),
         },
         {
            id: 'product_name',
            numeric: false,
            disablePadding: false,
            filterType: FilterType.TEXT,
            label: t('product.name', { ns: 'rows' }),
         },
         {
            id: 'product_type',
            numeric: false,
            disablePadding: false,
            filterType: FilterType.TEXT,
            label: t('product.type', { ns: 'rows' }),
         },
         {
            id: 'updated_at',
            numeric: false,
            disablePadding: false,
            filterType: FilterType.DATE,
            label: t('common.updatedAt', { ns: 'rows' }),
         },
         {
            id: 'price',
            numeric: true,
            disablePadding: false,
            filterType: FilterType.RANGE,
            label: t('common.price', { ns: 'rows' }),
         },
         {
            id: 'sale_price_percent',
            numeric: false,
            disablePadding: false,
            filterType: FilterType.RANGE,
            label: '% giảm',
         },
         {
            id: 'sale_price_amount',
            numeric: false,
            disablePadding: false,
            filterType: FilterType.RANGE,
            label: 'Giá sale',
         },
      ],
      [t]
   )
   const rows: TableRowType[] = useMemo(
      () =>
         fields && fields.length > 0 && productTypes
            ? fields.map((product, idx) => {
                 const productType = productTypes.find(
                    (type) => type._id === product.product_type
                 )

                 return {
                    key: product._id || `${idx}`,
                    cells: [
                       {
                          identifier: '_id',
                          type: TableCellDataType.ID,
                          data: product?._id || `${idx}`,
                       },
                       {
                          identifier: 'image',
                          type: TableCellDataType.IMAGE,
                          data: product?.image?.[0] || '',
                       },
                       {
                          identifier: 'product_name',
                          type: TableCellDataType.TEXT,
                          data: product.product_name,
                       },
                       {
                          identifier: 'product_type',
                          type: TableCellDataType.TEXT,
                          data:
                             productType?.product_type_name || 'Invalid type',
                       },
                       {
                          identifier: 'updated_at',
                          type: TableCellDataType.DATE,
                          data:
                             product.updated_at ||
                             product.created_at ||
                             new Date(),
                       },
                       {
                          identifier: 'price',
                          type: TableCellDataType.UNIT,
                          data: product.price,
                          unit: 'đ',
                       },
                       {
                          identifier: 'sale_price_percent',
                          type: TableCellDataType.CUSTOM,
                          data: (
                             <Controller
                                name={`list_products.${idx}.percentage`}
                                control={control}
                                rules={{ required: true }}
                                render={({
                                   field: { value, onChange, ...field },
                                }) => (
                                   <Input
                                      value={transformNumberInput(
                                         value.toString()
                                      )}
                                      onChange={(
                                         e: ChangeEvent<HTMLInputElement>
                                      ) =>
                                         handleOnPercentageChange(
                                            e,
                                            onChange,
                                            product,
                                            idx
                                         )
                                      }
                                      label='Giá %'
                                      fullWidth
                                      type='text'
                                      error={
                                         !!errors?.list_products?.[idx]
                                            ?.percentage
                                      }
                                      helperText={
                                         errors?.list_products?.[idx]
                                            ?.percentage?.message
                                      }
                                      {...field}
                                      sx={{ minWidth: '100px' }}
                                   />
                                )}
                             />
                          ),
                       },
                       {
                          identifier: 'sale_price_amount',
                          type: TableCellDataType.CUSTOM,
                          data: (
                             <Controller
                                //   disabled
                                name={`list_products.${idx}.price_sale`}
                                control={control}
                                defaultValue={product.price.toString()}
                                rules={{ required: true }}
                                render={({
                                   field: {
                                      value,
                                      onChange,
                                      disabled,
                                      ...field
                                   },
                                }) => (
                                   <Input
                                      disabled
                                      value={transformNumberInput(
                                         value.toString()
                                      )}
                                      onChange={(
                                         e: ChangeEvent<HTMLInputElement>
                                      ) =>
                                         handleOnPriceSaleChange(
                                            e,
                                            onChange,
                                            product,
                                            idx
                                         )
                                      }
                                      label='đ'
                                      fullWidth
                                      type='text'
                                      error={
                                         !!errors?.list_products?.[idx]
                                            ?.price_sale
                                      }
                                      helperText={
                                         errors?.list_products?.[idx]
                                            ?.price_sale?.message
                                      }
                                      {...field}
                                      sx={{ minWidth: '100px' }}
                                   />
                                )}
                             />
                          ),
                       },
                       {
                          identifier: 'action',
                          type: TableCellDataType.ACTION,
                          data: [
                             {
                                icon: {
                                   src: BinIcon,
                                   color: colorPalette.red.shade_500,
                                   tooltip: t('tooltip.common.delete', {
                                      ns: 'components',
                                   }),
                                },
                                handleOnClick: () => {
                                   setInProgramIds((prevIds) =>
                                      prevIds.filter(
                                         (prevId) => prevId !== product._id
                                      )
                                   )
                                   remove(idx)
                                },
                             },
                          ] as ActionData[],
                       },
                    ],
                 }
              })
            : [],
      [fields, productTypes, control, t, errors?.list_products]
   )

   const renderTable = useMemo(
      () =>
         productTypes ? (
            <Table
               title={t('tableTitle.product')}
               headCells={headCells}
               rows={rows}
               withAction
               onMultipleDelete={handleOnDelete}
               initialSort='product_name'
               initialSearchTermType='_id'
               onSelected={handleOnSelected}
            />
         ) : null,
      [productTypes, t, headCells, rows]
   )

   return (
      <form onSubmit={handleSubmit(onSubmit)}>
         <PageWrapper>
            <PageHeader>
               <Box sx={{ display: 'flex' }}>
                  <NavIconButton
                     dir='left'
                     variant='text'
                     size='sm'
                     component={Link as any}
                     to='/promotion/programs'
                     style={{ marginRight: '16px' }}
                  />
                  <Typography
                     className={typography.pc.h6}
                     color={colorPalette.dark}
                     component='div'
                  >
                     {defaultValues.flash_sale_name
                        ? `Chương trình ${defaultValues.flash_sale_name}`
                        : 'Tạo chương trình khuyến mãi'}
                  </Typography>
               </Box>
               <Stack direction='row' alignItems='center'>
                  {createdDate ? (
                     <Typography
                        className={typography.pc.helpSemi}
                        color={colorPalette.lightGrey}
                        textTransform='none'
                        sx={{ marginRight: '8px' }}
                        fontStyle='italic'
                     >
                        <strong>{t('common.createdAt', { ns: 'rows' })}</strong>{' '}
                        {createdDate.time} - {createdDate.day}
                     </Typography>
                  ) : null}
                  <Button
                     customsize='sm'
                     variant='contained'
                     type='button'
                     onClick={() => setOpenModal(true)}
                     sx={{ marginRight: '8px' }}
                  >
                     Chọn sản phẩm
                  </Button>
                  <Button
                     disabled={!isDirty}
                     customsize='sm'
                     variant='contained'
                     type='submit'
                     // onClick={handleOnSubmit}
                  >
                     {t('buttons.common.save', { ns: 'components' })}
                  </Button>
               </Stack>
            </PageHeader>
            <PageContent>
               <Grid container spacing={2}>
                  <Grid item xs={12}>
                     <Grid container spacing={2}>
                        <Grid item xs={12}>
                           <Controller
                              name='flash_sale_name'
                              control={control}
                              rules={{ required: true }}
                              render={({ field }) => (
                                 <Input
                                    label='Tên chương trình'
                                    fullWidth
                                    placeholder='Nhập tên chương trình'
                                    type='text'
                                    error={!!errors.flash_sale_name}
                                    helperText={
                                       errors?.flash_sale_name
                                          ?.message as string
                                    }
                                    {...field}
                                 />
                              )}
                           />
                        </Grid>
                        <Grid item xs={12} md={6}>
                           <Controller
                              name='start_at'
                              control={control}
                              rules={{ required: true }}
                              render={({ field }) => {
                                 const handleChange = (value: Date) => {
                                    const date = new Date(value)
                                    setValue('start_at', date.valueOf(), {
                                       shouldDirty: true,
                                    })
                                 }

                                 return (
                                    <DateTimePicker
                                       {...field}
                                       value={field.value}
                                       error={!!errors.start_at}
                                       disablePast
                                       helperText={
                                          errors?.start_at?.message as string
                                       }
                                       label='Ngày bắt đầu'
                                       onChange={handleChange}
                                    />
                                 )
                              }}
                           />
                        </Grid>
                        <Grid item xs={12} md={6}>
                           <Controller
                              name='end_at'
                              control={control}
                              rules={{ required: true }}
                              render={({ field }) => {
                                 const handleChange = (value: Date) => {
                                    const date = new Date(value)
                                    setValue('end_at', date.valueOf(), {
                                       shouldDirty: true,
                                    })
                                 }

                                 return (
                                    <DateTimePicker
                                       {...field}
                                       value={field.value}
                                       error={!!errors.end_at}
                                       disablePast
                                       minDate={start_at}
                                       helperText={
                                          errors?.end_at?.message as string
                                       }
                                       label='Ngày kết thúc'
                                       onChange={handleChange}
                                    />
                                 )
                              }}
                           />
                        </Grid>
                     </Grid>
                  </Grid>
                  <Grid item xs={12}>
                     {isLoading ? <CircleLoading /> : renderTable}
                  </Grid>
               </Grid>
            </PageContent>
         </PageWrapper>
         {isLoading ? null : (
            <PopupModal
               title={t('formTitle.addToProgram')}
               open={openModal}
               onClose={() => setOpenModal(false)}
               maxWidth={'80vw'}
               minWidth={'80vw'}
               footer={
                  <Button
                     disabled={selectedIds.length < 1}
                     customsize='sm'
                     variant='contained'
                     onClick={handleOnPopupActionClick}
                  >
                     {t('buttons.common.add', { ns: 'components' })}
                  </Button>
               }
            >
               <Box
                  sx={{
                     padding: '16px',
                     maxHeight: '80vh',
                     overflow: 'scroll',
                  }}
               >
                  <ProductsTable
                     paginationInfo={paginationInfo}
                     isLoading={isLoading || productLoading}
                     productTypes={productTypes}
                     productsToShow={restProductsToShow}
                     onSelected={handleOnSelected}
                     filter={filter}
                     filterMethods={controlMethods}
                  />
               </Box>
            </PopupModal>
         )}
      </form>
   )
}

export default PromotionProgramForm
