import React, { FC, useMemo, memo, useState, useEffect } from 'react'
import {
   Typography,
   Tooltip,
   IconButton,
   Stack,
   Slide,
   Box,
   Grid,
} from '@mui/material'
import SearchBox from '../SearchBox'
import { Select, SelectOption } from '../Select'
import { TableToolbar } from '.'
import { colorPalette, typography } from '../../config'
import { MultipleSelectedIcon, SearchTermType } from './Table'
import { useDebounce } from '../../hooks/use-debounce'
import { useTranslation } from 'react-i18next'
import Icon from '../Icon'
import { ReactComponent as BinIcon } from '../../assets/icons/bin.svg'
import DateTimePicker from '../DateTimePicker'
import { ReactComponent as ClockIcon } from '../../assets/icons/clock.svg'
import { Maybe } from '../../types'

interface EnhancedTableToolbarProps {
   numSelected: number
   title: string
   initialSearchTermType: string
   searchTermType: string
   searchTermTypes: SearchTermType[]
   setSearchTermType: (type: string) => void
   onSearch?: (searchTerm: string) => void
   onMultipleDelete?: () => void
   onStartDateChange?: (eporch: Maybe<number>) => void
   onEndDateChange?: (eporch: Maybe<number>) => void
   disabledFuture?: boolean
   disabledPast?: boolean
   multipleSelectedIcons?: MultipleSelectedIcon[]
}

const EnhancedTableToolbar: FC<EnhancedTableToolbarProps> = ({
   numSelected,
   title,
   searchTermTypes,
   searchTermType,
   disabledFuture,
   disabledPast,
   initialSearchTermType,
   setSearchTermType,
   onSearch,
   onEndDateChange,
   onStartDateChange,
   onMultipleDelete,
   multipleSelectedIcons,
}) => {
   const { t } = useTranslation(['components'])

   const [open, setOpen] = useState<boolean>(false)
   const [searchTerm, setSearchTerm] = useState<string>('')
   const [startDate, setStartDate] = useState<Date>(new Date())
   const [endDate, setEndDate] = useState<Date>(new Date())

   const debouncedSearchTerm = useDebounce(searchTerm, 500)
   const isSelected = useMemo(() => numSelected > 0, [numSelected])

   const handleChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
   ) => {
      setSearchTerm(event.target.value)
   }

   const handleOnStartDateChange = (value: Date) => {
      setStartDate(value)
      if (value.valueOf() > endDate.valueOf()) {
         setEndDate(value)
      }
      if (onStartDateChange) onStartDateChange(value.valueOf())
   }

   const handleOnEndDateChange = (value: Date) => {
      setEndDate(value)
      if (onEndDateChange) onEndDateChange(value.valueOf())
   }

   const handleOnTimeClick = () =>
      setOpen((prevOpen) => {
         if (prevOpen && onEndDateChange && onStartDateChange) {
            onEndDateChange(null)
            onStartDateChange(null)
         }

         return !prevOpen
      })

   useEffect(() => {
      if (onSearch) {
         onSearch(debouncedSearchTerm)
      }
   }, [debouncedSearchTerm])

   return (
      <>
         <TableToolbar selected={isSelected}>
            {isSelected ? (
               <Typography
                  sx={{ flex: '1 1 100%', ml: 2 }}
                  color={colorPalette.dark}
                  component='div'
                  className={typography.mb.descSemi}
               >
                  {numSelected} {t('table.selected')}
               </Typography>
            ) : (
               <Typography
                  sx={{ flex: '1 1 50%' }}
                  color={colorPalette.dark}
                  id='table'
                  component='div'
                  className={typography.mb.h5}
                  textTransform='uppercase'
               >
                  {title}
               </Typography>
            )}
            {isSelected ? (
               <Stack direction='row' alignItems='center'>
                  {onMultipleDelete && (
                     <Tooltip title={t('tooltip.common.delete')}>
                        <IconButton
                           sx={{ mr: 2 }}
                           onClick={() => onMultipleDelete()}
                        >
                           <Icon
                              type='fill'
                              color={colorPalette.red.shade_500}
                              src={BinIcon}
                           />
                        </IconButton>
                     </Tooltip>
                  )}
                  {multipleSelectedIcons &&
                     multipleSelectedIcons.map(
                        ({ src, tooltip, handleOnClick }, idx) => (
                           <Tooltip title={tooltip} key={idx}>
                              <IconButton
                                 sx={{ mr: 2 }}
                                 onClick={() => handleOnClick()}
                              >
                                 <Icon
                                    type='fill'
                                    color={colorPalette.dark}
                                    src={src}
                                 />
                              </IconButton>
                           </Tooltip>
                        )
                     )}
               </Stack>
            ) : onSearch ? (
               <Stack direction='row' alignItems='center'>
                  <SearchBox
                     value={searchTerm}
                     width={331}
                     placeholder={t('table.searchbox') as string}
                     onChange={(e) => handleChange(e)}
                  />
                  <Select
                     label={t('table.searchBy') as string}
                     fullWidth
                     placeholder={t('table.searchBy') as string}
                     type='text'
                     defaultValue={initialSearchTermType}
                     onChange={(e) => {
                        setSearchTermType(e.target.value as string)
                        setSearchTerm('')
                     }}
                     sx={{ minWidth: '240px', marginLeft: '4px' }}
                  >
                     {searchTermTypes.map(({ value, label }) => (
                        <SelectOption key={value} value={value}>
                           {label}
                        </SelectOption>
                     ))}
                  </Select>
                  {onStartDateChange || onEndDateChange ? (
                     <Tooltip title='Lọc theo ngày'>
                        <IconButton
                           sx={{ marginLeft: '4px' }}
                           onClick={handleOnTimeClick}
                        >
                           <Icon
                              src={ClockIcon}
                              color={colorPalette.dark}
                              type='fill'
                           />
                        </IconButton>
                     </Tooltip>
                  ) : null}
               </Stack>
            ) : null}
         </TableToolbar>
         {onStartDateChange || onEndDateChange ? (
            <Box
               sx={{
                  width: '100%',
                  display: open ? 'block' : 'none',
                  margin: '16px 0',
               }}
            >
               <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                     <DateTimePicker
                        label='Từ'
                        value={startDate}
                        disableFuture={disabledFuture}
                        onChange={(val) => handleOnStartDateChange(val)}
                     />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                     <DateTimePicker
                        label='Đến'
                        value={endDate}
                        minDate={startDate}
                        disableFuture={disabledFuture}
                        onChange={(val) => handleOnEndDateChange(val)}
                     />
                  </Grid>
               </Grid>
            </Box>
         ) : null}
      </>
   )
}

export default memo(EnhancedTableToolbar)
