import { FC, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { Grid, Box, Stack } from '@mui/material'
import {
   Button,
   NavIconButton,
   CircleLoading,
   PopupNoti,
   PopupForm,
   InputType,
   CommonInfo,
} from '../../../components'
import {
   PageHeader,
   PageContent,
   PageTitle,
   PageWrapper,
} from '../../../layout'
import {
   useAppDispatch,
   useAppSelector,
   selectManager,
   getOneManager,
   revokeManager,
   updatePasswordManager,
   resetManagerDetail,
   selectFetch,
} from '../../../redux'
import { managerPasswordUpdateSchema, warningMsg } from '../../../schemas'
import { ReactComponent as EmailIcon } from '../../../assets/icons/email.svg'
import { ReactComponent as PasswordIcon } from '../../../assets/icons/fingerprint.svg'
import { ReactComponent as PermissionIcon } from '../../../assets/icons/trophy.svg'
import { ReactComponent as StatusIcon } from '../../../assets/icons/hourglass.svg'
import { ActiveStatus, ManagerPermission } from '../../../types'
import { getPermission } from '../../../utils'
import { useTranslation } from 'react-i18next'

interface UpdatePasswordFormValues {
   password: string
   password_repeat: string
}

const ManagerDetail: FC = () => {
   const { managerId } = useParams()
   const dispatch = useAppDispatch()
   const { managerDetail, managerLoading } = useAppSelector(selectManager)
   const { uploading } = useAppSelector(selectFetch)
   const { t } = useTranslation(['common', 'inputs', 'components'])

   const [openAlert, setOpenAlert] = useState<boolean>(false)
   const [openForm, setOpenForm] = useState<boolean>(false)

   const formFields = [
      {
         name: 'password',
         label: t('common.newPass.label', { ns: 'inputs' }),
         type: 'password',
         inputType: InputType.INPUT,
         cols: 12,
         required: true,
         placeholder: t('common.newPass.placeholder', { ns: 'inputs' }),
      },
      {
         name: 'password_repeat',
         label: t('common.repeatPass.label', { ns: 'inputs' }),
         type: 'password',
         inputType: InputType.INPUT,
         cols: 12,
         required: true,
         placeholder: t('common.repeatPass.placeholder', { ns: 'inputs' }),
      },
   ]

   const handleRevoke = () => {
      if (managerId) {
         dispatch(revokeManager(managerId))
      }
   }

   const handleOnValidate = (data: UpdatePasswordFormValues) => {
      let error: any = {}
      if (data.password !== data.password_repeat) {
         error.password_repeat = t('errors.passNotMatch', { ns: 'inputs' })
         error.password = t('errors.passNotMatch', { ns: 'inputs' })
      } else error = false

      return error
   }

   const handleOnSubmit = (data: UpdatePasswordFormValues) => {
      if (managerId) {
         dispatch(
            updatePasswordManager({
               managerId,
               payload: { password: data.password },
            })
         )
      }
   }

   useEffect(() => {
      if (managerId && !uploading) {
         dispatch(getOneManager(managerId))
      }
      return () => {
         dispatch(resetManagerDetail())
      }
   }, [managerId, uploading])

   const renderItems = useMemo(
      () =>
         managerDetail ? (
            <Grid container spacing={2}>
               <Grid item xs={12} sm={12}>
                  <PageContent>
                     <PageTitle
                        type='content'
                        title={t('blockTitle.general')}
                     />
                     <Box sx={{ width: '100%', padding: '8px 0' }}>
                        <Grid container spacing={3}>
                           <Grid item xs={12} sm={12}>
                              <CommonInfo
                                 label={t('common.email.label', {
                                    ns: 'inputs',
                                 })}
                                 icon={EmailIcon}
                                 value={`${managerDetail?.email}`}
                              />
                           </Grid>
                           <Grid
                              item
                              xs={12}
                              sm={12}
                              sx={{ overflow: 'hidden' }}
                           >
                              <CommonInfo
                                 label={t('common.password.label', {
                                    ns: 'inputs',
                                 })}
                                 icon={PasswordIcon}
                                 value={`${managerDetail?.password}`}
                                 hiddenValue
                              />
                           </Grid>
                           <Grid item xs={12}>
                              <CommonInfo
                                 label={t('common.permission.label', {
                                    ns: 'inputs',
                                 })}
                                 icon={PermissionIcon}
                                 value={
                                    t(
                                       `permission.${getPermission(
                                          managerDetail!
                                             .permission as ManagerPermission
                                       )}`
                                    ) as string
                                 }
                                 valuesWithTag={[
                                    {
                                       value: t(
                                          `permission.${getPermission(
                                             ManagerPermission.ADMIN
                                          )}`
                                       ),
                                       color: 'success',
                                    },
                                    {
                                       value: t(
                                          `permission.${getPermission(
                                             ManagerPermission.PACKAGE_MAN
                                          )}`
                                       ),
                                       color: 'warning',
                                    },
                                    {
                                       value: t(
                                          `permission.${getPermission(
                                             ManagerPermission.SHOP_KEEPER
                                          )}`
                                       ),
                                       color: 'info',
                                    },
                                 ]}
                              />
                           </Grid>
                           <Grid item xs={12}>
                              <CommonInfo
                                 label={t('common.status.label', {
                                    ns: 'inputs',
                                 })}
                                 icon={StatusIcon}
                                 tagFilled
                                 value={
                                    t(
                                       `status.${
                                          ActiveStatus[
                                             managerDetail?.is_active
                                                ? 'ACTIVE'
                                                : 'DEACTIVATED'
                                          ]
                                       }`
                                    ) as string
                                 }
                                 valuesWithTag={[
                                    {
                                       value: t(
                                          `status.${ActiveStatus.ACTIVE}`
                                       ),
                                       color: 'success',
                                    },
                                    {
                                       value: t(
                                          `status.${ActiveStatus.DEACTIVATED}`
                                       ),
                                       color: 'error',
                                    },
                                 ]}
                              />
                           </Grid>
                        </Grid>
                     </Box>
                  </PageContent>
               </Grid>
               <Grid item xs={12} sm={6}></Grid>
            </Grid>
         ) : null,
      [managerDetail, uploading, managerId, t]
   )

   return (
      <>
         {!!managerDetail && (
            <PopupForm
               open={openForm}
               setOpen={setOpenForm}
               title={t('formTitle.changePass')}
               schema={managerPasswordUpdateSchema}
               formFields={formFields}
               onSubmit={handleOnSubmit}
               onValidate={handleOnValidate}
            />
         )}
         <PopupNoti
            open={openAlert}
            setOpen={setOpenAlert}
            message={warningMsg}
            action={() => handleRevoke()}
         />
         <PageWrapper>
            <PageHeader>
               <Box sx={{ display: 'flex' }}>
                  <NavIconButton
                     dir='left'
                     variant='text'
                     size='sm'
                     component={Link as any}
                     to='/managers'
                     style={{ marginRight: '16px' }}
                  />
                  <PageTitle
                     type='header'
                     title={`${t('pageTitle.manager.detail')} ${managerId}`}
                  />
               </Box>
               <Stack direction='row' alignItems='center'>
                  <Button
                     customsize='sm'
                     variant='contained'
                     color='primary'
                     onClick={() => setOpenForm(true)}
                  >
                     {t('buttons.manager.changePass', { ns: 'components' })}
                  </Button>
                  {managerDetail?.is_active && (
                     <Button
                        sx={{ marginLeft: '8px' }}
                        customsize='sm'
                        variant='contained'
                        color='error'
                        onClick={() => setOpenAlert(true)}
                     >
                        {t('buttons.manager.revokePermis', {
                           ns: 'components',
                        })}
                     </Button>
                  )}
               </Stack>
            </PageHeader>
            {managerLoading ? <CircleLoading /> : renderItems}
         </PageWrapper>
      </>
   )
}

export default ManagerDetail
