import { createSlice } from '@reduxjs/toolkit'
import {
   getAllProducts,
   getOneProduct,
   createProduct,
   getAllProductTypes,
   getProductTypeDetail,
} from './productThunk'
import type { ProductTypeData, WrappedProductData } from '../../types'

interface ProductsState {
   productLoading: boolean
   products?: WrappedProductData[]
   productDetail?: WrappedProductData
   productTypes?: ProductTypeData[]
   productTypeDetail?: ProductTypeData
}

const initialState: ProductsState = {
   productLoading: false,
}

export const productsSlice = createSlice({
   name: 'productState',
   initialState,
   reducers: {
      resetProductDetail: (state) => {
         state.productDetail = undefined
      },
      resetProductTypeDetail: (state) => {
         state.productTypeDetail = undefined
      },
      resetProductTypes: (state) => {
         state.productTypes = undefined
      },
      resetProducts: (state) => {
         state.productTypes = undefined
      },
   },
   extraReducers: (builder) => {
      builder
         .addCase(getAllProducts.pending, (state) => {
            state.productLoading = true
         })
         .addCase(getAllProducts.fulfilled, (state, action: any) => {
            state.productLoading = false
            state.products = action.payload.products
         })
         .addCase(getAllProducts.rejected, (state, action: any) => {
            state.productLoading = false
         })
         .addCase(getOneProduct.pending, (state) => {
            state.productLoading = true
         })
         .addCase(getOneProduct.fulfilled, (state, action: any) => {
            state.productLoading = false
            state.productDetail = action.payload.productDetail
         })
         .addCase(getOneProduct.rejected, (state, action: any) => {
            state.productLoading = false
         })
         .addCase(getAllProductTypes.pending, (state) => {
            state.productLoading = true
         })
         .addCase(getAllProductTypes.fulfilled, (state, action: any) => {
            state.productLoading = false
            state.productTypes = action.payload.productTypes
         })
         .addCase(getAllProductTypes.rejected, (state, action: any) => {
            state.productLoading = false
         })
         .addCase(createProduct.rejected, (state, action: any) => {
            state.productLoading = false
         })
         .addCase(getProductTypeDetail.pending, (state) => {
            state.productLoading = true
         })
         .addCase(getProductTypeDetail.fulfilled, (state, action) => {
            state.productLoading = false
            state.productTypeDetail = action.payload.productTypeDetail
         })
         .addCase(getProductTypeDetail.rejected, (state) => {
            state.productLoading = false
            state.productTypeDetail = undefined
         })
   },
})

export const { resetProductDetail, resetProductTypes, resetProducts, resetProductTypeDetail } =
   productsSlice.actions

export default productsSlice.reducer
