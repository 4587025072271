import { OrderStatusNumber, OrderStatusString } from "../types"

export const getOrderStatus = (statusNum: OrderStatusNumber) => {
    switch (statusNum) {
        case OrderStatusNumber.ORDER_SUCCESS: 
            return OrderStatusString.ORDER_SUCCESS
        case OrderStatusNumber.ORDER_ONHOLD:
            return OrderStatusString.ORDER_ONHOLD
        case OrderStatusNumber.ORDER_CANCELLED: 
            return OrderStatusString.ORDER_CANCELLED
        case OrderStatusNumber.ORDER_SHIPPED: 
            return OrderStatusString.ORDER_SHIPPED
        default:
            return 'Invalid status'
    }
}