import { FC, useEffect, useMemo } from 'react'
import { Box, Typography, Grid } from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { colorPalette, typography } from '../../../config'
import {
   NavIconButton,
   Button,
   Input,
   Select,
   SelectOption,
} from '../../../components'
import {
   PageContent,
   PageHeader,
   PageTitle,
   PageWrapper,
} from '../../../layout'
import { yupResolver } from '@hookform/resolvers/yup'
import { ManagerPermission, Status } from '../../../types'
import { getManagerPermission } from '../../../utils/get-manager-permission'
import {
   addManager,
   AddManagerData,
   useAppDispatch,
   useAppSelector,
   selectFetch,
} from '../../../redux'
import { managerCreateSchema } from '../../../schemas'
import { useTranslation } from 'react-i18next'
import { getPermission } from '../../../utils'
import { emailRegex } from '../../../utils/validation'

const ManagerCreate: FC = () => {
   const { response } = useAppSelector(selectFetch)
   const dispatch = useAppDispatch()
   const { t } = useTranslation(['common', 'inputs', 'components'])

   const {
      control,
      reset,
      handleSubmit,
      formState: { errors, isDirty },
   } = useForm<AddManagerData>({
      defaultValues: {
         email: '',
         password: '',
         permission: ManagerPermission.ADMIN,
      },
      resolver: yupResolver(managerCreateSchema),
   })

   const onSubmit = (data: AddManagerData) => {
      dispatch(addManager(data))
   }

   useEffect(() => {
      if (response?.status === Status.SUCCESS) {
         reset()
      }
   }, [response?.status])

   const renderForm = useMemo(
      () => (
         <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
               <Controller
                  name='email'
                  control={control}
                  rules={{ required: true, pattern: {
                     value: emailRegex,
                     message: 'Email không đúng định dạng'
                  } }}
                  render={({ field }) => (
                     <Input
                        label={
                           t('common.email.label', { ns: 'inputs' }) as string
                        }
                        fullWidth
                        placeholder={
                           t('common.email.placeholder', {
                              ns: 'inputs',
                           }) as string
                        }
                        type='text'
                        error={!!errors.email}
                        helperText={errors.email?.message}
                        {...field}
                     />
                  )}
               />
            </Grid>
            <Grid item xs={12} sm={12}>
               <Controller
                  name='password'
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                     <Input
                        label={
                           t('common.password.label', {
                              ns: 'inputs',
                           }) as string
                        }
                        fullWidth
                        placeholder={
                           t('common.password.label', {
                              ns: 'inputs',
                           }) as string
                        }
                        type={'password'}
                        error={!!errors.password}
                        helperText={errors.password?.message}
                        {...field}
                     />
                  )}
               />
            </Grid>
            <Grid item xs={12} sm={12}>
               <Controller
                  name='permission'
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                     <Select
                        label={
                           t('common.permission.label', {
                              ns: 'inputs',
                           }) as string
                        }
                        {...field}
                     >
                        {Object.values(ManagerPermission).map((item) => (
                           <SelectOption key={item} value={item}>
                              {t(`permission.${getPermission(item)}`)}
                           </SelectOption>
                        ))}
                     </Select>
                  )}
               />
            </Grid>
         </Grid>
      ),
      [control, errors]
   )

   return (
      <form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
         <PageWrapper>
            <PageHeader>
               <Box sx={{ display: 'flex' }}>
                  <NavIconButton
                     dir='left'
                     variant='text'
                     size='sm'
                     component={Link as any}
                     to='/managers'
                     style={{ marginRight: '16px' }}
                  />
                  <Typography
                     className={typography.pc.h6}
                     color={colorPalette.dark}
                     component='div'
                  >
                     {t('pageTitle.manager.create')}
                  </Typography>
               </Box>
               <Button
                  disabled={!isDirty}
                  type='submit'
                  customsize='sm'
                  variant='contained'
               >
                  {t('buttons.common.save', { ns: 'components' })}
               </Button>
            </PageHeader>
            <Grid container spacing={2}>
               <Grid item xs={12} sm={6}>
                  <PageContent>
                     <PageTitle
                        type='content'
                        title={t('blockTitle.general')}
                     />
                     <Box sx={{ width: '100%', paddingTop: '8px' }}>
                        {renderForm}
                     </Box>
                  </PageContent>
               </Grid>
            </Grid>
         </PageWrapper>
      </form>
   )
}

export default ManagerCreate
