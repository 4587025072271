import { ChangeEvent, FC, useEffect, useMemo } from 'react'
import { Box, Typography, Grid } from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { colorPalette, typography } from '../../../config'
import { NavIconButton, Button, Input } from '../../../components'
import {
   PageContent,
   PageHeader,
   PageTitle,
   PageWrapper,
} from '../../../layout'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAppDispatch, useAppSelector, selectFetch } from '../../../redux'
import { videoCreateSchema } from '../../../schemas'
import { useTranslation } from 'react-i18next'
import { CreateVideoBody, createVideo } from '../../../redux/cms'
import { Status } from '../../../types'
import { getVideoUrl } from '../../../utils'

const VideoCreate: FC = () => {
   const { response } = useAppSelector(selectFetch)
   const dispatch = useAppDispatch()
   const { t } = useTranslation(['common', 'inputs', 'components'])

   const {
      control,
      reset,
      handleSubmit,
      setError,
      clearErrors,
      setValue,
      formState: { errors, isDirty },
   } = useForm<CreateVideoBody>({
      defaultValues: {
         title: '',
         urlVideoIntro: '',
         description: '',
      },
      shouldFocusError: true,
      resolver: yupResolver(videoCreateSchema),
   })

   const onSubmit = (data: CreateVideoBody) => {
      const inputUrl = data?.urlVideoIntro
      if (!inputUrl.includes('src="https://youtube.com/embed"')) {
         return setError(`urlVideoIntro`, {
            type: 'pattern',
            message: 'Không đúng định dạng video, phải là link embed',
         })
      }
      dispatch(createVideo(data))
   }

   useEffect(() => {
      if (response?.status === Status.SUCCESS) {
         reset()
      }
   }, [response?.status])

   const handleOnVideoUrlChange = (
      e: ChangeEvent<HTMLInputElement>,
      onChange: (...event: any[]) => void
   ) => {
      const inputUrl = e?.target?.value ?? ''

      if (!inputUrl.includes('src="https://youtube.com/embed"')) {
         setError(`urlVideoIntro`, {
            type: 'pattern',
            message: 'Không đúng định dạng video, phải là link embed',
         })
      } else {
         clearErrors(`urlVideoIntro`)
      }

      onChange(e)
      setValue('urlVideoIntro', getVideoUrl(inputUrl))
   }

   useEffect(() => {
      if (response?.status === Status.SUCCESS) {
         reset()
      }
   }, [response?.status])

   const renderForm = useMemo(
      () => (
         <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
               <Controller
                  name='title'
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                     <Input
                        label={'Tiêu đề'}
                        fullWidth
                        placeholder={'Tiêu đề'}
                        type='text'
                        error={!!errors.title}
                        helperText={errors?.title?.message}
                        {...field}
                     />
                  )}
               />
            </Grid>
            <Grid item xs={12} sm={12}>
               <Controller
                  name='description'
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                     <Input
                        label={'Mô tả'}
                        fullWidth
                        placeholder={'Mô tả'}
                        type={'text'}
                        error={!!errors.description}
                        helperText={errors?.description?.message}
                        {...field}
                     />
                  )}
               />
            </Grid>
            <Grid item xs={12} sm={12}>
               <Controller
                  name='urlVideoIntro'
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange, ...field } }) => (
                     <Input
                        value={value}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                           handleOnVideoUrlChange(e, onChange)
                        }
                        label={'Link video'}
                        fullWidth
                        placeholder={'Link video'}
                        type={'text'}
                        error={!!errors?.urlVideoIntro}
                        helperText={errors?.urlVideoIntro?.message}
                        {...field}
                     />
                  )}
               />
            </Grid>
         </Grid>
      ),
      [control, errors]
   )

   return (
      <form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
         <PageWrapper>
            <PageHeader>
               <Box sx={{ display: 'flex' }}>
                  <NavIconButton
                     dir='left'
                     variant='text'
                     size='sm'
                     component={Link as any}
                     to='/videos'
                     style={{ marginRight: '16px' }}
                  />
                  <Typography
                     className={typography.pc.h6}
                     color={colorPalette.dark}
                     component='div'
                  >
                     Tạo video
                  </Typography>
               </Box>
               <Button
                  disabled={!isDirty}
                  type='submit'
                  customsize='sm'
                  variant='contained'
               >
                  {t('buttons.common.save', { ns: 'components' })}
               </Button>
            </PageHeader>
            <Grid container spacing={2}>
               <Grid item xs={12} sm={12}>
                  <PageContent>
                     <PageTitle
                        type='content'
                        title={t('blockTitle.general')}
                     />
                     <Box sx={{ width: '100%', paddingTop: '8px' }}>
                        {renderForm}
                     </Box>
                  </PageContent>
               </Grid>
            </Grid>
         </PageWrapper>
      </form>
   )
}

export default VideoCreate
