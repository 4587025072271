import { FC, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Grid, Icon as MuiIcon, Stack } from '@mui/material'
import {
   CircleLoading,
   Button,
   PopupNoti,
   StatisticBlock,
   StatisticBlockType,
   Icon,
} from '../../components'
import Table, {
   TableRowType,
   HeadCell,
   FilterType,
   TableCellDataType,
} from '../../components/Table'
import { PageHeader, PageContent, PageTitle, PageWrapper } from '../../layout'
import {
   useAppDispatch,
   useAppSelector,
   selectStatistic,
   getStatistic,
   StatisticIndex,
} from '../../redux'
import { colorPalette } from '../../config'
import { warningMsg } from '../../schemas'
import { useTranslation } from 'react-i18next'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import { ReactComponent as ProductIcon } from '../../assets/icons/cart.svg'
import { ReactComponent as BannerIcon } from '../../assets/icons/banner.svg'
import { getNumberWithComma } from '../../utils/get-price'

const Dashboard: FC = () => {
   const dispatch = useAppDispatch()
   const { statisticLoading, soldProducts, revenue, bill } =
      useAppSelector(selectStatistic)
   const { t } = useTranslation(['common', 'inputs', 'components'])

   const [openAlert, setOpenAlert] = useState<boolean>(false)

   const headCells: HeadCell[] = useMemo(
      () => [
         {
            isId: true,
            id: 'product_id',
            numeric: false,
            disablePadding: false,
            filterType: FilterType.TEXT,
            label: 'ID',
         },
         {
            id: 'quantity',
            numeric: false,
            disablePadding: false,
            filterType: FilterType.TEXT,
            label: t('common.quantity', { ns: 'rows' }),
         },
      ],
      [t]
   )

   const rows: TableRowType[] = useMemo(
      () =>
         soldProducts
            ? soldProducts.map((soldProduct) => ({
                 key: soldProduct.product_id,
                 cells: [
                    {
                       identifier: 'product_id',
                       type: TableCellDataType.ID,
                       data: soldProduct.product_id,
                    },
                    {
                       identifier: 'quantity',
                       type: TableCellDataType.TEXT,
                       data: soldProduct.quantity,
                    },
                 ],
              }))
            : [],
      [soldProducts]
   )

   useEffect(() => {
      Object.values(StatisticIndex).forEach((index) => {
         dispatch(
            getStatistic({
               index,
               date: {
                  start_at: 0,
                  end_at: new Date().getTime(),
               },
            })
         )
      })
   }, [t])

   return (
      <>
         {/* <PopupNoti
            open={openAlert}
            setOpen={setOpenAlert}
            message={warningMsg}
            action={() => console.log()}
         /> */}
         <PageWrapper>
            <PageHeader>
               <PageTitle type='header' title={t('pageTitle.dashboard')} />
               <Stack direction='row' alignItems='center'>
                  <Button
                     customsize='sm'
                     variant='contained'
                     endIcon={
                        <Icon
                           type='fill'
                           src={BannerIcon}
                           color={colorPalette.white}
                           width={15}
                           height={15}
                        />
                     }
                     component={Link as any}
                     to='/banners/create'
                  >
                     {t('buttons.banner.create', { ns: 'components' })}
                  </Button>
                  <Button
                     customsize='sm'
                     variant='contained'
                     endIcon={
                        <Icon
                           type='fill'
                           src={ProductIcon}
                           color={colorPalette.white}
                           width={15}
                           height={15}
                        />
                     }
                     sx={{ marginLeft: '5px' }}
                     component={Link as any}
                     to='/products/create'
                  >
                     {t('buttons.product.create', { ns: 'components' })}
                  </Button>
               </Stack>
            </PageHeader>
            <PageContent>
               {statisticLoading ? (
                  <CircleLoading />
               ) : (
                  <Grid container spacing={2}>
                     <Grid item xs={12}>
                        <Grid container spacing={2}>
                           <Grid item xs={12} sm={6}>
                              <StatisticBlock
                                 muiIcon={
                                    <MuiIcon sx={{ color: colorPalette.white }}>
                                       <AttachMoneyIcon />
                                    </MuiIcon>
                                 }
                                 title={t('statisticBlock.revenue', {
                                    ns: 'components',
                                 })}
                                 type={StatisticBlockType.UNIT}
                                 unit='đ'
                                 value={getNumberWithComma(
                                    Number(revenue?.all_revenue)
                                 )}
                                 color={colorPalette.orange.shade_500}
                              />
                           </Grid>
                           <Grid item xs={12} sm={6}>
                              <StatisticBlock
                                 muiIcon={
                                    <MuiIcon sx={{ color: colorPalette.white }}>
                                       <ReceiptLongIcon />
                                    </MuiIcon>
                                 }
                                 title={t('statisticBlock.order.total', {
                                    ns: 'components',
                                 })}
                                 type={StatisticBlockType.TEXT}
                                 value={Number(bill?.total_order)}
                                 color={colorPalette.primary}
                              />
                           </Grid>
                           <Grid item xs={12} sm={3}>
                              <StatisticBlock
                                 muiIcon={
                                    <MuiIcon sx={{ color: colorPalette.white }}>
                                       <LocalShippingIcon />
                                    </MuiIcon>
                                 }
                                 title={t('statisticBlock.order.shipped', {
                                    ns: 'components',
                                 })}
                                 type={StatisticBlockType.TEXT}
                                 value={Number(bill?.shipped_order)}
                                 color={colorPalette.green.shade_500}
                              />
                           </Grid>
                           <Grid item xs={12} sm={3}>
                              <StatisticBlock
                                 muiIcon={
                                    <MuiIcon sx={{ color: colorPalette.white }}>
                                       <HourglassBottomIcon />
                                    </MuiIcon>
                                 }
                                 title={t('statisticBlock.order.onhold', {
                                    ns: 'components',
                                 })}
                                 type={StatisticBlockType.TEXT}
                                 value={Number(bill?.onhold_order)}
                                 color={colorPalette.yellow.shade_500}
                              />
                           </Grid>
                           <Grid item xs={12} sm={3}>
                              <StatisticBlock
                                 muiIcon={
                                    <MuiIcon sx={{ color: colorPalette.white }}>
                                       <CheckBoxIcon />
                                    </MuiIcon>
                                 }
                                 title={t('statisticBlock.order.success', {
                                    ns: 'components',
                                 })}
                                 type={StatisticBlockType.TEXT}
                                 value={Number(bill?.success_order)}
                                 color={colorPalette.blue.shade_500}
                              />
                           </Grid>
                           <Grid item xs={12} sm={3}>
                              <StatisticBlock
                                 muiIcon={
                                    <MuiIcon sx={{ color: colorPalette.white }}>
                                       <DisabledByDefaultIcon />
                                    </MuiIcon>
                                 }
                                 title={t('statisticBlock.order.cancelled', {
                                    ns: 'components',
                                 })}
                                 type={StatisticBlockType.TEXT}
                                 value={Number(bill?.cancelled_order)}
                                 color={colorPalette.red.shade_500}
                              />
                           </Grid>
                        </Grid>
                     </Grid>
                     {/* <Grid item xs={12}>
                        <Table
                           initialSort='product_id'
                           initialSearchTermType='product_id'
                           title={t('tableTitle.soldProduct')}
                           headCells={headCells}
                           rows={rows}
                        />
                     </Grid> */}
                  </Grid>
               )}
            </PageContent>
         </PageWrapper>
      </>
   )
}

export default Dashboard
