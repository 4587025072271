import { FC, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { CircleLoading, Button, Icon, PopupNoti } from '../../../components'
import Table, {
   TableRowType,
   HeadCell,
   FilterType,
   TableCellDataType,
} from '../../../components/Table'
import {
   PageHeader,
   PageContent,
   PageTitle,
   PageWrapper,
} from '../../../layout'
import { useAppDispatch } from '../../../redux'
import { ReactComponent as PenIcon } from '../../../assets/icons/pen.svg'
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg'
import { ReactComponent as BinIcon } from '../../../assets/icons/bin.svg'
import { colorPalette } from '../../../config'
import { warningMsg } from '../../../schemas'
import { useTranslation } from 'react-i18next'
import { useSoftwares } from '../../../hooks/use-redux/use-softwares'
import { Maybe } from '../../../types'
import { deleteSoftware } from '../../../redux/cms'

const SoftwaresTable: FC = () => {
   const dispatch = useAppDispatch()
   const { softwares, isLoading, mutate } = useSoftwares()
   const { t } = useTranslation(['common', 'inputs', 'components'])

   const [softwareToDelete, setSoftwareToDelete] = useState<Maybe<string>>(null)
   const [openAlert, setOpenAlert] = useState<boolean>(false)

   const headCells: HeadCell[] = [
      {
         isId: true,
         id: '_id',
         numeric: false,
         disablePadding: false,
         filterType: FilterType.TEXT,
         label: 'ID',
      },
      {
         id: 'thumbnail',
         numeric: false,
         disablePadding: false,
         filterType: FilterType.TEXT,
         label: 'Ảnh',
      },
      {
         id: 'title',
         numeric: false,
         disablePadding: false,
         filterType: FilterType.TEXT,
         label: 'Tiêu đề',
      },
      {
         id: 'created_at',
         numeric: false,
         disablePadding: false,
         filterType: FilterType.DATE,
         label: t('common.createdAt', { ns: 'rows' }),
      },
   ]

   const handleOnDelete = (software_id: string) => {
      setOpenAlert(true)
      setSoftwareToDelete(software_id)
   }

   const handleDelete = async () => {
      if (!softwareToDelete) {
         return
      }
      await mutate({
         statusCode: 200,
         data: softwares?.filter(software => software._id !== softwareToDelete)
      })

      dispatch(deleteSoftware(softwareToDelete))
      setSoftwareToDelete(null)
   }

   const rows: TableRowType[] = useMemo(
      () =>
         softwares
            ? softwares.map((software) => {
                 return {
                    key: software._id,
                    cells: [
                       {
                          identifier: '_id',
                          type: TableCellDataType.ID,
                          data: software._id,
                       },
                       {
                          identifier: 'thumbnail',
                          type: TableCellDataType.IMAGE,
                          data: software.thumbnail,
                       },
                       {
                          identifier: 'title',
                          type: TableCellDataType.TEXT,
                          data: software.title,
                       },
                       {
                          identifier: 'created_at',
                          type: TableCellDataType.DATE,
                          data: software.created_at,
                       },
                       {
                          identifier: 'action',
                          type: TableCellDataType.ACTION,
                          data: [
                             {
                                icon: {
                                   src: PenIcon,
                                   color: colorPalette.grey,
                                   tooltip: t('tooltip.common.edit', {
                                      ns: 'components',
                                   }) as string,
                                },
                                link: `/softwares/${software._id}`,
                             },
                             {
                                icon: {
                                   src: BinIcon,
                                   color: colorPalette.red.shade_500,
                                   tooltip: 'Xoá',
                                },
                                handleOnClick: () =>
                                   handleOnDelete(software._id),
                             },
                          ],
                       },
                    ],
                 }
              })
            : [],
      [softwares]
   )

   return (
      <>
         <PopupNoti
            open={openAlert}
            setOpen={setOpenAlert}
            message={warningMsg}
            action={() => handleDelete()}
         />
         <PageWrapper>
            <PageHeader>
               <PageTitle type='header' title={'Tất cả phần mềm'} />
               <Button
                  customsize='sm'
                  variant='contained'
                  endIcon={
                     <Icon
                        type='fill'
                        src={PlusIcon}
                        color={colorPalette.white}
                        width={15}
                        height={15}
                     />
                  }
                  style={{ marginLeft: '5px' }}
                  component={Link as any}
                  to='create'
               >
                  Thêm phần mềm
               </Button>
            </PageHeader>
            <PageContent>
               {isLoading ? (
                  <CircleLoading />
               ) : (
                  <Table
                     initialSort='title'
                     initialSearchTermType='_id'
                     title={'Bảng phần mềm'}
                     headCells={headCells}
                     rows={rows}
                     withAction
                  />
               )}
            </PageContent>
         </PageWrapper>
      </>
   )
}

export default SoftwaresTable
