import { FC, memo, useEffect, ReactNode } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Grid } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { AnyObjectSchema } from 'yup'
import Input from '../Input'
import Button from '../Button'
import { Select, SelectOption } from '../Select'
import { selectFetch, useAppSelector } from '../../redux'
import { Status } from '../../types'
import DateTimePicker from '../DateTimePicker'
import { useNavigate } from 'react-router-dom'
import PopupModal from '../PopupModal'
import { useTranslation } from 'react-i18next'
import { Dayjs } from 'dayjs'
import { useViewport } from '../../hooks/use-viewport'
import { useSWRConfig } from 'swr'

export enum InputType {
   SELECT = 'select',
   INPUT = 'input',
   DATE = 'date',
}

export interface PopupFormType {
   url?: string
   title: string
   defaultValues?: Record<string, any>
   fields?: Field[]
   schema?: AnyObjectSchema
   onValidate?: (data: any) => any
   onSubmit?: (data: any) => void
   navigateTo?: string
}

export interface PopupFormRecord {
   [key: string]: PopupFormType
}

export interface Field {
   name: string
   label: string
   type: string
   isHidden?: boolean
   inputType: InputType
   options?: {
      value: string
      label: string
   }[]
   cols: number
   required: boolean
   disableFuture?: boolean
   disablePast?: boolean
   minDate?: number | string | Date | Dayjs
   maxDate?: number | string | Date | Dayjs
   placeholder?: string
}

interface PopupFormProps {
   open: boolean
   url?: string
   setOpen: (open: boolean) => void
   title: string
   schema?: AnyObjectSchema
   defaultValues?: { [x: string]: any }
   formFields?: Field[]
   onSubmit?: (data: any) => void
   onValidate?: (data: any) => any
   navigateTo?: string
   tableForm?: ReactNode
}

const PopupForm: FC<PopupFormProps> = memo(
   ({
      open,
      url,
      setOpen,
      title,
      schema,
      defaultValues,
      formFields,
      onSubmit,
      onValidate,
      navigateTo,
      tableForm,
   }) => {
      const { response } = useAppSelector(selectFetch)
      const { mutate } = useSWRConfig()
      const {
         control,
         handleSubmit,
         setError,
         setValue,
         reset,
         formState: { errors, isDirty },
      } = useForm({
         defaultValues,
         ...(schema && { resolver: yupResolver(schema) }),
      })
      const navigate = useNavigate()
      const { t } = useTranslation(['components'])
      const { width } = useViewport()

      const handleValidate = (data: any) => {
         const error = onValidate ? onValidate(data) : false

         if (error) {
            Object.keys(error).forEach((key) => {
               setError(key, { type: 'custom', message: error[key] })
            })
         } else {
            if (onSubmit) {
               onSubmit(data)
               if (url) {
                  mutate(url)
               }
            }
         }
      }

      useEffect(() => {
         if (response?.status === Status.SUCCESS) {
            reset()
            setOpen(false)
            if (navigateTo) {
               navigate(navigateTo)
            }
         }
      }, [response?.status])

      const renderForm = (
         <Grid
            container
            spacing={2}
            sx={{ padding: '32px 16px', ...(tableForm && { display: 'none' }) }}
         >
            {formFields &&
               formFields.map(
                  ({
                     name,
                     label,
                     cols,
                     type,
                     inputType,
                     options,
                     disableFuture,
                     disablePast,
                     minDate,
                     maxDate,
                     required,
                     placeholder,
                     isHidden = false,
                  }) => (
                     <Grid item xs={12} md={cols} key={name}>
                        <Controller
                           name={name}
                           control={control}
                           rules={{ required }}
                           render={({ field }) => {
                              switch (inputType) {
                                 case InputType.INPUT:
                                    return (
                                       <Input
                                          hidden={isHidden}
                                          label={label}
                                          fullWidth
                                          placeholder={placeholder}
                                          type={type}
                                          error={!!errors[name]}
                                          helperText={
                                             errors[name]?.message as string
                                          }
                                          {...field}
                                       />
                                    )
                                 case InputType.SELECT:
                                    return (
                                       <Select
                                          label={label}
                                          fullWidth
                                          placeholder={placeholder}
                                          type={type}
                                          error={!!errors[name]}
                                          helperText={
                                             errors[name]?.message as string
                                          }
                                          {...field}
                                       >
                                          {options!.map((option) => (
                                             <SelectOption
                                                key={option.value}
                                                value={option.value}
                                             >
                                                {option.label}
                                             </SelectOption>
                                          ))}
                                       </Select>
                                    )
                                 case InputType.DATE:
                                    const handleChange = (value: Date) => {
                                       const date = new Date(value)
                                       setValue(name, date.valueOf())
                                    }

                                    return (
                                       <DateTimePicker
                                          value={field.value}
                                          error={!!errors[name]}
                                          position={
                                             width < 1280
                                                ? 'left-end'
                                                : 'bottom-end'
                                          }
                                          disableFuture={disableFuture}
                                          disablePast={disablePast}
                                          minDate={minDate}
                                          maxDate={maxDate}
                                          helperText={
                                             errors[name]?.message as string
                                          }
                                          label={label}
                                          onChange={handleChange}
                                       />
                                    )
                                 default:
                                    return (
                                       <Input
                                          label={label}
                                          fullWidth
                                          placeholder={placeholder}
                                          type={type}
                                          error={!!errors[name]}
                                          helperText={
                                             errors[name]?.message as string
                                          }
                                          {...field}
                                       />
                                    )
                              }
                           }}
                        />
                     </Grid>
                  )
               )}
         </Grid>
      )

      return (
         <form autoComplete='off'>
            <PopupModal
               open={open}
               onClose={() => setOpen(false)}
               footer={
                  <Button
                     disabled={!isDirty}
                     customsize='sm'
                     variant='contained'
                     color='primary'
                     type='submit'
                     onClick={handleSubmit(handleValidate)}
                  >
                     {t('buttons.common.save', { ns: 'components' })}
                  </Button>
               }
               title={title}
            >
               {renderForm}
               {tableForm}
            </PopupModal>
         </form>
      )
   }
)

export default PopupForm
