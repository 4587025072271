import { createAsyncThunk } from '@reduxjs/toolkit'
import { useFetchingHandler } from '../../hooks/use-fetch-handlers'
import type { OrderData } from '../../types'
import { setUploading, setResponse } from '../fetch'
import { Status, OrderStatusString } from '../../types'

export interface UpdateOrderDataPayload {
   status: OrderStatusString
   note: string
}

export interface UpdateOrderData {
   orderId: string
   payload: UpdateOrderDataPayload
}

export const getOrders = createAsyncThunk(
   'orderState/getAllOrders',
   async (_, { dispatch }) => {
      const { getHandler } = useFetchingHandler({ dispatch })
      try {
         const res = await getHandler<OrderData[]>({
            url: 'order',
            secured: true,
         })

         return {
            orders: res?.data,
         }
      } catch (err: any) {
         throw err
      }
   }
)

export const getOrderById = createAsyncThunk(
   'orderState/getById',
   async (orderId: string, { dispatch }) => {
      const { getHandler } = useFetchingHandler({ dispatch })
      try {
         const res = await getHandler<OrderData>({
            url: `order/${orderId}`,
            secured: true,
         })

         return {
            orderDetail: res?.data,
         }
      } catch (err: any) {
         throw err
      }
   }
)

export const updateOrder = createAsyncThunk(
   'orderState/updateOrder',
   async ({ orderId, payload }: UpdateOrderData, { dispatch }) => {
      const { putHandler } = useFetchingHandler({ dispatch })
      try {
         dispatch(setUploading(true))
         dispatch(
            setResponse({
               status: Status.PENDING,
               message: 'Processing ...',
            })
         )

         const res = await putHandler<UpdateOrderDataPayload, any>({
            url: `order/${orderId}`,
            secured: true,
            body: payload,
         })

         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.SUCCESS,
               message: res?.message,
            })
         )
         return {
            orderDetail: res?.data,
         }
      } catch (err: any) {
         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.ERROR,
               message: err?.response?.message,
            })
         )
         throw err
      }
   }
)
