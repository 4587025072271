/* Libs */
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
/* Reducers */
import { authReducer } from './auth'
import { orderReducer } from './order'
import { productReducer } from './product'
import { managerReducer } from './manager'
import { userReducer } from './user'
import { promotionReducer } from './promotion'
import { mediaReducer } from './media'
import { fetchReducer } from './fetch'
import { statisticReducer } from './statistic'
import { bannerReducer } from './banner'
import { cmsReducer } from './cms'

export enum States {
   AUTH = 'authState',
   ORDER = 'orderState',
   PRODUCT = 'productState',
   MANAGER = 'managerState',
   USER = 'userState',
   PROMOTION = 'promotionState',
   MEDIA = 'mediaState',
   FETCH = 'fetchState',
   CMS = 'cmsState',
}

export const store = configureStore({
   reducer: {
      authState: authReducer,
      orderState: orderReducer,
      productsState: productReducer,
      managerState: managerReducer,
      userState: userReducer,
      promotionState: promotionReducer,
      mediaState: mediaReducer,
      statisticState: statisticReducer,
      bannerState: bannerReducer,
      fetchState: fetchReducer,
      cmsState: cmsReducer,
   },
   middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
         serializableCheck: false,
      }),
   devTools: true,
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<
   ReturnType,
   RootState,
   unknown,
   Action<string>
>
