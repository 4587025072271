import { Typography, TypographyProps as MuiTypographyProps } from '@mui/material'
import React from 'react'
import { colorPalette, typography } from '../../config'

type TypographyProps = MuiTypographyProps & {
   component?: React.ReactNode
}

const styledTypography = (Component: typeof Typography) => {
   return (props: TypographyProps) => {
      const { children, sx, ...restProps } = props

      return (
         <Component
            className={typography.pc.descReg}
            color={colorPalette.dark}
            sx={{ display: 'flex', ...sx }}
            {...restProps}
         >
            {children}
         </Component>
      )
   }
}

const TableCellText = styledTypography(Typography)

export default TableCellText
