import { FC, useMemo, useEffect } from 'react'
import { Box, Typography, Grid } from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { colorPalette, typography } from '../../../config'
import {
   NavIconButton,
   Button,
   Input,
   LinearLoading,
   CircleLoading,
   ProducTypeForm,
} from '../../../components'
import {
   PageContent,
   PageHeader,
   PageTitle,
   PageWrapper,
} from '../../../layout'
import { Status } from '../../../types'
import { yupResolver } from '@hookform/resolvers/yup'
import {
   createProductType,
   selectFetch,
   selectProduct,
   useAppDispatch,
   useAppSelector,
} from '../../../redux'
import { productTypeCreateFormSchema } from '../../../schemas/productSchema'
import { useTranslation } from 'react-i18next'

interface ProductCreateFormValues {
   product_type_name: string
}

const ProductTypeCreate: FC = () => {
   const { uploading, response } = useAppSelector(selectFetch)
   const { productLoading } = useAppSelector(selectProduct)
   const dispatch = useAppDispatch()
   const { t } = useTranslation(['common', 'inputs', 'components'])

   const {
      control,
      handleSubmit,
      reset,
      formState: { errors, isDirty },
   } = useForm<ProductCreateFormValues>({
      defaultValues: {
         product_type_name: '',
      },
      resolver: yupResolver(productTypeCreateFormSchema),
   })

   const onSubmit = (data: ProductCreateFormValues) => {
      dispatch(createProductType(data))
   }

   useEffect(() => {
      if (response?.status === Status.SUCCESS) {
         reset()
      }
   }, [response?.status])

   return (
      <>
         {uploading && <LinearLoading />}
         <PageWrapper>
            <PageHeader>
               <Box sx={{ display: 'flex' }}>
                  <NavIconButton
                     dir='left'
                     variant='text'
                     size='sm'
                     component={Link as any}
                     to='/product-types'
                     style={{ marginRight: '16px' }}
                  />
                  <Typography
                     className={typography.pc.h6}
                     color={colorPalette.dark}
                     component='div'
                  >
                     {t('pageTitle.productType.create')}
                  </Typography>
               </Box>
               <Button
                  disabled={!isDirty}
                  customsize='sm'
                  variant='contained'
                  type='submit'
                  onClick={handleSubmit(onSubmit)}
               >
                  {t('buttons.common.save', { ns: 'components' })}
               </Button>
            </PageHeader>
            <Grid container spacing={2}>
               <Grid item xs={12} sm={6}>
                  <PageContent>
                     <PageTitle
                        type='content'
                        title={t('blockTitle.general')}
                     />
                     <Box sx={{ width: '100%', paddingTop: '8px' }}>
                        {productLoading ? (
                           <CircleLoading />
                        ) : (
                           <ProducTypeForm errors={errors} control={control} />
                        )}
                     </Box>
                  </PageContent>
               </Grid>
            </Grid>
         </PageWrapper>
      </>
   )
}

export default ProductTypeCreate
