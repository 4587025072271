import { Dispatch, FC, SetStateAction, useState } from 'react'
import { Link, Outlet, useOutletContext } from 'react-router-dom'
import { Stack } from '@mui/material'
import { PageHeader, PageTitle, PageWrapper } from '../../layout'
import {
   Button,
   HeaderTab,
   Icon,
   InputType,
   PopupForm,
   PopupFormRecord,
} from '../../components'
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg'
import { colorPalette } from '../../config'
import { voucherCreateSchema } from '../../schemas'
import { useAppDispatch, createVoucher } from '../../redux'
import { useTranslation } from 'react-i18next'

type OutletContextType = {
   setTitle: Dispatch<SetStateAction<string>>
   setFormName: Dispatch<SetStateAction<FormName>>
}

export enum OutletTitle {
   PROMOTIONS = 'promotions',
   PROGRAMS = 'programs',
   VOUCHERS = 'vouchers',
}

const links = [
   {
      label: OutletTitle.PROGRAMS,
      href: 'programs',
   },
   {
      label: OutletTitle.VOUCHERS,
      href: 'vouchers',
   },
]

interface CreateVoucherFormValues {
   value: number
   quantity: number
   expired_at: number
}

export enum FormName {
   PROGRAM = 'createProgram',
   VOUCHER = 'createVoucher',
}

const Promotion: FC = () => {
   const [title, setTitle] = useState<string>('')
   const [openForm, setOpenForm] = useState<boolean>(false)
   const [formName, setFormName] = useState<FormName>(FormName.VOUCHER)

   const dispatch = useAppDispatch()
   const { t } = useTranslation(['common', 'components', 'inputs'])

   const handleOnClick = (formName: FormName) => {
      setFormName(formName)
      setOpenForm(true)
   }

   const forms: PopupFormRecord = {
      [FormName.VOUCHER]: {
         url: 'voucher',
         title: t(`formTitle.${FormName.VOUCHER}`) as string,
         defaultValues: {
            value: 0,
            expired_at: new Date().valueOf(),
         },
         fields: [
            {
               name: 'value',
               label: `${t('common.amount.label', { ns: 'inputs' })} đ`,
               type: 'number',
               inputType: InputType.INPUT,
               cols: 6,
               required: true,
               placeholder: `${t('common.amount.placeholder', {
                  ns: 'inputs',
               })}`,
            },
            {
               name: 'quantity',
               label: 'Số lượng',
               type: 'number',
               inputType: InputType.INPUT,
               cols: 6,
               required: true,
               placeholder: 'Nhập số lượng voucher',
            },
            {
               name: 'expired_at',
               label: t('common.expiredDate.label', { ns: 'inputs' }),
               type: 'date',
               inputType: InputType.DATE,
               cols: 12,
               disablePast: true,
               required: true,
               placeholder: t('common.expiredDate.placeholder', {
                  ns: 'inputs',
               }) as string,
            },
         ],
         schema: voucherCreateSchema,
         onValidate: (data: CreateVoucherFormValues) => {
            let error: any = {}
            const currentDate = new Date().valueOf()

            if (data.expired_at < currentDate) {
               error.expired_at = t('errors.expiredDate', { ns: 'inputs' })
            } else {
               error = false
            }

            return error
         },
         onSubmit: (data: CreateVoucherFormValues) => {
            dispatch(createVoucher(data))
         },
         navigateTo: 'vouchers',
      },
      [FormName.PROGRAM]: {
         title: t(`formTitle.${FormName.PROGRAM}`) as string,
      },
   }

   return (
      <>
         {/* <PopupForm
            open={openForm}
            setOpen={setOpenForm}
            title={forms[formName].title || ''}
            defaultValues={forms[formName].defaultValues}
            schema={forms[formName].schema}
            formFields={forms[formName].fields}
            onSubmit={forms[formName].onSubmit}
            onValidate={forms[formName].onValidate}
            navigateTo={forms[formName].navigateTo}
            url={forms[formName]?.url}
         /> */}
         <PageWrapper>
            <HeaderTab linkPrefix='promotion' links={links} />
            <PageHeader sx={{ marginTop: '16px' }}>
               <PageTitle type='header' title={title} />
               <Stack direction='row' alignItems='center'>
                  <Button
                     customsize='sm'
                     variant='contained'
                     endIcon={
                        <Icon
                           type='fill'
                           src={PlusIcon}
                           color={colorPalette.white}
                           width={15}
                           height={15}
                        />
                     }
                     style={{ marginLeft: '5px' }}
                     component={Link as any}
                     to='/voucher/voucher-create'
                  >
                     {t('buttons.voucher.create', { ns: 'components' })}
                  </Button>
                  <Button
                     customsize='sm'
                     variant='contained'
                     endIcon={
                        <Icon
                           type='fill'
                           src={PlusIcon}
                           color={colorPalette.white}
                           width={15}
                           height={15}
                        />
                     }
                     style={{ marginLeft: '5px' }}
                     component={Link as any}
                     to='/promotion-program-create'
                  >
                     {t('buttons.promotion.create', { ns: 'components' })}
                  </Button>
               </Stack>
            </PageHeader>
            <Outlet context={{ setTitle, setFormName }} />
         </PageWrapper>
      </>
   )
}

export default Promotion

export const useTitle = () => {
   return useOutletContext<OutletContextType>()
}
