import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { login, logout } from './authThunk'
// Define a type for the slice state

interface UserState {
   error?: {
      message: string
   }
   authLoading: boolean
   userTokens: {
      accessToken: string
      refreshToken: string
   }
   permission?: 'admin' | 'user'
   isAuthenticated: boolean
}

const defaultUserTokens = {
   accessToken: '',
   refreshToken: '',
}
const userTokensFromStorage = localStorage.getItem('userTokens')

const initTokens = userTokensFromStorage
   ? JSON.parse(userTokensFromStorage)
   : null
// const permissionFromStorage = JSON.parse(
//   localStorage.getItem('permission') ?? ''
// )

// Define the initial state using that type
const initialState: UserState = {
   authLoading: false,
   userTokens: initTokens,
   // permission: permissionFromStorage,
   isAuthenticated: !!userTokensFromStorage || false,
}

export const authSlice = createSlice({
   name: 'authState',
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      builder
         .addCase(login.pending, (state) => {
            state.authLoading = true
         })
         .addCase(login.fulfilled, (state, action: any) => {
            state.authLoading = false
            state.userTokens = action.payload.userTokens
            state.permission = action.payload.permission
            state.isAuthenticated = !!action.payload.userTokens
         })
         .addCase(login.rejected, (state, action: any) => {
            state.authLoading = false
            state.isAuthenticated = false
            state.error = action.error
         })
         .addCase(logout.pending, (state) => {
            state.authLoading = true
         })
         .addCase(logout.fulfilled, (state) => {
            state.authLoading = false
            state.isAuthenticated = false
         })
         .addCase(logout.rejected, (state, action: any) => {
            state.authLoading = false
            state.error = action.error
         })
   },
})

export default authSlice.reducer
