import { createAsyncThunk } from '@reduxjs/toolkit'
import { useFetchingHandler } from '../../hooks/use-fetch-handlers'
import { setUploading, setResponse } from '../fetch'
import {
   ProductData,
   ProductTypeData,
   ProductMetaData,
   Status,
   WrappedProductData,
} from '../../types'

export interface CreateProductData extends UpdateProductDataPayload {
   image: string[]
}

export interface UpdateProductDataPayload {
   product_name: string
   product_type: string
   price: number
   product_metadata: ProductMetaData[]
   product_instruction: string
   product_description: string
   uid?: string
   _id?: string
   identifier?: string
}

export interface UpdateProductData {
   productId: string
   payload: CreateProductData[]
}

export interface UpdateProductImagesDataPayload {
   images: string[]
}

export interface UpdateProductImagesData {
   productId: string
   payload: UpdateProductImagesDataPayload
}

export interface UpdateProductType {
   productTypeId: string
   payload: {}
}
export interface CreateProductTypeData {
   product_type_name: string
}

export interface UpdateProductTypePayload {
   productTypeId: string
   productTypeName: string
}

export const getAllProducts = createAsyncThunk(
   'productState/getAllProducts',
   async (_, { dispatch }) => {
      const { getHandler } = useFetchingHandler({ dispatch })
      try {
         const res = await getHandler<WrappedProductData[]>({
            url: 'product',
            secured: true,
         })

         return {
            products: res?.data,
         }
      } catch (err: any) {
         throw err
      }
   }
)

export const getOneProduct = createAsyncThunk(
   'productState/getOneProduct',
   async (productId: string, { dispatch }) => {
      const { getHandler } = useFetchingHandler({ dispatch })
      try {
         const res = await getHandler<WrappedProductData>({
            url: `product/${productId}`,
            secured: true,
         })

         return {
            productDetail: res?.data,
         }
      } catch (err: any) {
         throw err
      }
   }
)

export const getAllProductTypes = createAsyncThunk(
   'productState/getAllProductTypes',
   async (_, { dispatch }) => {
      const { getHandler } = useFetchingHandler({ dispatch })
      try {
         const res = await getHandler<ProductTypeData[]>({
            url: `product-type`,
            secured: true,
         })

         return {
            productTypes: res?.data,
         }
      } catch (err: any) {
         throw err
      }
   }
)

export const createProductType = createAsyncThunk(
   'productState/createProductType',
   async (values: CreateProductTypeData, { dispatch }) => {
      const { postHandler } = useFetchingHandler({ dispatch })
      try {
         dispatch(setUploading(true))
         dispatch(
            setResponse({
               status: Status.PENDING,
               message: 'Processing ...',
            })
         )
         const res = await postHandler<CreateProductTypeData, any>({
            url: `product-type`,
            secured: true,
            body: values,
         })

         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.SUCCESS,
               message: res.message,
            })
         )
      } catch (err: any) {
         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.ERROR,
               message: err?.response?.message,
            })
         )
         throw err
      }
   }
)

export const deleteProductType = createAsyncThunk(
   'productState/deleteProductType',
   async (id: string, { dispatch }) => {
      const { deleteHandler } = useFetchingHandler({ dispatch })
      try {
         dispatch(setUploading(true))
         dispatch(
            setResponse({
               status: Status.PENDING,
               message: 'Processing ...',
            })
         )

         const res = await deleteHandler({
            url: `product-type/${id}`,
            secured: true,
         })

         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.SUCCESS,
               message: res.message,
            })
         )
      } catch (err: any) {
         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.ERROR,
               message: err?.response?.message,
            })
         )

         throw err
      }
   }
)

export const deleteProduct = createAsyncThunk(
   'productState/deleteProduct',
   async (productId: string, { dispatch }) => {
      const { deleteHandler } = useFetchingHandler({ dispatch })
      try {
         dispatch(setUploading(true))
         dispatch(
            setResponse({
               status: Status.PENDING,
               message: 'Processing ...',
            })
         )

         const res = await deleteHandler({
            url: `product/${productId}`,
            secured: true,
         })

         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.SUCCESS,
               message: res.message,
            })
         )
      } catch (err: any) {
         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.ERROR,
               message: err?.response?.message,
            })
         )

         throw err
      }
   }
)

export const createProduct = createAsyncThunk(
   'productState/createProduct',
   async (values: CreateProductData[], { dispatch }) => {
      const { postHandler } = useFetchingHandler({ dispatch })
      try {
         dispatch(setUploading(true))
         dispatch(
            setResponse({
               status: Status.PENDING,
               message: 'Processing...',
            })
         )

         const res = await postHandler<CreateProductData[], any>({
            url: `product`,
            secured: true,
            body: values.map(
               ({ _id, uid, identifier, ...restVals }) => restVals
            ),
         })
         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.SUCCESS,
               message: res?.message,
            })
         )
      } catch (err: any) {
         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.ERROR,
               message: err?.message || 'Tạo product thành công',
            })
         )
         throw err
      }
   }
)

export const updateProduct = createAsyncThunk(
   'productState/updateProduct',
   async ({ payload, productId }: UpdateProductData, { dispatch }) => {
      const { putHandler } = useFetchingHandler({ dispatch })
      try {
         dispatch(setUploading(true))
         dispatch(
            setResponse({
               status: Status.PENDING,
               message: 'Đang cập nhật ảnh sản phẩm...',
            })
         )

         dispatch(
            setResponse({
               status: Status.PENDING,
               message: 'Đang cập nhật sản phẩm',
            })
         )

         const res = await putHandler<CreateProductData[], any>({
            url: `product/${productId}`,
            secured: true,
            body: payload.map(({ identifier, ...restVals }) => restVals),
         })

         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.SUCCESS,
               message: res?.message,
            })
         )
      } catch (err: any) {
         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.ERROR,
               message: err?.message,
            })
         )
         throw err
      }
   }
)

export const updateProductImages = createAsyncThunk(
   'productState/updateProductImages',
   async ({ productId, payload }: UpdateProductImagesData, { dispatch }) => {
      const { putHandler } = useFetchingHandler({ dispatch })
      try {
         dispatch(setUploading(true))
         dispatch(
            setResponse({
               status: Status.PENDING,
               message: 'Đang cập nhật ảnh sản phẩm',
            })
         )

         const res = await putHandler<UpdateProductImagesDataPayload, any>({
            url: `product/${productId}/images`,
            secured: true,
            body: payload,
         })

         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.SUCCESS,
               message: res?.message,
            })
         )
      } catch (err: any) {
         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.ERROR,
               message: err?.message,
            })
         )
         throw err
      }
   }
)

export const updateProductType = createAsyncThunk(
   'productState/updateProductType',
   async (
      { productTypeId, productTypeName }: UpdateProductTypePayload,
      { dispatch }
   ) => {
      const { putHandler } = useFetchingHandler({ dispatch })
      try {
         dispatch(setUploading(true))
         dispatch(
            setResponse({
               status: Status.PENDING,
               message: 'Đang cập nhật',
            })
         )

         const res = await putHandler<{ product_type_name: string }, any>({
            url: `product-type/${productTypeId}`,
            secured: true,
            body: { product_type_name: productTypeName },
         })

         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.SUCCESS,
               message: res?.message,
            })
         )
      } catch (err: any) {
         dispatch(setUploading(false))
         dispatch(
            setResponse({
               status: Status.ERROR,
               message: err?.message,
            })
         )
         throw err
      }
   }
)

export const getProductTypeDetail = createAsyncThunk(
   'productState',
   async (id: string, { dispatch }) => {
      const { getHandler } = useFetchingHandler({ dispatch })
      try {
         const res = await getHandler<WrappedProductData>({
            url: `product-type/${id}`,
            secured: true,
         })

         return {
            productTypeDetail: res?.data,
         }
      } catch (err: any) {
         throw err
      }
   }
)
