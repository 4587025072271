export const getDate = (timeStamp: number) => {
   const date = new Date(timeStamp)
   const time = date.toLocaleTimeString('vi', {
      hourCycle: 'h23',
   })
   const day = date.toLocaleDateString('vi', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
   })

   return {
      time,
      day,
   }
}
