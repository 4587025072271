import * as yup from 'yup'
import { requiredMsg } from './common'

export const managerPasswordUpdateSchema = yup
   .object({
      password: yup.string().required(requiredMsg),
      password_repeat: yup.string().required(requiredMsg),
   })
   .required()

export const managerCreateSchema = yup
   .object({
      email: yup.string().required(requiredMsg),
      password: yup.string().required(requiredMsg),
      permission: yup.string().required(requiredMsg),
   })
   .required()
