import axios from 'axios'
import { logout, resetError, setResponse, setUploading } from '../redux'
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import { Status } from '../types'

export type PaginationInfo = {
   pageNum: number
   pageSize: number
   pageTotal: number
   totalDocument: number
}

export type APIReturnData<OutputInterface> = {
   statusCode: number
   message?: string
   data?: OutputInterface
   paginationInfo?: PaginationInfo
}

type BaseFetchHandlerParameters = {
   url: string
   secured?: boolean
   contentType?: ContentType
}

type PostFetchHandlerParameters<BodyInterface> = BaseFetchHandlerParameters & {
   body: BodyInterface
}

export enum ContentType {
   JSON = 'application/json',
   FILE = 'multipart/form-data',
}

export const baseURL = process.env.REACT_APP_BASE_URL

type UseFetchHandlerParams = {
   dispatch: ThunkDispatch<unknown, unknown, AnyAction>
}

export const useFetchingHandler = ({ dispatch }: UseFetchHandlerParams) => {
   const tokens = JSON.parse(localStorage.getItem('userTokens') || 'null')

   const instance = axios.create({
      baseURL: `${baseURL}/admin/`,
      timeout: 100000,
   })

   instance.interceptors.request.use(
      function (config) {
         // Làm gì đó trước khi request dược gửi đi
         // console.log(config)
         return config
      },
      function (error) {
         // Làm gì đó với lỗi request
         // console.log('error when fetching', error)
         return Promise.reject(error)
      }
   )

   // Thêm một bộ đón chặn response
   instance.interceptors.response.use(
      function (response) {
         // Bất kì mã trạng thái nào nằm trong tầm 2xx đều khiến hàm này được trigger
         // Làm gì đó với dữ liệu response
         // console.log(response)
         return response
      },
      async function (error) {
         console.log('Error when fetching: ', error?.response)
         if (error?.response?.status === 401) {
            dispatch(setUploading(true))
            dispatch(
               setResponse({
                  status: Status.PENDING,
                  message: 'Processing ...',
               })
            )
            const { data, status } = await instance.post<
               APIReturnData<{ accessToken: string }>
            >(
               'authentication/refresh-token',
               {
                  refreshToken: tokens.refreshToken,
               },
               {
                  headers: {
                     'Content-Type': ContentType.JSON,
                     ...(tokens && {
                        Authorization: `Bearer ${tokens.accessToken}`,
                     }),
                  },
               }
            )
            if (status === 200 && data?.data?.accessToken) {
               localStorage.setItem(
                  'userTokens',
                  JSON.stringify({
                     ...tokens,
                     accessToken: data?.data?.accessToken,
                  })
               )
               dispatch(setUploading(false))
               dispatch(
                  setResponse({
                     status: Status.SUCCESS,
                     message: data?.message,
                  })
               )
               dispatch(resetError())
            } else {
               dispatch(logout())
            }
         }

         return error?.response
      }
   )

   const postHandler = async <BodyInterface, OutputInterface>({
      url,
      body,
      secured = false,
      contentType = ContentType.JSON,
   }: PostFetchHandlerParameters<BodyInterface>) => {
      try {
         // const minifiedBody = JSON.stringify(body)
         const response = await instance.post<APIReturnData<OutputInterface>>(
            url,
            body,
            // minifiedBody,
            {
               headers: {
                  'Content-Type': contentType,
                  ...(contentType === ContentType.FILE && {
                     Accept: 'application/json',
                     type: 'formData',
                  }),
                  ...(secured &&
                     tokens && {
                        Authorization: `Bearer ${tokens.accessToken}`,
                     }),
               },
            }
         )

         return response?.data
      } catch (err: any) {
         console.error('Error fetching: ', url, err)
         if (err.response) {
            throw err.response?.data
         } else throw new Error(err)
      }
   }

   const putHandler = async <BodyInterface, OutputInterface>({
      url,
      body,
      secured = false,
      contentType = ContentType.JSON,
   }: PostFetchHandlerParameters<BodyInterface>) => {
      try {
         const minifiedBody = JSON.stringify(body)
         const { data } = await instance.put<APIReturnData<OutputInterface>>(
            url,
            minifiedBody,
            {
               headers: {
                  'Content-Type': contentType,
                  ...(secured &&
                     tokens && {
                        Authorization: `Bearer ${tokens.accessToken}`,
                     }),
               },
            }
         )

         return data
      } catch (err: any) {
         console.error('Error fetching: ', url, err)
         if (err.response) {
            throw err.response?.data
         } else throw new Error(err)
      }
   }

   const patchHandler = async <BodyInterface, OutputInterface>({
      url,
      body,
      secured = false,
      contentType = ContentType.JSON,
   }: PostFetchHandlerParameters<BodyInterface>) => {
      try {
         const minifiedBody = JSON.stringify(body)
         const { data } = await instance.patch<APIReturnData<OutputInterface>>(
            url,
            minifiedBody,
            {
               headers: {
                  'Content-Type': contentType,
                  ...(secured &&
                     tokens && {
                        Authorization: `Bearer ${tokens.accessToken}`,
                     }),
               },
            }
         )

         return data
      } catch (err: any) {
         if (err.response) {
            throw err.response?.data
         } else throw new Error(err)
      }
   }

   const deleteHandler = async <OutputInterface>({
      url,
      secured = false,
      contentType = ContentType.JSON,
   }: BaseFetchHandlerParameters) => {
      try {
         const { data } = await instance.delete<APIReturnData<OutputInterface>>(
            url,
            {
               headers: {
                  'Content-Type': contentType,
                  ...(secured &&
                     tokens && {
                        Authorization: `Bearer ${tokens.accessToken}`,
                     }),
               },
            }
         )

         return data
      } catch (err: any) {
         console.error('Error fetching: ', url, err)
         if (err.response) {
            throw err.response?.data
         } else throw new Error(err)
      }
   }

   const getHandler = async <OutputInterface>({
      url,
      secured,
      contentType = ContentType.JSON,
   }: BaseFetchHandlerParameters) => {
      try {
         const { data } = await instance.get<APIReturnData<OutputInterface>>(
            url,
            {
               headers: {
                  'Content-Type': contentType,
                  ...(secured &&
                     tokens && {
                        Authorization: `Bearer ${tokens.accessToken}`,
                     }),
               },
            }
         )

         return data
      } catch (err: any) {
         console.error('Error fetching: ', url, err)
         if (err.response) {
            return err.response?.data
         } else throw err
      }
   }

   return { postHandler, getHandler, putHandler, deleteHandler, patchHandler }
}
