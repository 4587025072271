import { FC, useEffect, useMemo } from 'react'
import { Box, Typography, Grid } from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import { Link, Navigate, useParams } from 'react-router-dom'
import { colorPalette, typography } from '../../../config'
import {
   NavIconButton,
   Button,
   Input,
   CircleLoading,
   ImageEditor,
} from '../../../components'
import {
   PageContent,
   PageHeader,
   PageTitle,
   PageWrapper,
} from '../../../layout'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAppDispatch, useAppSelector, selectFetch } from '../../../redux'
import { setupPostCreateSchema } from '../../../schemas'
import { useTranslation } from 'react-i18next'
import { CreateSetupPostBody, updateSetupPost } from '../../../redux/cms'
import { Status } from '../../../types'
import { useSetupPostDetail } from '../../../hooks/use-redux/use-setup-posts'
import { isValidUrl, urlPattern } from '../../../utils/validation'

const SetupPostDetail: FC = () => {
   const { response } = useAppSelector(selectFetch)
   const { setupPostId } = useParams()
   const { setupPostDetail, isLoading, mutate } = useSetupPostDetail(
      setupPostId || ''
   )
   const dispatch = useAppDispatch()
   const { t } = useTranslation(['common', 'inputs', 'components'])

   const {
      control,
      reset,
      setValue,
      handleSubmit,
      getValues,
      watch,
      formState: { errors, isDirty },
   } = useForm<CreateSetupPostBody>({
      values: {
         title: setupPostDetail?.title || '',
         description: setupPostDetail?.description || '',
         thumbnail: setupPostDetail?.thumbnail || '',
         content: setupPostDetail?.content || '',
         url: setupPostDetail?.url || '',
      },
      resolver: yupResolver(setupPostCreateSchema),
   })
   const [thumbnail] = watch(['thumbnail'])

   const onSubmit = (data: CreateSetupPostBody) => {
      dispatch(updateSetupPost({ id: setupPostId || '', values: data }))
   }

   const handleChangeMainImage = (imagesToUpload: string[]) => {
      setValue('thumbnail', imagesToUpload[0], { shouldDirty: true })
   }

   useEffect(() => {
      if (response?.status === Status.SUCCESS) {
         const data = getValues()
         mutate({
            statusCode: 200,
            message: 'Success',
            data: {
               _id: setupPostDetail?._id || '',
               title: data.title || '',
               description: data.description || '',
               url: data.url || '',
               content: data.content || '',
               thumbnail: data.thumbnail || '',
               created_at: new Date().valueOf().toString(),
            },
         })
         reset()
      }
   }, [response?.status])

   const renderForm = useMemo(
      () => (
         <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
               <Controller
                  name='title'
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                     <Input
                        label={'Tiêu đề'}
                        fullWidth
                        placeholder={'Tiêu đề'}
                        type='text'
                        error={!!errors.title}
                        helperText={errors?.title?.message}
                        {...field}
                     />
                  )}
               />
            </Grid>
            <Grid item xs={12} sm={12}>
               <Controller
                  name='description'
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                     <Input
                        label={'Mô tả'}
                        fullWidth
                        placeholder={'Mô tả'}
                        type={'text'}
                        error={!!errors.description}
                        helperText={errors?.description?.message}
                        {...field}
                     />
                  )}
               />
            </Grid>
            <Grid item xs={12} sm={12}>
               <Controller
                  name='content'
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                     <Input
                        label={'Nội dung'}
                        fullWidth
                        placeholder={'Nội dung'}
                        type={'text'}
                        error={!!errors.content}
                        helperText={errors?.content?.message}
                        {...field}
                     />
                  )}
               />
            </Grid>
            <Grid item xs={12} sm={12}>
               <Controller
                  name='url'
                  control={control}
                  rules={{
                     required: true,
                     pattern: {
                        value: urlPattern,
                        message:
                           'Không đúng định dạng link. VD: https://www.example.com',
                     },
                  }}
                  render={({ field }) => (
                     <Input
                        label={'Đường dẫn'}
                        fullWidth
                        placeholder={'Đường dẫn'}
                        type={'text'}
                        error={!!errors.url}
                        helperText={errors?.url?.message}
                        {...field}
                     />
                  )}
               />
            </Grid>
            <Grid item xs={12}>
               <Typography
                  className={typography.pc.s3}
                  color={colorPalette.dark}
                  sx={{ marginTop: '16px' }}
               >
                  Chọn ảnh thumbnail
               </Typography>
               {errors?.thumbnail ? (
                  <Typography
                     className={typography.pc.helpReg}
                     color={colorPalette.red.shade_500}
                  >
                     Chưa chọn ảnh
                  </Typography>
               ) : null}
               <ImageEditor
                  productId={setupPostId}
                  initialSelectedImgs={[thumbnail]}
                  variantIdentifier={setupPostId || 'main'}
                  cols={8}
                  onSelectedImgsChanges={handleChangeMainImage}
               />
            </Grid>
         </Grid>
      ),
      [control, errors]
   )

   if (isLoading) {
      return <CircleLoading />
   }

   return setupPostDetail ? (
      <form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
         <PageWrapper>
            <PageHeader>
               <Box sx={{ display: 'flex' }}>
                  <NavIconButton
                     dir='left'
                     variant='text'
                     size='sm'
                     component={Link as any}
                     to='/setups'
                     style={{ marginRight: '16px' }}
                  />
                  <Typography
                     className={typography.pc.h6}
                     color={colorPalette.dark}
                     component='div'
                  >
                     Thêm phần mềm
                  </Typography>
               </Box>
               <Button
                  disabled={!isDirty || Object.keys(errors).length > 0}
                  type='submit'
                  customsize='sm'
                  variant='contained'
               >
                  {t('buttons.common.save', { ns: 'components' })}
               </Button>
            </PageHeader>
            <Grid container spacing={2}>
               <Grid item xs={12} sm={12}>
                  <PageContent>
                     <PageTitle
                        type='content'
                        title={t('blockTitle.general')}
                     />
                     <Box sx={{ width: '100%', paddingTop: '8px' }}>
                        {renderForm}
                     </Box>
                  </PageContent>
               </Grid>
            </Grid>
         </PageWrapper>
      </form>
   ) : (
      <Navigate to='/setups' />
   )
}

export default SetupPostDetail
