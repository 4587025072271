import { FC, useEffect } from 'react'
import { Box, Typography, Grid } from '@mui/material'
import { Link } from 'react-router-dom'
import { colorPalette, typography } from '../../../config'
import { NavIconButton, Button, ProductForm } from '../../../components'
import {
   PageContent,
   PageHeader,
   PageTitle,
   PageWrapper,
} from '../../../layout'
import { ProductData } from '../../../types'
import { createProduct, useAppDispatch } from '../../../redux'
import { useTranslation } from 'react-i18next'
import VariantOption from '../../../components/VariantOption'
import {
   ProductsFormHOC,
   useProductsFormContext,
} from '../../../hooks/use-products-form'

const ProductCreatePage: FC = () => {
   const { t } = useTranslation(['common', 'components'])
   const {
      formControl,
      variants,
      variantController,
      handleOnSubmit,
      // debouncedProductAttributes,
   } = useProductsFormContext()!

   return (
      <PageWrapper>
         <PageHeader>
            <Box sx={{ display: 'flex' }}>
               <NavIconButton
                  dir='left'
                  variant='text'
                  size='sm'
                  component={Link as any}
                  to='/products'
                  style={{ marginRight: '16px' }}
               />
               <Typography
                  className={typography.pc.h6}
                  color={colorPalette.dark}
                  component='div'
               >
                  {t('pageTitle.product.create')}
               </Typography>
            </Box>
            <Button
               disabled={!formControl.isDirty}
               customsize='sm'
               variant='contained'
               type='submit'
               onClick={handleOnSubmit}
            >
               {t('buttons.common.save', { ns: 'components' })}
            </Button>
         </PageHeader>
         <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
               <ProductForm formControl={formControl} />
            </Grid>
            <Grid item xs={12} sm={12}>
               <PageContent>
                  <PageTitle type='content' title='Các mẫu sản phẩm' />
                  {variants.length > 0 ? (
                     <Grid container spacing={2}>
                        {variants.map((variant, idx) => (
                           <Grid item xs={12} sm={6} key={idx}>
                              <VariantOption
                                 variantController={variantController}
                                 variant={variant}
                                 variantIdentifier={variant.identifier}
                              />
                           </Grid>
                        ))}
                     </Grid>
                  ) : (
                     <Typography
                        className={typography.pc.descSemi}
                        color={colorPalette.dark}
                        component='p'
                     >
                        Thêm thuộc tính cho sản phẩm để bắt đầu tạo các mẫu sản
                        phẩm
                     </Typography>
                  )}
               </PageContent>
            </Grid>
         </Grid>
      </PageWrapper>
   )
}

const ProductCreate = () => {
   const dispatch = useAppDispatch()

   const onSubmit = (variants: ProductData[]) => {
      if (variants.length > 0) {
         dispatch(createProduct(variants))
      }
   }

   return ProductsFormHOC(ProductCreatePage, onSubmit)
}

export default ProductCreate
