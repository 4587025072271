import { FC, useEffect, useMemo } from 'react'
import { Box, Typography, Grid } from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { colorPalette, typography } from '../../../config'
import { NavIconButton, Button, Input, ImageEditor } from '../../../components'
import {
   PageContent,
   PageHeader,
   PageTitle,
   PageWrapper,
} from '../../../layout'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAppDispatch, useAppSelector, selectFetch } from '../../../redux'
import { setupPostCreateSchema } from '../../../schemas'
import { useTranslation } from 'react-i18next'
import { CreateSoftwareBody, createSoftware } from '../../../redux/cms'
import { Status } from '../../../types'
import { isValidUrl, urlPattern } from '../../../utils/validation'

const SoftwareCreate: FC = () => {
   const { response } = useAppSelector(selectFetch)
   const dispatch = useAppDispatch()
   const { t } = useTranslation(['common', 'inputs', 'components'])

   const {
      control,
      reset,
      setValue,
      handleSubmit,
      watch,
      formState: { errors, isDirty },
   } = useForm<CreateSoftwareBody>({
      values: {
         title: '',
         description: '',
         thumbnail: '',
         downloadLink: '',
      },
      mode: 'onChange',
      resolver: yupResolver(setupPostCreateSchema),
   })
   const [thumbnail] = watch(['thumbnail'])

   const onSubmit = async (data: CreateSoftwareBody) => {
      dispatch(createSoftware(data))
   }

   const handleChangeMainImage = (imagesToUpload: string[]) => {
      setValue('thumbnail', imagesToUpload[0], { shouldDirty: true })
   }

   useEffect(() => {
      if (response?.status === Status.SUCCESS) {
         reset()
      }
   }, [response?.status])

   const renderForm = (
      <Grid container spacing={2}>
         <Grid item xs={12} sm={12}>
            <Controller
               name='title'
               control={control}
               rules={{ required: true }}
               render={({ field }) => (
                  <Input
                     label={'Tiêu đề'}
                     fullWidth
                     placeholder={'Tiêu đề'}
                     type='text'
                     error={!!errors.title}
                     helperText={errors?.title?.message}
                     {...field}
                  />
               )}
            />
         </Grid>
         <Grid item xs={12} sm={12}>
            <Controller
               name='description'
               control={control}
               rules={{ required: true }}
               render={({ field }) => (
                  <Input
                     label={'Mô tả'}
                     fullWidth
                     placeholder={'Mô tả'}
                     type={'text'}
                     error={!!errors.description}
                     helperText={errors?.description?.message}
                     {...field}
                  />
               )}
            />
         </Grid>
         <Grid item xs={12} sm={12}>
            <Controller
               name='downloadLink'
               control={control}
               render={({ field }) => (
                  <Input
                     label={'Link download'}
                     fullWidth
                     placeholder={'Link download'}
                     type={'text'}
                     error={!!errors.downloadLink}
                     helperText={errors?.downloadLink?.message}
                     {...field}
                  />
               )}
            />
         </Grid>
         <Grid item xs={12}>
            <Typography
               className={typography.pc.s3}
               color={colorPalette.dark}
               sx={{ marginTop: '16px' }}
            >
               Chọn ảnh thumbnail
            </Typography>
            {errors?.thumbnail ? (
               <Typography
                  className={typography.pc.helpReg}
                  color={colorPalette.red.shade_500}
               >
                  Chưa chọn ảnh
               </Typography>
            ) : null}
            <ImageEditor
               productId={''}
               initialSelectedImgs={thumbnail ? [thumbnail] : []}
               variantIdentifier={'main'}
               cols={8}
               onSelectedImgsChanges={handleChangeMainImage}
            />
         </Grid>
      </Grid>
   )

   return (
      <form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
         <PageWrapper>
            <PageHeader>
               <Box sx={{ display: 'flex' }}>
                  <NavIconButton
                     dir='left'
                     variant='text'
                     size='sm'
                     component={Link as any}
                     to='/softwares'
                     style={{ marginRight: '16px' }}
                  />
                  <Typography
                     className={typography.pc.h6}
                     color={colorPalette.dark}
                     component='div'
                  >
                     Thêm phần mềm
                  </Typography>
               </Box>
               <Button
                  disabled={!isDirty || Object.keys(errors).length > 0}
                  type='submit'
                  customsize='sm'
                  variant='contained'
               >
                  {t('buttons.common.save', { ns: 'components' })}
               </Button>
            </PageHeader>
            <Grid container spacing={2}>
               <Grid item xs={12} sm={12}>
                  <PageContent>
                     <PageTitle
                        type='content'
                        title={t('blockTitle.general')}
                     />
                     <Box sx={{ width: '100%', paddingTop: '8px' }}>
                        {renderForm}
                     </Box>
                  </PageContent>
               </Grid>
            </Grid>
         </PageWrapper>
      </form>
   )
}

export default SoftwareCreate
