import { createSlice } from '@reduxjs/toolkit'
import { DeviotVideo, Maybe, SetupPostData, SoftwareData } from '../../types'
import {
   deleteSetupPost,
   deleteSoftware,
   deleteVideo,
} from './cmsThunk'
// Define a type for the slice state

interface UserState {
   error?: {
      message: string
   }
   cmsLoading: boolean
   softwareDetail?: Maybe<SoftwareData>
   videoDetail?: Maybe<DeviotVideo>
   setupPostDetail?: Maybe<SetupPostData>
}
// Define the initial state using that type
const initialState: UserState = {
   cmsLoading: false,
}

export const cmsSlice = createSlice({
   name: 'cmsState',
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      builder
         .addCase(deleteSetupPost.pending, (state) => {
            state.cmsLoading = true
         })
         .addCase(deleteSetupPost.fulfilled, (state) => {
            state.cmsLoading = false
            state.setupPostDetail = null
         })
         .addCase(deleteSetupPost.rejected, (state, action: any) => {
            state.cmsLoading = false
            state.error = action.error
         })
         .addCase(deleteVideo.pending, (state) => {
            state.cmsLoading = true
         })
         .addCase(deleteVideo.fulfilled, (state) => {
            state.cmsLoading = false
            state.videoDetail = null
         })
         .addCase(deleteVideo.rejected, (state, action: any) => {
            state.cmsLoading = false
            state.error = action.error
         })
         .addCase(deleteSoftware.pending, (state) => {
            state.cmsLoading = true
         })
         .addCase(deleteSoftware.fulfilled, (state) => {
            state.cmsLoading = false
            state.softwareDetail = null
         })
         .addCase(deleteSoftware.rejected, (state, action: any) => {
            state.cmsLoading = false
            state.error = action.error
         })
         // .addCase(updateBrandsList.pending, (state) => {
         //    state.cmsLoading = true
         // })
         // .addCase(updateBrandsList.fulfilled, (state) => {
         //    state.cmsLoading = false
         // })
         // .addCase(updateBrandsList.rejected, (state, action: any) => {
         //    state.cmsLoading = false
         //    state.error = action.error
         // })
   },
})

export default cmsSlice.reducer
