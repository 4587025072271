import { Box, Paper, PaperProps, Stack, Typography } from '@mui/material'
import { colorPalette, typography } from '../../config'
import Icon from '../Icon'

export enum StatisticBlockType {
   UNIT = 'unit',
   TEXT = 'text',
}

type StatisticBlockProps = PaperProps & {
   icon?: React.FunctionComponent<any>
   color: string
   muiIcon?: React.ReactNode
   title: string
   value: string | number
   type: StatisticBlockType
   unit?: React.ReactNode
}

const styledPaper = (Component: typeof Paper) => {
   return ({
      sx,
      icon,
      title,
      value,
      type,
      unit,
      muiIcon,
      color,
      ...restProps
   }: StatisticBlockProps) => {
      return (
         <Component
            sx={{
               display: 'flex',
               borderRadius: '8px',
               border: `1px solid ${colorPalette.stroke}`,
               padding: '16px',
               ...sx,
               boxShadow: 'none',
               height: '100%'
            }}
            {...restProps}
         >
            <Box
               sx={{
                  borderRadius: '50%',
                  width: '32px',
                  height: '32px',
                  border: `1px solid ${colorPalette.blue.shade_100}`,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  bgcolor: color
               }}
            >
               {icon ? (
                  <Icon type='fill' color={colorPalette.white} src={icon} />
               ) : (
                  muiIcon
               )}
            </Box>
            <Stack direction='column' sx={{ marginLeft: '16px' }}>
               <Typography
                  className={typography.pc.helpReg}
                  color={colorPalette.dark}
               >
                  {title}
               </Typography>
               <Typography
                  className={typography.pc.h6}
                  color={colorPalette.dark}
               >
                  {value} {unit} 
               </Typography>
            </Stack>
         </Component>
      )
   }
}

const StatisticBlock = styledPaper(Paper)

export default StatisticBlock
