import { createSlice } from '@reduxjs/toolkit'
import {
   getAllManagers,
   getOneManager,
} from './managerThunk'
import type { ManagerData } from '../../types'

interface UserState {
   managerLoading: boolean
   managers: ManagerData[]
   managerDetail?: ManagerData
}

const initialState: UserState = {
   managerLoading: false,
   managers: [],
}

export const managerSlice = createSlice({
   name: 'managerState',
   initialState,
   reducers: {
      resetManagers: (state) => {
         state.managers = []
      },
      resetManagerDetail: (state) => {
         state.managerDetail = undefined
      }
   },
   extraReducers: (builder) => {
      builder
         .addCase(getAllManagers.pending, (state) => {
            state.managerLoading = true
         })
         .addCase(getAllManagers.fulfilled, (state, action: any) => {
            state.managerLoading = false
            state.managers = action.payload.managers
         })
         .addCase(getAllManagers.rejected, (state, action: any) => {
            state.managerLoading = false
         })
         .addCase(getOneManager.pending, (state) => {
            state.managerLoading = true
         })
         .addCase(getOneManager.fulfilled, (state, action: any) => {
            state.managerLoading = false
            state.managerDetail = action.payload.managerDetail
         })
         .addCase(getOneManager.rejected, (state, action: any) => {
            state.managerLoading = false
         })
   },
})

export const { resetManagerDetail, resetManagers } = managerSlice.actions

export default managerSlice.reducer
