import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getOrders, getOrderById } from './orderThunk'
import type { OrderData } from '../../types'

interface UserState {
  orderLoading: boolean
  orders: OrderData[]
  orderDetail?: OrderData
}

const initialState: UserState = {
  orderLoading: false,
  orders: []
}

export const orderSlice = createSlice({
  name: 'orderState',
  initialState,
  reducers: {
    resetOrders: (state) => {
      state.orders = []
    },
    resetOrderDetail: (state) => {
      state.orderDetail = undefined
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrders.pending, (state) => {
        state.orderLoading = true
      })
      .addCase(getOrders.fulfilled, (state, action: any) => {
        state.orderLoading = false
        state.orders = action.payload.orders
      })
      .addCase(getOrders.rejected, (state) => {
        state.orderLoading = false
      })
      .addCase(getOrderById.pending, (state) => {
        state.orderLoading = true
      })
      .addCase(getOrderById.fulfilled, (state, action: any) => {
        state.orderLoading = false
        state.orderDetail = action.payload.orderDetail
      })
      .addCase(getOrderById.rejected, (state) => {
        state.orderLoading = false
      })
  },
})

export const { resetOrders, resetOrderDetail } = orderSlice.actions

export default orderSlice.reducer
