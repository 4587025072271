import { FunctionComponent, forwardRef } from 'react'
import { Button, ButtonProps } from '@mui/material'
import Icon from '../Icon'
import { colorPalette } from '../../config'

type IconButtonProps = ButtonProps & {
   width?: number
   height?: number
   customSize?: 'xs'| 'sm' | 'md' | 'lg'
   iconColor?: string
   src: FunctionComponent<any>
}

const styledIconButton = (Component: typeof Button) => {
   return forwardRef(({
      sx,
      variant,
      width,
      height,
      customSize = 'md',
      iconColor = colorPalette.primary,
      src,
      ...restProps
   }: IconButtonProps, ref: any) => {
      const sizeStyles = {
         xs: {
            padding: '4px',
            width: 10
         },
         sm: {
            padding: '8px',
            width: 16,
         },
         md: {
            padding: '10px',
            width: 20,
         },
         lg: {
            padding: '16px',
            width: 24,
         },
      }

      const props = {
         variant,
         sx: {
            ...sx,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            borderRadius: '50%',
            minWidth: 0,
            minHeight: 0,
            maxHeight: `calc(${sizeStyles[customSize].padding}*2 + ${sizeStyles[customSize].width}px)`,
            padding: sizeStyles[customSize].padding,
         },
         ...restProps,
      }

      return (
         <Component {...props} ref={ref}>
            <Icon
               type='fill'
               color={variant === 'contained' ? colorPalette.white : iconColor}
               src={src}
               width={sizeStyles[customSize].width}
               height={sizeStyles[customSize].width}
            />
         </Component>
      )
   })
}

const IconButton = styledIconButton(Button)

export default IconButton
