import { FC, useEffect, useMemo, useState } from 'react'
import { Grid, Paper, Stack } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import {
   Button,
   CircleLoading,
   Icon,
   BannerCard,
   IconButton,
   PopupNoti,
   PopupForm,
   Field,
   InputType,
} from '../../../components'
import { PageHeader, PageTitle, PageWrapper } from '../../../layout'
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg'
import { ReactComponent as BinIcon } from '../../../assets/icons/bin.svg'
import { ReactComponent as PenIcon } from '../../../assets/icons/pen.svg'
import { colorPalette } from '../../../config'
import {
   CreateBannerData,
   deleteBanner,
   getAllBanners,
   resetBanners,
   selectBanner,
   selectFetch,
   updateBanner,
   useAppDispatch,
   useAppSelector,
} from '../../../redux'
import { useTranslation } from 'react-i18next'
import { bannerCreateFormSchema, warningMsg } from '../../../schemas'
import { BannerData } from '../../../types'

const AllBanners: FC = () => {
   const dispatch = useAppDispatch()
   const { bannerLoading, banners } = useAppSelector(selectBanner)
   const { uploading } = useAppSelector(selectFetch)
   const { t } = useTranslation(['common', 'inputs', 'components'])
   const navigate = useNavigate()

   const [openAlert, setOpenAlert] = useState<boolean>(false)
   const [selectedBanner, setSelectedBanner] = useState<BannerData | null>(null)
   const [openForm, setOpenForm] = useState<boolean>(false)

   const formFields: Field[] = [
      {
         name: 'button_text',
         label: t('banners.buttonText.label', {
            ns: 'inputs',
         }) as string,
         placeholder: t('banners.buttonText.placeholder', {
            ns: 'inputs',
         }) as string,
         type: 'text',
         inputType: InputType.INPUT,
         cols: 12,
         required: true,
      },
      {
         name: 'button_link',
         label: t('banners.buttonLink.label', {
            ns: 'inputs',
         }) as string,
         placeholder: t('banners.buttonLink.placeholder', {
            ns: 'inputs',
         }) as string,
         type: 'text',
         inputType: InputType.INPUT,
         cols: 12,
         required: true,
      },
      {
         name: 'product',
         label: t('banners.product.label', {
            ns: 'inputs',
         }) as string,
         placeholder: t('banners.product.placeholder', {
            ns: 'inputs',
         }) as string,
         type: 'text',
         inputType: InputType.INPUT,
         cols: 12,
         required: true,
      },
      {
         name: 'large_text_1',
         label: t('banners.largeText1.label', {
            ns: 'inputs',
         }) as string,
         placeholder: t('banners.largeText1.placeholder', {
            ns: 'inputs',
         }) as string,
         type: 'text',
         inputType: InputType.INPUT,
         cols: 12,
         required: true,
      },
      {
         name: 'small_text_1',
         label: t('banners.smallText1.label', {
            ns: 'inputs',
         }) as string,
         placeholder: t('banners.smallText1.placeholder', {
            ns: 'inputs',
         }) as string,
         type: 'text',
         inputType: InputType.INPUT,
         cols: 12,
         required: true,
      },
   ]

   const handleOnEdit = (banner: BannerData) => {
      // setSelectedBanner(banner)
      // setOpenForm(true)
      navigate(`/banners/${banner._id}`)
   }

   const handleOnClick = (banner: BannerData) => {
      setSelectedBanner(banner)
      setOpenAlert(true)
   }

   const handleOnAction = () => {
      if (selectedBanner) {
         dispatch(deleteBanner(selectedBanner?._id))
      }
   }

   const handleOnSubmit = (formValues: CreateBannerData) => {
      if (selectedBanner) {
         dispatch(
            updateBanner({
               bannerId: selectedBanner?._id,
               payload: formValues,
            })
         )
      }
   }
   const renderItems = useMemo(
      () => (
         <Grid container spacing={2}>
            {banners.map((banner) => (
               <Grid item xs={12} sm={6} key={banner._id}>
                  <Paper
                     sx={{
                        borderRadius: '16px',
                        bgcolor: colorPalette.white,
                        position: 'relative',
                     }}
                  >
                     <Stack
                        sx={{
                           position: 'absolute',
                           top: '16px',
                           right: '16px',
                           zIndex: '9',
                        }}
                        direction='row'
                        alignItems='center'
                        justifyContent='flex-end'
                     >
                        <IconButton
                           src={PenIcon}
                           iconColor={colorPalette.white}
                           variant='contained'
                           color='primary'
                           onClick={() => handleOnEdit(banner)}
                        />
                        <IconButton
                           src={BinIcon}
                           iconColor={colorPalette.white}
                           variant='contained'
                           color='error'
                           sx={{ ml: '4px' }}
                           onClick={() => handleOnClick(banner)}
                        />
                     </Stack>
                     <BannerCard banner={banner} />
                  </Paper>
               </Grid>
            ))}
         </Grid>
      ),
      [t, banners, uploading]
   )

   useEffect(() => {
      if (!uploading) {
         dispatch(getAllBanners())
      }

      return () => {
         dispatch(resetBanners())
      }
   }, [uploading, t])

   return (
      <>
         {selectedBanner && (
            <PopupForm
               title='Update banner data'
               open={openForm}
               setOpen={setOpenForm}
               schema={bannerCreateFormSchema}
               formFields={formFields}
               onSubmit={handleOnSubmit}
               defaultValues={selectedBanner}
            />
         )}
         <PopupNoti
            open={openAlert}
            setOpen={setOpenAlert}
            message={warningMsg}
            action={handleOnAction}
         />
         <PageWrapper>
            <PageHeader>
               <PageTitle type='header' title={t('pageTitle.banner.all')} />
               <Stack direction='row' alignItems='center'>
                  <Button
                     customsize='sm'
                     variant='contained'
                     endIcon={
                        <Icon
                           type='fill'
                           src={PlusIcon}
                           color={colorPalette.white}
                           width={15}
                           height={15}
                        />
                     }
                     style={{ marginLeft: '5px' }}
                     component={Link as any}
                     to='create'
                  >
                     {t('buttons.banner.create', { ns: 'components' })}
                  </Button>
               </Stack>
            </PageHeader>
            {bannerLoading ? <CircleLoading /> : renderItems}
         </PageWrapper>
      </>
   )
}

export default AllBanners
