/* Libs */
import { FC } from 'react'
import { Box, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const NotFound: FC = () => {
   const navigate = useNavigate()
   const { t } = useTranslation(['common', 'components'])

   return (
      <Box justifyContent='center' alignItems='center'>
         <p>{t('error.404')}</p>
         <Button
            variant='contained'
            color='primary'
            onClick={() => navigate('/')}
         >
            {t('buttons.404.back', { ns: 'components' })}
         </Button>
      </Box>
   )
}

export default NotFound
